const en = {
  ngon_ngu_tieng_viet: "Vietnamese",
  ngon_ngu_tieng_anh: "English",

  common_ngay: "Day",
  common_ngay_kham: 'Day of the examination',
  common_thang: "Month",
  common_nam: "Year",
  common_tuan: "Week",
  common_khong_co_quyen: "You cant't access this",
  common_huy: 'Cancel',
  data_Tiep_tan: "Reception",
  data_Nguoi_lay_mau: "Sampler",
  tiep_tan: "Reception",

  menu_Bang_dieu_khien: "Dasdboard",
  menu_quan_ly_he_thong: "menu_quan_ly_he_thong",
  //menu_quan_ly_he_thong: "menu_quan_ly_he_thong",
  menu_Quan_ly_tiep_don: "Reception Manager",
  menu_Quan_ly_he_thong: "System Management",
  menu_Quan_ly_danh_muc: "Manage Categories",
  menu_Quan_ly_nguoi_dung: "User Management",
  menu_Thong_tin_cskcb: "Hospital Information",
  menu_Quan_ly_vien_phi: "Hospital Fee Management",
  menu_Quan_ly_thanh_toan: "Payment Management",
  menu_Quan_ly_kham_benh: "Examination Management",
  menu_Quan_ly_chan_doan_hinh_anh_ris: "Management of diagnostic imaging",
  menu_Quan_ly_bhyt: "Health Insurance Management",
  menu_Quan_ly_duoc_ngoai_tru: "Outpatient pharmacy",
  menu_Kho_thuoc: "Medicine Storage",
  menu_Kho_duoc_ngoai_tru: "Warehouse",
  menu_Quan_ly_xep_hang_qms: "Queue Management QMS",
  menu_Quan_ly_dich_vu_can_thanh_toan: "Must-paid Service",
  menu_Quan_ly_so_hoa_don: "Invoice Book",
  menu_Quan_ly_xet_nghiem_lis: "LIS Test Management",
  menu_Lich_lam_viec: "Schedule",
  menu_Quan_ly_benh_nhan: "Patient Management",
  menu_Bao_cao: "Report",
  menu_Phieu_xuat_nhap: "Import/export ticket",
  app_loi_ket_noi: "Connection error!",
  menu_Ho_tro_khach_hang: "Customer support",
  menu_Ban_thuoc: "Drug sale",
  co_thai: "Pregnant",
  // Danh sách danh mục (quản lý danh mục)
  cate_DM: "Category",
  cate_Danh_muc: "Category",
  cate_benh_vien: "hospital",
  cate_khoa: "department",
  cate_phong: "room",
  cate_dich_vu_ki_thuat: "technical services",
  cate_nhom_dv_ki_thuat: "group technical service",
  cate_loai_dv_ki_thuat: "type of technical service",
  cate_nhom_hoa_don: "invoice group",
  cate_thuoc: "drug",
  cate_nha_thuoc: "drugstore",
  cate_hang_san_xuat: "manufacturer",
  cate_duong_dung: "route of use",
  cate_kho_tu_truc: "warehouse",
  cate_hoat_chat: "active ingredients",
  cate_tinh: "province",
  cate_huyen: "district",
  cate_xa: "wards",
  cate_nghe_nghiep: "job",
  cate_dan_toc: "ethnic",
  cate_quoc_gia: "nation",
  cate_icd10: "ICD-10",
  cate_nhom_icd10: "group ICD-10",
  cate_don_vi_tinh: "calculation unit",
  cate_so_hoa_don: "Receipt list",
  cate_giuong: "bed",
  cate_dv_giuong: "service bed",
  cate_cach_thuc_PTTT: "surgical procedure",
  cate_phuong_phap_PTTT: "Surgical method",
  cate_pp_vo_cam: "emotionless method",
  cate_xuat_khac: "other import",
  cate_nguon: "source",
  cate_kho_thuoc: "warhouse drug",
  cate_doi_tuong: "Customer target",
  cate_nguon_khach: "Client resource",
  cate_cach_dung: "use",

  // Danh sách trường thông tin (bao gồm: tên cột của table, label của form)
  field_ma_doi_tuong: "Object code",
  field_Ma_cskcb: "Code hospital",
  field_Ten_benh_vien: "Hospital name",
  field_Tuyen: "Line",
  field_Hang: "Rate",
  field_Dia_chi: "Address",
  field_Ma_khoa: "Faculty code",
  field_Ten_khoa: "Faculty name",
  field_Ma_khoa_byt: "Ministry of Health's Faculty Code",
  field_Truong_khoa: "Dean",
  field_Loai_khoa: "Faculty type",
  field_Ma_phong: "Room code",
  field_Ten_phong: "Room name",
  field_Khoa: "Department",
  field_Loai_phong: "Type of room",
  field_Phong_chi_dinh: "Designated room",
  field_Stt: "Number",
  field_Ma_dich_vu: "Service code",
  field_Ten_dich_vu: "Service name",
  field_Ten_dich_vu_theo_byt: "Ministry of Health's Service Code",
  field_Don_vi_tinh: "Unit",
  field_Thong_tin_co_so: "Hospital info",
  field_Thong_tin_co_ban: "Basic informations",
  field_Ten_cskcb: "Hospital name",
  field_Tinh_thanh: "Province/City",
  field_Quan_huyen: "District",
  field_Xa_phuong: "Ward",
  field_Dia_chi_chi_tiet: "Detailed address",
  field_Ghi_chu: "Note",
  field_Hien_thi: "Lock",
  field_Thao_tac: "Action",
  field_Nhom_dich_vu: "Group service",
  field_Loai_dich_vu: "Type of service",
  field_Nhom_bhyt: "Group health insurrance",
  field_nhom_hoa_don: "Invoice group",
  field_Gia: "Price",
  field_Gia_thu_phi: "Toll price",
  field_Gia_bhyt: "Insurrance price",
  field_Gia_dich_vu: "Requirement price",
  field_Gia_nuoc_ngoai: "Foreign price",
  field_Thong_tin_khac: "Other information",
  field_Ti_le_dung_tuyen: "Right line rate",
  field_Ti_le_trai_tuyen: "Off-line rate",
  field_So_ngay_tai_chi_dinh: "Number of days of reassignment",
  field_Phong_thuc_hien: "Implementation room",
  field_Phong_lay_mau: "Sampling room",
  field_chon_loai_thu_thuat: "Procedure type",
  field_Chi_so_xet_nghiem: "Test Index",
  field_Don_vi_chi_so: "Index unit",
  field_Giai_ket_qua_chi_so: "Solve the index result",
  field_Cho_phep_mien_giam: "Allowances for exemptions",
  field_Cho_sua_gia_khi_chi_dinh: "For price correction when specifying",
  field_Ma_nhom: "Group code",
  field_Ten_nhom: "Group name",
  field_Id_loai_dv_ki_thuat: "ID type of technical service",
  field_Nhom_dv_ki_thuat: "Group technical service",
  field_Ten_loai_dv_ki_thuat: "Technical service type name",
  field_Id_nhom: "ID group",
  field_Ma_hang_san_xuat: "Manufacturer code",
  field_Ten_hang_san_xuat: "Manufacturer's name",
  field_Ma_duong_dung: "Route code",
  field_Ma_duong_dung_theo_byt: "Ministry of Health's Usage code",
  field_ten_duong_dung: "Route name",
  field_Ma_kho: "Warehouse code",
  field_Ten_kho: "Warehouse name",
  field_Thu_kho: "Stocker",
  field_Loai_kho: "Warehouse type",
  field_Kieu_kho: " Warehouse type",
  field_Ma_tinh: "Province code",
  field_Ma_tinh_byt: "Ministry of Health's Province Code",
  field_Ten_tinh: "Name of province",
  field_Ten_Tinh_thanh: "Province",
  field_Id_huyen: "District code",
  field_Ma_huyen_byt: "Ministry of Health's District Code",
  field_Ten_huyen: "District name",
  field_Ten_Quan_huyen: "District",
  field_Ma_xa: "Ward code",
  field_Ma_xa_byt: "Ministry of Health's Ward Code",
  field_Ten_xa: "Ward name",
  field_Ten_Xa_phuong: "Ward",
  field_Ma_nghe_nghiep: "Occupation code",
  field_Ten_nghe_nghiep: "Occupation name",
  field_Ma_dan_toc: "Ethnic Code",
  field_Ten_dan_toc: "Ethnic name",
  field_Ma_icd: "Code ICD",
  field_Ten_icd: "Name ICD",
  field_Nhom_icd: "Group ICD",
  field_Loai_icd: "Type ICD",
  field_Ma_nhom_icd: "Group code ICD",
  field_Ten_nhom_icd: "Gruop name ICD",
  field_Ma_quoc_gia: "Country code",
  field_Ten_quoc_gia: "Country name",
  field_Ten_goi_khac: "Other name",
  field_Ten_viet_tat: "Abbreviations",
  field_Benh_nhan: "Patients",
  field_Dich_vu_su_dung: "Service use",
  field_Thanh_toan: "Payment",
  field_Da_TT: "Paid",
  field_Trang_thai: "Status",
  field_Thong_tin_hanh_chinh: "Administrative information",
  field_Tim_kiem_benh_nhan: "Patient search",
  field_Tim_kiem_thuoc: "Medicine search",
  field_Doi_tuong_benh_nhan: "Patient object",
  field_Uu_tien: "Prioritize",
  field_Ma_benh_nhan: "Patient ID",
  field_Ma_BN: "Patient ID",
  field_Ten_benh_nhan: "Patient name",
  field_So_dien_thoai: "Phone",
  field_Email: "Email",
  field_Gioi_tinh: "Gender",
  field_Ngay_sinh: "Birthday",
  field_THX: "P-D-W",
  field_Nghe_nghiep: "Job",
  field_Dan_toc: "Ethnic",
  field_Quoc_gia: "Nation",
  field_Thong_tin_bhyt: "Health insurance information",
  field_TE_co_the_bhyt: "TE has a health insurance card",
  field_The_bhyt: "Health insurance card",
  field_Han_the_tu: "Card expiry from",
  field_Han_the_den: "Card expiry from to",
  field_Ngay_mien_cung_chi_tra: "Free day",
  field_Ngay_dong_han_du_5_nam: "Closing date is full 5 years",
  field_Noi_song: "Habitat",
  field_Thong_tin_kham: "Examination information",
  field_Yeu_cau_kham: "Request an examination",
  field_Chon_phong_kham: "Choose a clinic",
  field_Ma_cccd: "Citizen identification",
  field_So_phong: "Room number",
  field_Benh_nhan_cho_kham: "Patient waiting for examination",
  field_In_phieu_kham: "Print examination card",
  field_doi_tuong_BHYT: "Medical Insurance",
  field_doi_tuong_yeu_cau: "Require",
  field_doi_tuong_vien_phi: "Hospital Fee",
  field_doi_tuong_nuoc_ngoai: "Foreign",
  field_doi_tuong_mien_giam: "Exemptions",
  field_ton_kho: "Stock",
  field_gia_ban: "Selling price",
  field_gia_nhap: "Import price",
  field_tinh_nang: "Feature",
  field_ma_ly_do: "Reason ID",
  field_ly_do: "Reason",
  field_ma_nguon: "Source ID",
  field_ma_nguon_khach: "Client resource",
  field_ten_nguon_khach: "Client resource name",
  field_ten_nguon: "Source name",
  field_so_dang_ky_thuoc: "Drug registration number",
  field_gio_bat_dau: "Start time",
  field_gio_ket_thuc: "End time",
  field_thoi_gian_hoat_dong: "Room's working time",
  field_thoi_gian: "Time",
  field_chua_cai_dat_thoi_gian: "Working time not configured",
  field_gia_vien_phi: "hospital_fees",
  ten_doi_tuong: "Object name",
  thu_tu: "Order",
  field_loai_doi_tuong: "Object type",
  field_mau_bp: "Patient sample",

  // Từ khóa thông dụng (tên button, chức năng phổ biến, tiêu đề dùng nhiều, ...)
  common_trong: "of",
  common_Huy: "Cancel",
  common_Thoat: "Exit",
  common_Luu: "Save",
  common_Luu_va_In: "Save and Print",
  common_In: "Print",
  common_Nhap: "Enter",
  common_Chon: "Select",
  common_Bat: "On",
  common_Xoa: "Delete",
  common_huy_don: "Cancel",
  common_Chinh_sua: "Edit",
  common_Them_moi: "Add",
  common_Tim_kiem: "Search",
  common_Tim_kiem_theo_loai: "Search by type",
  common_Nhap_csv: "Import spreadsheet",
  common_Xuat_csv: "Export spreadsheet",
  common_Tat: "Off",
  common_Xem_truoc: "Preview",
  common_Tai_mau: "Download templates",
  common_Tiep_tuc: "Continue",
  common_Dang_kham: "Examining",
  common_Kham_xong: "Done",
  common_Dang_xuat: "Logout",
  common_Canh_bao: "Warning",
  common_Xuat_file: "Export file",
  common_ok: "Ok",
  common_bo_loc: "Filter",
  common_dong: "Close",
  common_tai_lai: "Reload",
  // Giới tính
  gioi_tinh_Nam: "Male",
  gioi_tinh_Nu: "Female",
  gioi_tinh_Khac: "Other",

  // Khác...
  noi_dung_keo_tha_upload_file: "Click or drop the file here!",

  // Loại khoa
  loai_khoa_kham_benh: "Physical exam",
  loai_khoa_noi_khoa: "Medical",
  loai_khoa_duoc: "Pharmacy",
  loai_khoa_tai_chinh: "Finance",
  loai_khoa_xet_nghiem: "Analysis",
  loai_khoa_cdha: "CDHA",
  loai_khoa_khac: "Other",

  // label
  label_Da_tiep_nhan: "Received",
  label_quan_ly_lich_hen: "Upcoming appointment",
  label_Tiep_don: "Reception ",
  label_Tiep_don_kham_chua_benh: "Patient admission",
  label_So_hoa_don: "Receipt package",
  label_them_so_hoa_don: "Add receipt package",
  label_sua_so_hoa_don: "Edit receipt package",
  label_thong_tin_chi_tiet: "Detail information",
  label_phieu_xuat_nhap: "Import/Export ticket",

  // thông tin cơ sở khám chữa bệnh
  thong_tin_cskcb: "Information about medical facilities",
  luu_thay_doi: "Save changes",
  field_ten_CSKCB: "Hospital name",
  field_don_vi_quan_ly: "Management unit",
  field_hang_benh_vien: "Hospital grade",
  field_giam_doc_benh_vien: "Hospital director",
  them_hinh_anh: "More photos",
  field_ten_nguoi_dung: "User name",
  field_phan_quyen: "Decentralization",
  txt_da_chon: "Select",
  field_ma_cc_hanh_nghe: "Code of practice certificate",
  field_thong_tin_cong_viec: "Job Information",
  field_hoc_ham: "Academic rank",
  field_chuc_danh: "Title",
  field_ho: "First name",
  field_ten_ten_dem: "Last name",
  field_mat_khau: "Password",
  field_ten_danh_nhap: "Username",
  title_them_moi_nguoi_dung: "Add user",
  title_thong_tin_tai_khoan: "Infomation account",
  title_chinh_sua_nguoi_dung: "Edit user",
  title_tong_gia: "Total price",
  button_nhap_BHYT: "Enter Insurance",
  button_kiem_tra_BHYT: "Check Insurance",
  field_dia_chi_the: "Address card",

  /// thuốc
  field_ma_thuoc: "Drug code",
  field_ten_thuoc: "Drug name",
  field_lo_thuoc: "Batch",
  field_ma_thuoc_bhyt: "Ministry of Health's Drug Code",
  field_stt_bhyt: "Order Number Insurance",
  field_Ten_thuoc_bhyt: "Insurance Drug Name",
  field_ten_hoat_chat: "Active Ingredient Name",
  field_don_vi_tinh: "Unit",
  field_nong_do: "Concentration",
  field_the_tich: "Volume",
  field_ham_luong: "Content",
  field_ma_hoat_chat: "Active Ingredient Code",
  field_nuoc_san_xuat: "Made in",
  field_hang_san_xuat: "Made by",
  field_quy_cach_dong_goi: "Packing Instruction",
  field_ATC_code: "ATC Code",
  field_thuoc_khang_sinh: "Medicine",
  field_che_pham_y_hoc_co_truyen: "Traditional Medicine Product",
  field_cho_phep_mien_giam: "Exemptions Allowed",
  field_thuoc_tan_duoc: "Is New Drug",
  field_thuoc_ke_don: "Is Prescribed Drug",
  field_vi_thuoc_y_hoc: "Is Traditional Medicine",
  field_thuc_pham_chuc_nang: "Is Functional Food",
  field_tam_dung_nhap_NCC: "Pause supplier import",
  field_ma_hoat_chat_bhyt: "Ministry of Health's Active Ingredient",
  field_ngay_het_han: "Expiration date",
  field_loai_thuoc: "Medicine type",
  field_nhom_thuoc: "Medicine group",
  field_tat_ca: "All",
  field_tat_ca_nhom: "All group",
  field_da_het_han: "Expired",
  field_chua_het_han: "Unexpired",
  field_ma_vach: "Barcode",
  field_nhom_chi_phi: "Cost group",
  field_chung_tu: "Document",
  field_phuong_thuc: "Method",
  field_don_gia: "Unit price",
  field_ton_cuoi: "Ending stocks",
  field_doi_tac: "Partner",
  field_so_lo: "Lot number",
  field_han_su_dung: "Expiration date",
  field_con_hang: "In stock",
  field_het_hang: "Out of stock",
  field_ten_nhom_thuoc: "Medicine group name",
  field_nhom_cha: "Parent group",
  field_nhom_cls: "subclinical group",

  field_ma_lo: "lot code",
  field_them_lo: "add lot",
  field_chi_tiet_phieu_nhap: "Import ticket details",
  field_chi_tiet_phieu_xuat: "Export ticket details",
  field_chi_tiet_phieu_ban_thuoc: "Drug sales slip details",
  field_Ten_nhom_thuoc: "Drug class name",
  field_tao_phieu_nhap: "Create import ticket",
  field_chon_phieu_mau: "choose form entry form",
  field_chon_loai_phieu_mau: " choose sample ticket",
  field_ten_khach_hang: "Customer name",
  field_nguoi_ban_thuoc: "Medicine seller",
  field_Nguoi_tu_van: "Counselor",

  field_doanh_thu: "Revenue",
  field_tong_tien: "Total",

  //sổ hóa đơn
  field_shd_loai_so: "Loại sổ",
  field_shd_ten_so: "Tên sổ",
  field_shd_ky_hieu: "Ký hiệu",
  field_shd_tong_so: "Tổng số phiếu",
  field_shd_ma_so: "Mã sổ",

  // message errorr :
  nhap_dung_dinh_dang_email: "Please enter the correct email format",
  nhap_email: "Please enter your email",
  nhap_dung_dinh_dang_sdt: "Please enter the correct phone number format",
  nhap_sdt: "Please enter your phone",
  vui_long_nhap: "Please enter !",
  please_chon: "Please choose !",
  vui_long_chon: "Please choose a performance room!",
  vui_long_nhap_du_ky_tu: "Please enter correctly!",
  vui_long_nhap_dung_dinh_dang: "Please enter correctly!",
  error_trung_so_dien_thoai: "Same phone number",
  error_trung_so_user_name: "Same username",
  error_loi_ton_tai: "Data already exists!",
  error_loi_khong_nhap_gia_tri: "Data are still empty!",
  error_gia_tri_khong_hop_le: "Invalid data!",
  error_khong_trung_khop: "Data mismatch!",
  error_dich_vu_da_thanh_toan: "Can't cancel paid services",
  error_vien_phi_thieu_phieu: "Total count is lower than created receipt count ( at least ",
  error_thoi_gian_khong_hop_le: "Invalid time",
  error_gioi_han_ky_tu_sdt: "Cannot be more than 11 characters",
  error_gioi_han_ky_tu_sdt_min: "Cannot be less than 10 characters",
  error_gioi_han_ky_tu_mst: "Cannot be more than 14 characters",
  error_zero_only: "Cannot type 0 characters only",

  cap_nhat_thanh_cong: "Update successful",
  cap_nhat_that_bai: "Update failed !",
  them_moi_thanh_cong: "Successfully added",
  them_moi_that_bai: "Add new failure !",
  thao_tac_thanh_cong: "Successful manipulation",
  thao_tac_that_bai: "Failed action !",

  common_Hom_nay: "Today",
  common_Hom_qua: "Yesterday",
  Cho_kham: "Waiting",
  CLS: "Subclinical",
  common_Hoan_thanh: "Complete",
  common_Hoan_thanh_short: "Complete",
  field_Tuoi: "Age",
  common_Ap_dung: "Apply",
  Thong_tin_benh_nhan: "Patient Information",
  Lich_su_kham_benh: "Medical History",
  Chi_dinh_CLS: "Subclinical Indications",
  Ke_don_thuoc_tu_nguyen: "Voluntary Prescription",
  Don_thuoc_bhyt: "Medical Insurance Prescription",
  field_Ngay_kham: "Examination Day",
  field_Noi_kham: "Examination clinic",
  field_Chan_doan_benh_chinh: "Main Disease Diagnosis",
  xac_nhan_lich_hen: "Appointment confirmation",
  xac_nhan_tiep_don: "Reception confirmation",
  xac_nhan_va_in_phieu_kham: "Confirm and Print the examination card",
  field_Chan_doan_benh_phu_kem_theo: "Diagnosis of comorbidities",
  field_Bac_si: "Doctor",
  field_Bac_si_kham: "Examine Doctor",
  field_Khoa_dieu_tri: "Faculty treatment",
  Don_thuoc: "Prescription",
  Ket_qua_CLS: "Subclinical Result",
  field_CMND_CCCD: "Identity Card",

  field_Chi_so_sinh_ton: "Survival stats",
  field_Mach: "Blood vessel",
  field_Lan_phut: "(time/min)",
  field_Do_C: "(≈)",
  field_Nhiet_do: "Temp",
  field_Nhip_tho: "Breathing",
  field_Nhip_phut: "(breathing/min)",
  field_Huyet_ap: "Blood pressure",
  field_Ly_do_kham: "Exam reason",
  field_Qua_trinh_benh_ly: "Pathological process",
  field_Tien_su_ban_than: "Personal history",
  field_Tien_su_gia_dinh: "Family history",
  field_Kham_lam_sang: "Clinical examination",
  field_Ma_benh_chinh: "Main disease code",
  field_Ma_benh_kem_theo: "Accompanying disease code",
  field_Chan_doan_lam_sang: "Clinical diagnosis",
  field_Chan_doan_xac_dinh: "Implementing the quadrants",
  field_Cach_giai_quyet: "Solution",
  field_Ket_qua_dieu_tri: "Treatment results",
  field_Xu_tri: "To solve",
  field_Tai_kham: "Re-examination",
  field_Tai_kham_sau: "Re-examination after",
  Ket_thuc_cuoc_kham: "Finish examination",
  field_Chon_ngay_ap_dung: "Choose apply date",
  field_ngay_kham: "Date of examination",
  field_ngay_thuc_hien: "Implementation date",
  field_gio_thuc_hien: "Implementation time",

  Ke_don_thuoc: "Prescribe Drugs",
  field_Thoi_gian_y_lenh: "Command time",
  field_Them_moi_thuoc: "New medicine",
  field_Kho_thuoc: "Drug store",
  field_Ten_thuoc: "Drug name",
  field_So_luong: "Quantity",
  field_Moi_ngay: "Per day",
  field_Chia_lam: "Divide into",
  field_Cach_dung: "Usage",
  field_Ma_thuoc: "Drug ID",
  field_Hoat_chat: "Active ingredient",
  field_Don_vi: "Unit",
  field_In_phieu: "Print",
  field_Lan: "Time(s)",
  field_Ma_don_thuoc: "Prescription ID",
  field_Kho_nhan_thuoc: "Drug receiving store",
  Ds_dich_vu_cls: "Subclinical service list",
  field_Dich_vu_chi_dinh: "Designated service",
  field_Ma_phieu_chi_dinh: "Designated code",
  field_Bac_si_chi_dinh: "Designated doctor",
  Chi_dinh_can_lam_sang: "Subclinical Indications",
  chi_tiet_phieu_chi_dinh_can_lam_sang: "Subclinical form detail",
  tao_phieu_chi_dinh_can_lam_sang: "Create subclinical form",
  danh_sach_dich_vu_da_chon: "List of selected services",
  field_Them_moi_can_lam_sang: "New subclinical",
  field_Chi_tiet_phieu_cls: "Subclinicals",
  txt_So_luong: "Quantity",
  txt_nhap_loi_dan: "Advice for patient",

  field_Ket_qua: "Result",

  field_KQKB_khoi_benh: "Cured",
  field_KQKB_do: "Better",
  field_KQKB_khong_thay_doi: "Unchanged",
  field_KQKB_nang: "Severe",
  field_KQKB_tu_vong: "Dead",

  field_cach_xu_tri_ra_vien: "Discharged",
  field_cach_xu_tri_chuyen_vien2: "Referral",
  field_cach_xu_tri_chuyen_vien: "Referral",
  field_cach_xu_tri_tron_vien: "Escaped",
  field_cach_xu_tri_xin_ra_vien: "Request discharge",

  currency_viet_nam: "vnd",
  field_Tong_gia: "Total price",
  field_Tong_gia_tien: "Total price",
  error_Dich_vu_da_ton_tai: "Service(s) existed in current list!",
  fallback_Phong_thuc_hien_trong: "(No data)",
  status_Chua_thanh_toan: "Unpaid",
  status_Da_thanh_toan: "Paid",
  tip_trang_sau: "Previous",
  tip_trang_ke_tiep: "Next",
  status_Cho_ket_qua: "Waiting",
  status_Da_huy: "Canceled",
  status_Tat_ca: "All",
  status_Cho_xac_nhan: "Wait confirm",
  field_Tong_thanh_toan: "Total checkout",
  field_Da_TH: "Executed",
  // Viện phí

  vien_phi_da_tam_ung: "Has advanced",
  vien_phi_Tam_ung: "Advance",
  vien_phi_dich_vu_can_thanh_toan: "Services to pay for",
  vien_phi_thanh_tien: "Total",
  vien_phi_mien_giam_chi_phi: "Cost reduction",
  vien_phi_tong_chi_phi: "Total cost",
  vien_phi_mien_giam: "Exemptions",
  vien_phi_tong_thanh_toan: "Total",
  vien_phi_tong_tien_benh_nhan_dua: "Money given by customer",
  vien_phi_tien_tam_ung: "Prepayment",
  vien_phi_tien_thua: "Excess cash",
  vien_phi_so_hoa_don: "Invoice book",
  vien_phi_thanh_toan: "Payment ",
  vien_phi_Tao_tam_ung: "Create advance ",
  vien_phi_Hoan_ung: "Reimbursement",
  vien_phi_Tao: "Create",
  vien_phi_Don_gia: "Unit price",
  vien_phi_warning_khong_the_bo_chon_tat_ca: "Unable to unselect all",
  status_Dang_kham: "Examining",
  status_Hoan_thanh: "Completed",
  vien_phi_BN_phai_tra: "Patient must pay",
  vien_phi_tien_hoan_ung: "Reimbursement",
  vien_phi_Hoan_tien: "Refund",
  noti_vien_phi_ly_do_hoan_tien: "Please enter payback reason",
  noti_so_hoa_don: "Please select Invoice book",

  field_Yeu_cau_hoi_chan: "Request consultation",
  field_Thong_tin_nguoi_su_dung: "User Information",
  field_Chu_ky_so: "Digital Signatures",
  field_Anh_chu_ky_scan: "Image signature scan",
  field_Khoa_lam_viec: "Work Speciality",
  field_Phong_lam_viec: "Work Room",
  field_Ket_qua_tim_kiem: "Search Result",
  field_Kham_benh: "Medical Exam",
  field_dich_vu_kt: "Technical service",
  field_Dich_vu: "Service",
  field_Lua_chon_dich_vu: "Select service",
  field_Nhom_chi_phi_bhyt: "Fee Group Insurance",
  field_Nhom_danh_muc: "Category Group",

  Ban_chua_chon_benh_nhan_nao: "You have not selected any patients. Please select one patient to start the medical examination.",

  data_Tuyen_trung_uong: "Central line",
  data_Tuyen_tinh: "Provincial line",
  data_Tuyen_huyen: "District line",
  data_Tuyen_xa: "Ward line",
  data_Khong_phan_tuyen: "Unknown line",
  data_Hang_dac_biet: "Special class",
  data_Hang: "Class",
  data_Khong_xep_hang: "Unknown Class",
  data_Kham_benh: "Medical Exam",
  data_Kham_cls: "Preclinical examination",
  data_Noi_khoa: "Medical",
  data_Duoc: "Drug",
  data_Tai_chinh: "Finance",
  data_Xet_nghiem: "Test",
  data_CDHA: "Image Diagnosis",
  data_Khac: "Other",
  data_Vien_phi: "Hospital Fee",
  data_Bhyt: "Health Insurance",
  data_Lay_mau: "Sample",
  data_Nha_thuoc: "Drug store",
  data_Kho_bhyt: "Insurance Storage",
  data_Ngay: "Day",
  data_Tuan: "Week",
  data_Thang: "Month",
  data_Nam: "Year",
  data_Kho_thuoc_tu_nguyen: "Voluntary Drug Storage",
  data_Kho_thuoc_bhyt: "Insurance Drug Storage",
  data_Kho_chan: "Even Storage",
  data_Kho_le: "Odd Storage",
  data_Tu_truc: "Drug Cabinet",
  data_Noi_tru: "Inpatient Treatment",
  data_Ngoai_tru: "Outpatient Treatment",
  data_Noi_tru_Ngoai_tru: "In & Outpatient Treatment",
  data_buong_dieu_tri: "Treatment",

  field_Nguoi_tiep_don: "Receptionist",
  data_Giao_su: "Professor",
  data_Pho_giao_su: "Associate Professor",
  data_Tien_si: "Doctor",
  data_Thac_si: "Master",
  data_Bs_chuyen_khoa: "Specialist",
  data_Truong_khoa: "Dean",
  data_Pho_khoa: "Vice Dean",
  data_Pho_giam_doc: "Vice President",
  data_Giam_doc: "President",
  data_Bac_si: "Doctor",
  data_Quyen: "Permission",

  field_Ma_dvt: "Calculation unit code",
  field_Ten_dvt: "Calculation unit name",

  Cai_dat_llv: "Settings",
  Tuan_nay: "This week",
  data_Thu_2: "Monday",
  data_Thu_3: "Tuesday",
  data_Thu_4: "Wednesday",
  data_Thu_5: "Thursday",
  data_Thu_6: "Friday",
  data_Thu_7: "Saturday",
  data_Chu_nhat: "Sunday",
  field_Ca: "Shift",
  field_Thoi_gian_lam_viec: "Work Time",
  field_Tu: "From",
  field_Den: "To",
  field_Ngay_ap_dung: "Apply at",
  field_Thoi_gian_lam_viec_mac_dinh: "Default Work Time",
  field_Benh_nhan_tiep_theo: "Next Patient",
  field_So_benh_nhan_kham_mac_dinh: "Default number of patients examined per unit of time",
  field_Tai_phong_kham: "At clinic",
  field_phut: "mins",

  field_Ngay_tao: "Created at",
  field_Nguoi_tao: "Created by",
  field_Tong_chi_phi: "Total expense",
  field_Mien_giam: "Exemption",
  field_hinh_thuc_thanh_toan: "Payments type",
  In_hoa_don: "Print receipt",

  field_Ki_hieu: "Sign",
  field_Loai_so: "Book type",
  status_Moi: "New",
  status_Gan_day: "Almost Full",
  status_Da_day: "Full",
  field_So_phieu: "Votes",
  field_Ma_so: "Book code",
  field_Ten_so: "Book name",
  field_Tong_so_phieu: "Total votes",
  field_Nguoi_su_dung: "Users",
  field_So_bat_dau: "From number",
  field_So_ket_thuc: "To number",
  field_Phan_quyen_nguoi_su_dung_so: "Assign user permissions",

  noti_Chi_duoc_upload_anh: "Upload image only",
  noti_Kich_thuoc_anh_be_hon_5mb: "Image size must be smaller than 5MB",
  noti_Kich_thuoc_anh_be_hon_1mb: "Image size must be smaller than 1MB",
  noti_Chi_duoc_upload_excel: "Upload excel only",
  noti_Dang_nhap_thanh_cong: "Login successfully",
  noti_Dang_nhap_that_bai: "Login failed",
  noti_Xoa_thanh_cong: "Delete successfully",
  noti_Xoa_that_bai: "Delete failed",
  noti_Dang_xuat_danh_sach: "Exporting list...",
  noti_Xuat_danh_sach_thanh_cong: "Export successfully",
  noti_Xuat_danh_sach_that_bai: "Export failed",
  noti_Nhap_du_lieu_thanh_cong: "Import successfully",
  noti_Nhap_du_lieu_that_bai: "Import failed",
  noti_Xoa_danh_sach_thanh_cong: "Delete list successfully",
  noti_Xoa_danh_sach_that_bai: "Delete list failed",
  noti_Khoa_danh_sach_thanh_cong: "Update lock list successfully",
  noti_Khoa_danh_sach_that_bai: "Update lock list failed",
  noti_Chua_con_phong_thuc_hien: "The service room has not been selected or the service has no performance room",
  noti_Thuoc_da_ton_tai: "Drug already exists",
  noti_Chua_chon_thoi_gian_y_lenh: "Have not chosen the time of the order or haven't added the medicine yet",
  noti_Chua_chon_ngay_tai_kham: "Have not chosen a follow-up date yet",
  noti_Chua_co_thong_tin_kham: "No medical information yet",
  noti_Chua_chon_phong_thuc_hien: "The service room has not been selected or the service has no performance room",
  noti_Luu_lich_kham_thanh_cong: "Save exam schedule successfully",
  noti_Luu_lich_kham_that_bai: "Save exam schedule failed",
  noti_Chua_chon_benh_nhan_dv_thanh_toan: "Have not selected a patient or service to pay for",
  noti_Khong_the_huy_dich_vu_da_thanh_toan: "Can't cancel paid service",
  noti_Luu_cuoc_hen_thanh_cong: "Save appointment successfully",
  noti_Luu_cuoc_hen_that_bai: "Save appointment failed",
  noti_lay_danh_sach_lich_kham_that_bai: "Get Appoitment list failed",
  noti_vui_long_nhap_du_ngay_thang_nam: "Please enter the full date month year",
  noti_ten_dang_nhap_da_ton_tai: "Username have been exist",
  noti_ma_so_da_ton_tai: "Code have already exist",

  Dang_nhap: "Login",
  Dang_nhap_subtitle: "Login to continue using services \n of H247 Pro",
  field_Ten_dang_nhap: "Username",
  field_Nang_suat_kham: "Examination productivity",
  field_Danh_sach_thuoc: "Drug list",

  field_lich_lam_viec: "schedule",
  error_Chon_toi_thieu_1_ngay: "Please select at least one day",
  error_Chua_co_ca_lam_viec: "Please add at least one shift",

  field_Don_thuoc_tu_nguyen: "Voluntary prescription",
  field_Bac_si_ke_don: "Doctor prescribes",
  button_In_don_thuoc: "Print prescription",
  field_Loi_dan: "Advice",
  cf_Ban_co_chac_chan_xoa: "Are you sure you want to delete?",
  noti_Chua_chon_dich_vu_kham_benh: "You have not chosen any medical service yet",
  noti_Chua_chon_dich_vu_kham_benh_hoac_cdcls: "You have not chosen any medical or clinical service yet",
  label_Tao_cuoc_hen_moi: "Patient Appointment",
  error_view_Chua_co_don_thuoc: "You do not have prescription. Please create a new prescription now!",
  error_view_Khong_co_don_thuoc: "No prescription",

  field_chinh_sua_phieu: "note edit",
  field_Doi_tuong: "Object type",

  field_Gio_kham: "Time",
  title_cuoc_hen_sap_toi: "Appointment comming",
  title_Tao_cuoc_hen: "Create Appointment",
  title_Chinh_sua_cuoc_hen: "Edit Appointment",
  title_Chinh_sua_thong_tin_tiep_don: "Edit appointment Infomation",
  title_Xac_nhan: "Confirm",
  title_Gia_dich_vu: "Service price",
  title_phong_khám: "Room",
  noti_Tiep_tuc_khi_chua_ke_don_thuoc: "Prescription has not been done yet. Are you sure you want to continue?",

  noti_Dang_tai_du_lieu: "Loading data ...",
  noti_Tai_du_lieu_thanh_cong: "Load data successfully",
  noti_Tai_du_lieu_that_bai: "Load data failed",
  noti_Dang_kiem_tra_trang_thai_don_thuoc: "Checking prescriptions status",
  noti_warning_thay_doi_thoi_gian_phong:
    "There's incomplete service in this room in the moment, the booked services will be kept even when working time of this room is changed ",
  noti_warning_thuoc: "",
  noti_warning_thuoc_co_trong_don: " has been listed in prescription. Please check again",
  noti_warning_thuoc_khong_du: " not enough in this medicine storage. Please check again",
  noti_Cuoc_hen_khong_phai_hom_nay: "This appointment does not take place today.\n Are you sure you want to confirm this appointment?",
  noti_Dang_Tai_template: "Downloading template...",
  noti_Tai_template_thanh_cong: "Download template successfully",
  noti_Tai_template_that_bai: "Download template failed",
  common_Doi_ngon_ngu: "Language",
  field_Ma_cuoc_kham: "Exam ID",
  chi_tiet_cuoc_hen: "Appointment detail",
  chinh_sua_cuoc_hen: "Edit appointment",
  thong_tin_cuoc_kham: "Appointment info",
  thong_tin_kham_benh: "Exam info",
  thong_tin: "Information",
  the_kho: "Stock card",
  lo_han_su_dung: "Expiry",
  title_them_moi_nhom_thuoc: "Add medicine group",
  title_chinh_sua_nhom_thuoc: "Edit medicine group",

  error_Rang_buoc_ton_tai: "You cannot delete this category. This category is related to other categories.",
  error_Rang_buoc_khoa_ton_tai: "You cannot lock this category. This category is related to other categories.",
  common_Xuat_hang_cho: "Export queue",
  title_Hang_cho_kham_benh: "Exam Queue",
  title_Hang_cho: "Queue",

  error_Chua_chon_doi_tuong_benh_nhan: "You have not chosen patient object yet",
  error_Ngay_sinh_ko_hop_le: "Invalid date of birth",
  error_Chua_chon_thong_tin_luot_dieu_tri: "Please select full info of treatments",
  error_Khong_chon_ngay_khac_voi_ngay_truoc_do: "Do not select date that is different from the former date",

  field_Trang_thai_thanh_toan: "Payment status",
  field_Trang_thai_cuoc_hen: "Appointment status",
  title_Phieu_thu_da_tao: "Created receipt",
  error_Gia_tri_mien_giam_ko_hop_le: "Invalid exemptions",
  error_Khong_sua_duoc_lich_kham: "Schedule cannot be updated because appointments exist on this day",
  error_khong_the_khoa_phong_do_co_benh_nhan_cho_kham: "Can't lock this room due to scheduled appointment booked in this room",

  tab_Phieu_mua_thuoc: "Drug sale paper",
  tab_Lich_su_phieu: "Card history",
  field_Ma_phieu: "Card ID",
  placeholder_Ma_tu_dong_tao: "Auto-generated code",
  placeholder_Lua_chon_don_thuoc_mau: "Select sample prescription",
  common_Thay_the: "Alternate",
  txt_Them_lo: "Add lot",
  txt_Chon_lo: "Select lot",
  noti_lay_chi_tiet_phieu_mau_that_bai: "get sample slip details failed",
  txt_tu_choi: "Denied",

  noti_confirm_Huy_dich_vu: "Are you sure you want to cancel service?",
  field_Ly_do_hoan_tien: "Refund reason",
  field_Tong_tien: "Total",
  warning_url_notfound_title: "Access warning",
  warning_url_notfound: "You are accessing the wrong page with your current account, please login again to continue using!",
  warning_change_password: "Password changed, please login again to continue using!",
  field_Ly_do_mien_giam: "Reason for exemption",

  error_body__at_column: "at column",
  error_body__and_duplicate_value: "and duplicate value",

  noti_chua_nhap_lo_thuoc: "you have not entered the drug batch",
  noti_so_luong_thuoc_toi_da: "quantity has reached the maximum",
  noti_lo_khong_con_thuoc: "quantity is out",
  noti_lo_da_ton_tai: "lot already exists",

  //nhà cung cấp
  da_chon: "Selected",
  nha_cung_cap: "Supplier",
  ma_nha_cung_cap: "Supplier ID",
  ten_nha_cung_cap: "Supplier Name",
  dien_thoai: "Mobile phone",
  tong_mua: "Total purchase",
  driving_license: "Driving license",
  no_can_tra: "Debt",
  nhom_ncc: "Supplier group",
  tu: "From",
  toi: "To",
  no_hien_tai: "Current debt",
  tat_ca: "All",
  dang_hoat_dong: "Active",
  khong_hoat_dong: "Inactive",
  trang_thai: "Status",
  them_nha_cung_cap: "Add new suppiler",
  thong_tin_co_so: "Basic information",
  dia_chi: "Address",
  cong_ty: "Company",
  ma_so_thue: "Tax ID",
  tao_moi: "Save",
  huy: "Cancel",
  thong_tin_chi_tiet: "Details",
  thong_tin_camel: "INFORMATION",
  lich_su_nhap_tra_hang_camel: "IMPORT/RETURN HISTORY",
  quan_ly_cong_no_camel: "DEBT CONTROL",
  xoa: "Delete",
  luu: "Save",
  nhap_gia_tri: "Enter value",
  gia_tri: "Value",
  cong_no: "Debt",
  dieu_chinh: "Impale",
  xuat_file_cong_no: "Export debt file",
  in_file_cong_no: "Print debt file",
  no_can_tra_hien_tai: "Current liabilities",
  cai_dat_ngay_gio: "Set date and time",
  ngay_dieu_chinh: "Adjustment date",
  gia_tri_no_dieu_chinh: "Adjusted debt value",
  mo_ta: "Description",
  cap_nhat: "Update",
  tien_mat: "Cash",
  chuyen_khoan: "Bank transfer",
  no_sau: "Postpaid debt",
  tao_phieu_chi: "Create payment voucher",
  tao_phieu_chi_va_in: "Create payment voucher & print",
  tra_cho_ncc: "Pay the supplier",
  loai_phieu: "Voucher type",
  hay_chon_ngay: "Please select date",
  txt_luu_phieu_mau: "save sample form",
  txt_ten_phieu_mau: "sample sheet name",
  txt_tien_tra_ncc: "Supplier payment",
  txt_tinh_vao_cong_no: "Counted in debt",
  title_phieu_xuat_ban: "Drug sales slip",
  title_phieu_xuat_tra: "supplier payment slip",
  title_phieu_xuat_huy: "Cancellation slip",
  txt_tong_gia_tri_huy: "Total cancellation value",
  txt_tien_ncc_tra: "Vendor pay",
  txt_ncc_phai_tra: "Supplier must pay",
  da_nhap_hang: "Imported",
  da_tra_hang: "Paid",
  hay_chon_nhom: "Please select group",
  hay_dien_ma: "Please fill in",
  hay_dien_ten: "Please fill in",
  hay_nhap_so_dien_thoai: "Please fill in",
  hay_dien_email: "Please fill in",
  hay_dien_dia_chi: "Please fill in",
  hay_dien_ten_cong_ty: "Please fill in",
  nhap_ten: "Enter name",
  nhap_dia_chi: "Enter address",
  noti_them_thuoc_that_bai: "add new medicine failed",
  /// BẢNG ĐIỀU KHIỂN
  doanh_thu_trung_binh: "Average revenue",
  doanh_thu_trung_binh_moi_cuoc_kham: "Average revenue per visit",
  cuoc_kham: "Appointments",
  trung_binh_cuoc_kham: "Average examination",
  so_voi_ky_truoc: "compared to the previous period",
  tuoi: "age",
  so_benh_nhan_trong_ky: "Number of patients in the period",
  so_benh_nhan_ky_truoc: "Number of patients in the previous period",
  so_luong_benh_nhan_theo_thoi_gian: "Number of patients over time",
  benh_nhan: "Patients",
  tong_quan_benh_nhan: "Patient overview",
  ti_le_benh_nhan_theo_gioi_tinh: "Percentage of patients by sex",
  ti_le_benh_nhan_theo_do_tuoi: "Percentage of patients by age",
  don_vi_trieu_dong: "millions",

  tong_quan_dich_vu: "Total service revenue",
  tong_so_cuoc_kham: "Total number of visits",
  tong_so_benh_nhan: "Total number of patients",
  tong_quan_kinh_doanh: "Business overview",
  tong_quan_tiep_don_kham_chua_benh: "Overview of medical reception",

  field_Gia_nhap: "Import price",
  field_Gia_ban: "Export price",

  noti_tao_moi_don_thuoc_thanh_cong: "Create new prescription successfully",
  noti_tao_moi_benh_nhan_thanh_cong: "Create new patient successfully",
  noti_tao_moi_benh_nhan_that_bai: "Create new patient failed",
  noti_them_vao_hang_cho_thanh_cong: "Add to the drug sale queue successfully",
  noti_them_vao_hang_cho_that_bai: "Add to the drug sale queue failed",
  confirm_Chuyen_cuoc_kham_tu_hoan_thanh_sang_dang_kham: "This medical examination is currently done. Are you sure you want to continue examing?",
  confirm_Chuyen_cuoc_kham_sang_hoan_thanh: "The medical examination has been prescribed, do you want to confirm the end of the examination?",
  confirm_Thay_doi_kho_thuoc: "Selected medicines will be cleared on changing medicine storage.\nAre you sure to continue?",
  field_Ton: "Backlog",
  chon_phong: "Select room",
  loai: "Type",
  phong: "Room",
  thuoc: "Drug",
  doanh_thu_theo_thoi_gian: "Revenue over time",
  tong_doanh_thu: "Total revenue",
  dich_vu_cls: "Clinical service",
  truc_tiep: "Direct",
  qua_app: "Via App",
  qua_website: "Via Website",
  qua_zalo: "Via Zalo",
  ti_le_cuoc_kham_ung_voi_nguon_tiep_nhan: "Percentage of examinations by receiving means",
  so_cuoc_kham_va_so_dich_vu_thoi_gian_ung_voi_nguon_tiep_nhan: "Number of examinations and time services corresponding to the receiving means",
  ky_nay: "This period",
  ky_truoc: "Previous period",

  //NỘI TRÚ
  title_noi_tru: "Boarding",
  dm_Giuong: "List of hospital beds",
  dm_giuong_ma_giuong: "Bed code",
  dm_giuong_ma_giuong_bhyt: "Bed code BHYT",
  dm_giuong_ten_giuong: "Bed name",

  title_Bao_cao_nhanh: "Quick report",
  title_Danh_sach_phieu_thu: "List of receipts",
  title_Thu_phi: "Fee",
  tab_Bao_cao_phieu_thu: "Report receipt",
  tab_Bao_cao_vien_phi: "Hospital fee report",
  tab_Bao_cao_doanh_thu: "Hospital fee report",
  tab_Bao_cao_tiep_don: "Reception report",
  tag_Ban_thuoc: "Drug sale",
  button_Cai_dat_lai: "Reset filters",
  button_Xuat_excel: "Export excel",
  button_In_bao_cao: "Print report",
  label_Thoi_gian: "Filter time",
  label_Loai_phieu: "Receipt type",
  option_Tat_ca: "All",
  confirm_xoa_don_thuoc: "Are you sure to delete this prescription ?",

  // Quản lý bệnh nhân
  cai_dat_hien_thi: "Display configuration",
  field_do_tuoi: "Age group",
  field_ngay_kham_gan_nhat: "Most recent examination date",
  field_nho_hon_1_tuoi: "< Age 1",
  field_1_11_tuoi: "Age 1 - 11",
  field_12_17_tuoi: "Age 12 - 17",
  field_18_64_tuoi: "Age 18 - 64",
  field_lon_hon_64_tuoi: "> Age 64",
  bao_hiem_y_te: "Medical Insurance",
  danh_sach_lich_su_kham_benh: "List of medical history",
  field_chuan_doan_benh_chinh: "Diagnosis of main disease",
  field_cskcb: "Hospital",
  field_khoa_theo_byt: "Ministry of Health's Department",
  field_co_so_kham_chua_benh: "Hospital",
  field_ngay_tai_kham: "Re-examination date",
  danh_sach_phieu_thu: "List of receipts",
  field_loai_phieu_thu: "Type of receipt",
  field_phan_loai: "Classified",
  field_hoan_tien: "Refund",
  txt_ngay_gan_nhat: "Recent days",
  txt_thang_gan_nhat: "Recent months",
  txt_tuy_chon: "Custom",

  //Loại thanh toán
  loai_phieu_nhap: "Input slip",
  loai_phieu_xuat: "Export slip",
  loai_phieu_ban_le: "Retail voucher",
  loai_phieu_dieu_chin: "Adjustment slip",
  loai_phieu_thanh_toan: "Payment slip",
  loai_phieu_xuat_huy: "Cancellation ticket",
  loai_phieu_xuat_tra_ncc: "NCC payment slip",
  loai_phieu_xuat_ban: "Receipt for sale",

  noti_chua_co_phieu_ban_thuoc: "You do not have any prescriptions yet. Please create a new one.",
  noti_chua_chon_benh_nhan_ban_thuoc: "You have not selected any prescriptions yet",
  error_Thoi_gian_khong_hop_le: "Invalid datetime",

  //thông tin bệnh nhân, tạo mới bệnh nhân
  tao_moi_benh_nhan: "Create a new patient",
  tab_Phieu_thanh_toan: "Payment slip",
  tab_Phieu_hoan_tien: "Refund Voucher",
  cf_Ban_co_chac_chan_khoa: "Are you sure you want to block these items?",
  cf_Ban_co_chac_chan_mo_khoa: "Are you sure you want to unblock these items?",

  // NỘI TRÚ
  ma_bn: "Patient Code",
  ma_lk: "Code visits ",
  chon_khoa: "Select department",
  loc_BN: "Filter patient",
  in_bao_cao: "Print report",
  dich_vu: "Service",
  thuoc_vat_tu_mau: "Medicines, supplies, blood",
  dieu_duong: "Nursing",
  dieu_tri: "Treatment",
  xet_nghiem: "Test",
  cdha: "IA",
  pttt: "Surgical procedure",
  thuoc_vat_tu: "Medicines, supplies",
  dich_vu_khac: "Other services",
  xu_tri: "To solve",
  ngay_chi_dinh: "Designated date",
  ngay_y_lenh: "Order day",
  noi_chi_dinh: "Order place",
  ngay_ket_qua: "Result date",
  noi_thuc_hien: "Place of execution",
  ten_chi_so: "Index",
  tham_chieu: "Reference",
  ten_PTTT: "Name PTTT",
  loai_PTTT: "Type PTTT",
  ten_thuoc_vat_tu: "Medicines, supplies",
  sl: "Quanity",
  HDSD: "User manual",
  ngay_vao_giuong: "Date of entry",
  ngay_ra_giuong: "Release date",
  chon_hinh_thuc: "Choose form",
  nhap_PTTT: "Enter PTTT",
  bat_dau_luc: "Started at",
  ket_thuc_luc: "Ended at",
  chan_doan_truoc_PT: "Preoperative diagnosis",
  chan_doan_sau_PT: "Diagnosis after surgery",
  cach_thuc_PT: "Surgical method",
  phuong_phap_PT: "Surgical method",
  phuong_phap_vo_cam: "Emotionless method",
  mo_ta_chi_tiet: "Detailed description",
  nhom_mau: "Blood group",
  tai_bien_PTTT: "catastrophe surgical",
  hinh_anh_PTTT: "Image surgical",
  in_tuong_trinh: "Print report",
  tip_in_ban_ghi: "Print record",
  tip_copy_ban_ghi: "Copy record",
  ho_va_ten: "Name",
  ket_luan: "Result",
  chi_tiet: "Detail",
  loai_giuong: "Bed type",
  giuong: "Bed",
  benh_kem_theo: "Including diseases",
  benh_chinh: "Main disease",
  chan_doan: "Diagnose",
  da_thuc_hien: "Executed",
  dang_thuc_hien: "Processing",
  chua_thuc_hien: "Unfulfilled",
  dieu_tri_noi_tru: "Inpatient treatment",
  chuyen_khoa: "Transfer faculty",

  rh_am: "Minus",
  rh_duong: "Positive",
  tren_ban_mo: "On the operating table",
  trong_24h: "In 24h",
  sau_24h: "After 24h",
  do_phau_thuat: "Due to surgery",
  do_thu_thuat: "By tricks",
  do_gay_me: "Due to anesthesia",
  do_nhiem_khuan: "Due to infection",

  //DM Giường
  loai_giuong_thuong: "Normal bed",
  loai_giuong_ghep_2: "Compound bed 2",
  loai_giuong_ghep_3: "Compound bed 3",

  data_Thu_ngan: "Cashier",
  data_Ky_thuat_vien: "Technician",
  data_Dieu_duong: "Nurse",
  data_Thu_kho: "Stocker",
  data_Quan_tri: "Administrator",

  field_Nhom_nhan_vien: "Staff group",
  //dược ngoại trú
  txt_them_thuoc: "Add Drug",
  txt_luu_thuoc: "Save drug",
  txt_chon_kho: "Select repository",
  txt_chon_thuoc: "Select drug",
  txt_ds_thuoc_chi_dinh: "List of prescription drugs",
  txt_thanh_toan: "Payment",
  txt_tong_tien_truoc_vat: "Total amount before VAT",
  txt_tong_tien_vat: "Total VAT",
  txt_tong_tien_sau_vat: "Total amount after VAT",
  txt_chua_ban: "Not for sale",
  txt_da_ban: "Sold",
  txt_duyet_kho: "Accept store",
  txt_ngay_ke_don: "Date",

  txt_luu_mau_don_thuoc: "Save prescription sample",
  txt_ma_mau_don_thuoc: "Code",
  txt_ten_mau_don_thuoc: "Name",
  txt_placeholder_chon_don_thuoc_mau: "Select sample",
  txt_luu_mau_thong_tin: "Save information form",

  field_Gia_thang_du: "Overvalue",

  menu_Nha_cung_cap: "Supplier",
  So_luong_ton_kho: "Inventory number",
  ton_kho: "Inventory",
  Phieu_nhap_kho: "Inventory receiving voucher",
  Phieu_xuat_kho: "Inventory delivery voucher",
  Van_chuyen_kho: "Transfer warehouse",
  The_kho: "Stock card",
  vien_phi_thanh_toan_va_in: "Pay and print",
  vien_phi_Hoan_tien_va_in: "Refund and print",

  Quyen_duyet_phieu: "Authority to approve voucher",
  luu_va_in: "Save and print",
  //alert

  khong_duoc_phep_xoa_lich_kham: "You cannot delete a paid appointment or other pending status",
  khong_duoc_phep_xoa_phieu: "Cannot delete the tiket completed !",
  // date
  thu_2: "Monday",
  thu_3: "Tuesday",
  thu_4: "wednesday",
  thu_5: "Thursday",
  thu_6: "Friday",
  thu_7: "Saturday",
  chu_nhat: "Sunday",
  so_hoa_don_da_day: "The cash register is full",
  title_them_phieu_nhap_ncc: "Add new supplier entry ticket",
  fieldMa_hoa_don: "Bill code",
  placeholder_Tao_tu_dong: "Auto genarate",

  info_Chi_xem_va_khong_duoc_thay_doi: "Read only",

  ma_phieu_bi_trung: "Duplicate Card ID",
  bac_si_chua_co_ma_cchn: "Please update your Practicing certificate",

  nhap_loi_dan: "Input advice",
  ris_nhap_tay: "Input handle RIS",
  da_nghi: "Inactive",
  chua_co_phong_thuc_hien: "No room available",

  lich_su_nhap_thuoc: "Drug import history",
  gia_co_vat: "Price VAT",
  loai_thuoc: "Drug type",
  field_thoi_gian_tao: "Create time",

  txt_them_moi_kho_thuoc: "Add new warehouse",
  kho_chuyen: "Warehouse transfer",
  duyet_khi_ke_thuoc: "Browse when prescribing",
  duyet_khi_chuyen_cac_kho: "Browse when switching repositories",

  // loai kho
  kho_le: "Retail warehouse",
  kho_tong: "Total warehouse",
  nha_thuoc: "Drugstore",
  field_SL: "Quanity",

  benh_nhan_bhyt: "Patient BHYT",
  benh_nhan_noi_tru: "Inpatient",
  benh_nhan_ngoai_tru: "Outpatient",

  nhap_tu_ncc: "Slowly enter the supplier",
  nhap_tu_kho_khac: "Import from another repository",
  xuat_vo_hong: "Break out",
  xuat_het_han_su_dung: "Expired export",
  xuat_khac: "Other export",
  ban_benh_nhan: "Sell to patients",
  tao_phieu: "Create ",
  ma_phieu: "Card ID",
  them: "Add",
  txt_can_tra_ncc: "Need to pay supplier",
  thong_tin_thuoc: "Infomation drug",
  so_benh_nhan: "Patient number",
  thong_tin_kham_nhanh: "Quick examination information",
  thong_tin_dich_vu_cls: "Clinical service information",
  chi_tiet_tiep_don_kham_chua_benh: "Details of medical reception",
  luu_va_in_phieu_kham: "Save and print the examination report",
  luu_va_in_phieu_cls: "Save and print the subclinical sheet",

  field_Nam_sinh: "Birthyear",
  danh_sach_kho_thuoc: "List warehouse",
  chi_tiet_kho: "Deatail",
  bs: "DR.",

  phan_quyen_nguoi_ban: "Authorization of the seller",
  cho_phep_ban_gia_thang_du: "Selling surplus price",
  huy_duyet_phieu: "Cancel confirm ticket",
  huy_phieu: "Cancel ticket",
  confirm_cancel_ticket: "Are you sure you want to cancel your ticket?",
  luu_va_duyet: "Save & Confirm",
  tong_tien: "Total",
  tong_tt: "Total payment",
  duyet: "Confirm",
  da_HT: "Success",
  phieu_nhap: "Receipt",
  phieu_chuyen_kho: "Warehouse transfer",

  phan_loai_xuat_tra_ncc: "Return",
  phan_loai_xuat_vo_hong: "Broken",
  phan_loai_xuat_huy: "Cancellation",
  phan_loai_ban_benh_nhan: "Sell patients",
  phan_loai_xuat_khac: "Other",
  phan_loai_xuat_het_han: "Expired",
  field_Ngay_bat_dau: "Start day",
  field_Ngay_ket_thuc: "End date",
  limit_thoi_gian_1_nam: "Availability has now exceeded 1 year",
  field_Ma_may: "Machine code",

  mau_mo_ta_chan_doan_hinh_anh: "mau_mo_ta_chan_doan_hinh_anh",
  common_Them: "Add",
  nguoi_tao: "Create by",
  them_moi_kho: "Creat warehouse",
  khoa: "Department",
  cho_duyet: "Wait confirm",
  dm_may: "Machine",
  danh_sach_thuoc_chi_dinh: "List drug",
  data_thu_thuat: "Mechanical",

  //// Trả kết quả CDHA
  title_page_tra_kq_cdhd: "Return diagnostic imaging results",
  cho_TH: "Wait",
  dang_TH: "Processing",
  da_TH: "Done",
  ngay_tra_kq: "Results day",
  nguoi_thuc_hien: "Performer",
  nguoi_tra_kq: "Person result",
  chup_anh: "Take photo",
  thoi_gian_tra: "Time",
  in_kq: "Print",
  huy_tra: "Cancel",
  tra_ket_qua: "Return",
  thuc_hien: "Doing",
  da_tra_kq: "Returned",
  da_KQ: "Result",
  xem_KQ: "View result",
  content_huy_tra_phieu: "Are you sure cancel ticket?",
  tra_ket_qua_va_in: "Return and print",
  chon_mau_mo_ta: "Choose description template",
  chon_may_thuc_hien: "choose execution machine",
  noti_vui_long_chon_may_thuc_hien: "Please choose execution machine first",
  noti_vui_long_chon_may_thuc_hien: "Please choose execution room first",
  noti_so_luong_anh_toi_da: "Maximum amount image",
  noti_tra_kq_thanh_cong: "return success",
  noti_tra_kq_that_bai: "return failed",
  title_form_nhap_ket_qua: "Result input",

  // Support
  gui_cau_hoi: "Submit question",
  nhap_noi_dung: "Input detail",
  gui: "Submit",
  cau_hoi_cua_toi: "My question",
  danh_sach_cau_hoi_thuong_gap: "Frequently asked questions",
  hoi_dap: "Q&A",
  noi_dung_phan_hoi: "Feedback content",
  cong_cu_ho_tro: "Support tools",
  nhap_noi_dung_phan_hoi: "Enter feedback text",
  cham_soc_khach_hang_context: "We'd love to hear your questions and comments. Please let us know your problem !",
  cham_soc_khach_hang: "Customer care",
  huong_dan_su_dung_he_thong: "User Manual",
  tai_pdf: "Download PDF",

  phan_quyen_tao_mau_mo_ta: "Create",
  phan_quyen_sua_mau_mo_ta: "Edit",
  phan_quyen_xoa_mau_mo_ta: "Delete",
  phan_quyen_khoa_mau_mo_ta: "Lock",
  phan_quyen_tra_ket_qua: "Return",
  phan_quyen_huy_phieu: "Cancel",

  so_hoan_tien: "Refund note",

  field_Ten_may: "Machine name",
  field_Ma_BHYT: "Health insurance code",
  field_Loai_may: "Machine type",
  title_Danh_sach_nguoi_dung: "User list",
  menu_mau_mo_ta_cdha: "RIS description template",
  menu_tra_kq_cdha: "Return result",
  bao_cao_cdha: "Report",
  huy_tra_phieu: "Cancel ticket",
  title_Danh_sach_mau_mo_ta: "Description template list",
  title_Tao_mau_mo_ta: "Create description template",

  mau_danh_muc: "Category template",
  field_ma_kq_dv: "Service result code",
  field_ten_kq_dv: "Service result name",

  chon_nhom_dv: "Select service group",
  chon_loai_dv: "Select service type",
  chon_dv: "Select services",
  btn_mo_file_mo_ta: "Open description file",
  placeholder_nhap_mo_ta: "Enter description...",
  field_ket_luan: "Conclusion",
  tu_dong: "Auto generated",
  field_mo_ta: "Description",
  field_may_thuc_hien: "Execution machine",
  warning_chon_nhom_dv: "Please select service group",
  warning_chon_loai_dv: "Please select service type",

  btn_xem_them: "Load more",
  field_Ma_CK: "Exam code",
  btn_chen_anh: "Attach",
  btn_chup_anh: "Take picture",
  common_cai_dat_lai: "Reset",
  common_Ngay_mai: "Tomorrow",
  next_7_days: "Next 7 days",
  next_30_days: "Next 30 days",
  last_7_days: "Last 7 days",
  last_30_days: "Last 30 days",
  other_option: "Other option",
  tong_tra: "Total amount",
  title_Danh_sach_thuoc_nhap: "List of imported drugs",

  //key phân hệ khám bệnh
  khambenh_7ngay_gan_nhat: "Last 7 days",
  khambenh_30ngay_gan_nhat: "Last 30 days",
  khambenh_xemthem: "See more",
  khambenh_phieu_so: "Voucher No",
  khambenh_err_xoa_dich_vu: "Paid services cannot be deleted",
  khambenh_du_kq: "Result Fulfilled",
  khambenh_cho_kq: "Result Pending",
  khambenh_xoa_ban_ghi: "Delete record",
  khambenh_confirm_xoa: "Are you sure to delete this service ?",
  khambenh_moi_ngay: "Everyday",
  khambenh_chia_lam: "Divided",
  khambenh_lan: "Time",
  khambenh_so_luong_thuoc_lon_hon_0: "The number of drugs needed is greater than 0",
  khambenh_so_luong_thuoc_khong_du: "The amount of medicine in stock is not enough",
  khambenh_toi_da: "maximum",
  khambenh_thuoc: "Medicine",
  khambenh_chua_dien_cach_dung: "did not fill in the amount or usage",
  khambenh_thuoc_da_het: "The medicine is out of stock",
  khambenh_sl_toida: "Maximum number",
  khambenh_empty: "Empty",
  khambenh_khong_huy_don_da_ban: "An approved prescription cannot be canceled",
  khambenh_khong_sua_don_da_ban: "Approved prescription cannot be edited",
  khambenh_cho_thanh_toan: "Wait for pay",
  khambenh_da_thanh_toan: "Paid in full",
  khambenh_homqua: "Yesterday",
  vienphi_dv_khong_thanh_toan: "Service cannot be paid due to performed",
  vienphi_hoan_tien_dich_vu: "Service Refund",
  vienphi_ds_dv_can_hoan_tien: "List of services to be refunded",
  vienphi_tong_mien_giam: "Total exemption and reduction",
  vienphi_da_thanh_toan: "Paid",
  vienphi_da_thuc_hien: "Already tried",
  vienphi_chua_thuc_hien: "Not yet",
  vienphi_hoan_tien: "Refund",
  vienphi_xoa_dich_vu: "Remove service",
  vienphi_so_hd_day: "Invoice book is full",
  vienphi_tong_tt: "Total payment",
  vienphi_vui_long_chon_so_tt: "Please select the collection book!",
  vienphi_no_service: "No service",
  vienphi_dv_da_tt: "Paid Service",
  vienphi_chon_so_hoan_tien: "Please select a refund book!",
  vienphi_da_hoan_tien: "Refunds",
  vienphi_ds_cac_dichvu: "List of services",
  vienphi_in_phieu_thu: "Print receipt",
  vienphi_chua_thanh_toan: "Not yet paid",
  vienphi_da_tt: "Paid",
  vienphi_ngay: "Date",
  vienphi_ghi_chu: "Notes",

  //key phân hệ tiếp đón
  tiepdon_nguoi_sua: "Editor",
  tiepdon_ngay_sua: "Correction date",
  tiepdon_nhap_lien_tuc: "Continuous input",
  tiepdon_chia_se_thong_tin: "Share health profile",
  tiepdon_khong_xoa_dv_da_tt: "Paid services cannot be deleted!",
  tiepdon_chi_chon_1_ngay: "You can only choose 1 exam date per appointment!",
  tiepdon_xoa_cuoc_hen: "Delete appointment",
  tiepdon_huy_cuoc_hen: "Cancel appointment",
  tiepdon_chinh_sua_cuoc_hen: "Edit appointment",
  tiepdon_cho_xac_nhan: "WAIT FOR CONFIRMATION",
  tiepdon_da_xac_nhan: "CONFIRMED",
  tiepdon_da_huy: "CANCELLED",
  tiepdon_xac_nhan_xoa_cuoc_kham: "Are you sure you want to delete this exam?",
  tiepdon_xac_nhan_xoa_ck_da_chon: "Are you sure you want to delete the selected exam?",
  tiepdon_chua_chon_phong: "No room selected yet!",
  tiepdon_doi_cuoc_hen_thanh_cong: "Change appointment successfully!",
  tiepdon_doi_cuoc_hen_that_bai: "Change of appointment failed!",
  tiepdon_in_dich_vu: "Print Service",
  tiepdon_da_nghi: "Retired",
  tiepdon_khong_hoat_dong: "inactive",
  tiepdon_da_kham: "Checked",
  tiepdon_xoa_cuoc_kham: "Delete the examination",
  tiepdon_xac_nhan_huy_ck: "Are you sure you want to cancel these exams?",
  tiepdon_in_cuoc_kham: "Print the examination",
  tiepdon_sua_cuoc_kham: "Edit examination",
  tiepdon_xac_nhan_huy_cuoc_kham: "Are you sure you want to cancel this exam?",
  tiepdon_xac_nhan_huy_lich_hen: "Are you sure you want to cancel this appointment?",
  bc_da_thuc_hien: "Done",
  bc_chua_thuc_hien: "Unfulfilled",
  trang_thai_thuc_hien: "Execution status",
  xac_nhan_cuoc_hen_thanh_cong: "Confirm examination successfully",

  // fix ngôn ngữ dược
  duoc_message_chua_chon_loai_kho: "Have not selected the type of warehouse",
  con_ton: "Still exist",
  da_het: "It's over",
  duoc_chon_nguoi_ban: "Select authorization of the seller",
  duoc_ton_kha_dung: "Available",
  duoc_nguoi_huy: "Canceler",
  duoc_ngay_huy: "Cancellation date",
  duoc_thuoc: "Drug",
  duoc_message_lay_ds_kho_thuoc_loi: "Get list of bug potion stock",
  duoc_xoa_nha_cung_cap: "Delete",
  sua_nha_cung_cap: "Edit",
  confirm_xoa_nha_cung_cap: "Are you sure to delete this provider?",
  duoc_hay_nhap_gia_tri_lon_hon_0: "Please enter a value greater than 0",
  duoc_phieu_dieu_chinh_chi_tiet: "Detailed adjustment adventure",
  duoc_in_phieu_thanh_toan: "Print",
  duoc_phieu_thanh_toan_chi_tiet: "Detailed payment slip",
  duoc_ma_ncc_trung: "Vendor code is the same",
  duoc_ma_so_thue_trung: "Duplicate tax code",
  duoc_them_moi_nhom_ncc: "Add new supplier group",
  duoc_ten_nhom_ncc: "Supplier group name",
  duoc_nhap_gia_tri_nho_toi_lon: "Please enter a value from small to large",
  da_xac_nhan: "Confirmed",
  duoc_chua_chon_kho_thuoc: "Haven't selected a pharmacy",
  duoc_so_luong_toi_da: "Maximum quantity",
  duoc_so_luong_lon_hon_ton_kha_dung: "Larger quantity available",
  duoc_thuoc_da_ton_tai: "The drug already exists ",
  duoc_so_luong_thuoc_khong_kha_dung: "Number of drugs not available",
  duoc_chi_tiet_phieu_chuyen_kho: "Details of the transfer note",
  duoc_them_phieu_chuyen_kho: "Add transfer slip",
  duoc_kho_xuat_thuoc: "Export warehouse",
  duoc_kho_nhap_thuoc: "Import warehouse",
  duoc_chua_chon_kho_xuat: "Select export warehouse",
  duoc_xoa_thuoc: "Delete drug",
  duoc_gia_nhap_lon_hon_gia_ban: "The import price is greater than the selling price",
  duoc_chua_nhap_thuoc: "Haven't imported medicine yet",
  duoc_gia_sau_vat: "Price after VAT",
  title_phieu_nhap_ncc: "Supplier entry slip",
  duoc_chua_chon_thuoc: "Haven't chosen medicine yet",
  duoc_phieu_xuat_tra_ncc: "delivery note",
  duoc_phieu_xuat_vo_hong: "broken out",
  duoc_phieu_xuat_huy: "export cancel",
  duoc_phieu_xuat_het_han: "expired export",
  duoc_phieu_xuat_khac: "other export",
  duoc_phieu_ban_benh_nhan: "sell export",
  duoc_ly_do_xuat: "Reason export",
  duoc_chon_kho_thuoc: "Select warehouse",
  duoc_chon_ncc: "Supplier selection",
  duoc_vui_long_ke_don_thuoc: "Please prescribe medicine",
  duoc_khong_du_so_luong: "not enough quantity",
  duoc_nhap_day_du_thong_tin_benh_nhan: "Fill in patient information",
  duoc_chua_ban: "Not sold yet",
  duoc_da_ban: "Sold",
  duoc_nam_sinh_hop_le: "Enter a valid birth year",
  duoc_khach_vang_lai: "Passersby",
  duoc_them_benh_nhan: "Add patient",
  duoc_huy_don: "Cancellation",
  duoc_huy_duyet: "Cancel approval",
  duoc_duyet_don: "Approval",
  duoc_huy_thanh_toan: "Cancel payment",
  duoc_xuat_kho_in_phieu: "Export stock + Print",
  xuat_kho: "Export stock",
  duoc_huy_xuat_kho: "Cancellation export",
  duoc_confirm_xoa_don_thuoc: "You definitely want to delete this prescription?",
  phan_mem_ho_tro: "Remote device access software",
  mau_dm_khoa: "Faculty list",
  mau_dm_phong: "Room catalog",
  mau_dm_don_vi_tinh: "Unit list",
  mau_dm_nhom_dich_vu: "Service group catalog",
  mau_dm_loai_dich_vu: "Service type catalog",
  mau_dm_dich_vu: "Service catalog",
  mau_dm_thuoc: "Drug list",
  mau_dm_hang_san_xuat: "Manufacturers",
  mau_dm_hoat_chat: "active ingredients",
  mau_dm_duong_dung: "How-to-use catalogries template",
  tong_hop_template_mau_danh_muc: "Synthesize sample",
  tai_tat_ca_cac_mau: "Download all sample",
  bao_cao_duoc: "Pharmaceutical report",

  // fix ngôn ngữ báo cao
  //title báo cáo
  bao_cao_theo_doi_thu_ngan: "Cashier tracking report",
  bao_cao_bac_si_chi_dinh: "Report to the appointed doctor",
  bao_cao_phong_chuc_nang: "Report to the function room",
  bao_cao_doanh_thu: "Sales report",
  bao_cao_thu_ngan_benh_nhan: "Cashier tracking by patient and service report",
  bao_cao_the_kho: "Report card stock",
  bao_cao_su_dung_thuoc_khang_sinh: "Report on antibiotic use",
  bao_cao_bien_ban_kiem_ke_thuoc: "Drug inventory record",
  bao_cao_xuat_nhap_ton: "Import existing reports",
  bao_cao_phuong_thuc_thanh_toan: "Report to payment type",
  bao_cao_doanh_thu_ban_thuoc: "Medicine sales report",

  // bao_cao_bac_si_chi_dinh : "",
  // bao_cao_bac_si_chi_dinh : ""
  // fixx ngôn ngữ báo cáo
  bao_cao_tien_kham: "Medical examination fee",
  bao_cao_tien_dvkt: "Service money",
  bao_cao_tien_thuoc: "Drug money",
  bao_cao_tien_huy: "Cancellation money",
  bao_cao_tien_xet_nghiem: "Test money",
  bao_cao_tien_cdha: "Image analysation money",
  bao_cao_tien_khac: "Other",
  bao_cao_tong_cong: "Total",
  bao_cao_tien_mien_giam: "Free money",
  phong_chuc_nang: "Function room",
  bao_cao_xuat_tat_ca_thuoc: "Export all drugs",
  bao_cao_xuat_tat_ca_thuoc_co_lich_su_nhap_xuat: "Export all drugs with import and export history",
  bao_cao_sl_so_sach: "Number",
  bao_cao_ngoai_tru: "Outpatient",
  bao_cao_tien: "Money",
  bao_cao_ten_thuoc_ham_luong_nong_do: "Drug name, concentration, strength",
  bao_cao_duoc: "Pharmacy report",
  bao_cao_chon_kho_thuoc: "Choose medicine store",
  bao_cao_chon_loai_thuoc: "Choose medicine type",

  bao_cao_ton_dau_ky: "Survive head",
  bao_cao_ton_cuoi_ky: "Survive end",
  bao_cao_sl_nhap: "Amount import",
  bao_cao_sl_xuat: "Amount export",
  bao_cao_khac: "Other",
  bao_cao_sct_nhap: "License import",
  bao_cao_sct_xuat: "License export",
  bao_cao_lo_sx: "Production batch",
  bao_cao_han_dung: "Due date",
  bao_cao_dien_giai: "Explain",
  bao_cao_sl_ton_dau: "Initial stock",
  bao_cao_hl: "Content",
  bao_cao_sl_ton_cuoi: "End stock",
  bao_cao_thoi_gian_365_ngay: "Invalid search time or more than 365 days",
  bao_cao_khong_co_thuoc: "No medicine",
  bao_cao_chon_nhan_su: "Select personnel",
  bao_cao_nhan_su_da_ton_tai: "Personnel already exist",
  bao_cao_thanh_phan_tham_gia: "Participants",
  bao_cao_them_thanh_phan_tham_gia: "Add participants",
  bao_cao_chon_kho_xuat_bao_cao: "Select the report export repository",
  bao_cao_vui_long_nhap_thong_tin_thanh_phan_tham_du: "Please enter participant information",
  bao_cao_chuc_vu: "Position",
  bao_cao_sdkt: "Registration number",
  bao_cao_hinh_thuc_thanh_toan: "Reported of payment types",

  common_lua_chon_khac: "Another choice",
  common_xuat_file_thanh_cong: "Export file success",
  common_co_loi_xay_ra: "An error occurred",
  commom_khong_co_du_lieu: "No data",
  nguoi_lap_bao_cao: "The reporting person",
  so_dang_ky: "Registration number",
  so_chung_tu: "License number",
  so_luong_dau_ky: "Quantity at the beginning of the period",
  stt: "No.",
  ten_bs_chi_dinh: "Doctor name",
  cong_khoan: "Total payment",
  bac_si: "Doctors",
  truong_phong_tckt: "Head of Finance and Accounting Department",
  ky_ten_va_dong_dau: "Signed and sealed",
  ky_ten_va_ghi_ro_ho_ten: "Sign and write full name",
  ma: "ID",
  ten_bn: "Patient name",
  truong_khoa_duoc: "Head of Pharmacy Department",
  to_kiem_ke_gom_co: "The inventory team",
  thoi_gian_kiem_ke_thuoc: "Inventory drug date",
  dia_chi_kiem_ke: "Inventory address",
  hong_vo: "Broken",
  han_dung: "Due date",
  ghi_nho: "Note",
  so_sach: "Documented",
  thuc_te: "Reality",
  y_kien_de_xuat: "Recommendations",
  thanh_vien: "Member",
  sl_nhap: "Import num",
  sl_xuat: "Export num",
  thuoc_kho: "Storage",
  ten_nhan_vien: "Staff name",
  so_nhan_vien: "personels",
  nhan_vien: "Staff",
  thong_ke_phong_chuc_nang: "Function rooms report",
  xuat: "Export",
  nguoi_lap_bang: "Table maker",
  hoa_don_ban_hang: "Receipt",
  khach_hang: "Customer",
  khach_mua_theo_don: "Customer buy by order",
  khach_vang_lai: "Passersby",
  ten_san_pham: "Product name",
  tong_tien_hang: "Total amount",
  chiet_khau_hoa_don: "Invoice discount",
  chiet_khau: "Discount",
  tong_thanh_toan: "Total payment",
  cam_on_va_hen_gap_lai: "Thank you and see you next time",
  phieu_nhap_hang: "Import coupon",
  nguoi_lap: "Creator",
  chi_nhanh: "Branch",
  ma_hang: "PLU",
  ten_hang: "Product name",
  giam_gia: "Discount",
  tong_thanh_tien: "Total money",
  mien_giam_hoa_don: "Exemptions bill",
  tien_ncc_can_tra: "Suppliers need to pay",
  ky_va_ghi_ro_ho_ten: "Sign and write full name",
  gia_tri_huy: "Cancellation value",
  sl_huy: "Number of cancellations",
  ma_so: "Code",
  thoi_gian: "Date",
  so: "Number",
  ten_thuoc_hoa_chat: "Names of drugs, chemicals, medical consumables",
  ham_luong_nong_do: "Content, concentration, packing specifications",
  ki_thuat_chi_dinh: "Designated technique",
  loi_dan_cua_bac_si_khoa: "Doctor's advice",
  bac_si_phu_trach_kham: "Doctor in charge of examination",
  do_tuoi: "Age",
  kham_lai_theo_don_thuoc_nay: "Follow up with this prescription",
  bang_chu: "In letters",
  thong_tin_bo_loc: "Filter information",
  ten_benh_nhan: "Patient name",
  ma_don_thuoc_mau_da_ton_tai: "Sample prescription code already exists, do you want to replace the saved form?",
  ma_khach_hang: " Client code",
  kham_ngay: "Day of the exam",
  Phieu_co_gia_tri_trong_ngay: "Date of ticket",
  thu_ngan_giu: "Cashier kept",
  khach_hang_giu: "Client kept",
  thong_tin_khach_hang: "Client infomation",
  ten_khach_hang: "Client name",
  tong_so_tien: "Total cost",

  title_Phieu_thu_phi_dich_vu: "Service fee receipt",
  title_Phieu_tom_tat_kham: "Examination summary sheet",
  nguoi_tao_bao_cao: "Reporter",
  sdt: "PN",
  field_doi_tuong_bn: " Object type",
  cong_hoa_xa_hoi_chu_nghia: "Socialist Republic of Vietnam",
  doc_lay_tu_do_hanh_phuc: "Independence - Freedom - Happiness",
  txt_ngay_nhap: "Date Added",

  txt_hoa_don_so: "Digital receipt",
  txt_nhap_tai_kho: "Enter at the warehouse",
  common_da: "had",

  txt_nguoi_giao: "transtporter",
  txt_ke_toan: "accountant",
  txt_Thu_truong_don_vi: "Header of Unit",

  noti_so_tien_am: "Cash is less than zero",
  noti_khong_dong: "Is zero",
  title_Chi_tiet_cong_no_ncc: "Supplier dept detail",
  no_dau_ky: "Dept at the begining",
  txt_cong_khoan: "Total amount",
  txt_nguoi_tao_phieu: "Voting creator",
  title_phieu_thanh_toan: "Payment ticket",
  txt_tm_cong_ty: "Company representative",
  common_Tong: "Total",
  txt_phieu_chi: "payment slip",
  txt_nguoi_nhan_tien: "Receivers",
  txt_ly_do_nhan: "Reason for receive",
  txt_So_tien: "Amount of money",
  txt_thu_quy: "Treasurer",
  txt_nguoi_lap_phieu: "Voter",
  txt_nguoi_nhan: "Receivers",
  txt_phong_tai_chinh_ke_toan: "Financial Accounting room",
  phieu_co_gia_tri_trong_ngay: "Ticket is valid for today only",
  phieu_thu_phi_dich_vu: "Service fee ticket",
  lien_0_dau: "Inter",
  thanh_tien: "Amount",
  tong_cong: "Total",
  phieu_tom_tat_kham: "Examination summary sheet",
  loi_nhap_form: "Error typing",
  status_Da_hoan_thanh: "Completed",
  tip_xoa_ban_ghi: "Delete record",
  duoc_sua_nha_cung_cap: "Edit supplier",

  text_khoi_tao_du_lieu: "System is initializing data. Please wait for a few seconds.",
  tip_xoa_nhieu: "Delete records",
  noti_dang_chuyen_doi_file: "Converting file...",
  noti_chuyen_doi_file_thanh_cong: "Convert file successfully",
  noti_chuyen_doi_file_that_bai: "Convert file failed",
  noti_dinh_dang_file_word: "Please update doc, docx file",
  noti_vui_long_nhap_mo_ta: "Please enter description",
  thuoc_thuong: "Normal drug",
  thuoc_nha_thuoc: "Drugstore drug",
  prefix_so_luong_thuoc_trong_kho_hien_khong_du: "Quantity of drug at",
  suffix_so_luong_thuoc_trong_kho_hien_khong_du: "is not enough. Do you want to change?",

  sl_chuyen_kho: "Number of warehouse transfer",
  noti_so_luong_duyet_khong_lon_hon_ton_kha_dung: "The number of browsing is not greater than the availableness!",

  xuat_ban_dau_sac: "Export to sell",
  loai_phieu_dieu_chinh: "Ticket editing",
  duoc_them_moi_nhom: "Add new group",
  tip_sua_ban_ghi: "Edit records",
  tao_moi_mau_mo_ta: "Add new template",
  noti_chua_nhap_mo_ta: "Please enter description",
  noti_chua_nhap_ket_luan: "Please enter conclusion",
  error_khoa_da_duoc_chon: "This speciality is selected!",
  prefix_xoa_lich: "Delete calendar",
  noti_chua_hoan_thanh_dich_vu: "Haven't completed the service, do you want to leave?",
  file_hdsd: "File instruction",
  cai_dat: "Setting",
  chi_tiet_cuoc_kham: "Exam detail",
  tim_kiem_lich_lam_viec: "Search schedule",

  /// fix language Exel
  common_benh_vien: "Hospital",
  common_bo_y_te: "Ministry of Health (Department of Health)",
  common_tt: "Number",
  common_ngay_thang_nam: "Date...month...year",
  common_tu_ngay: "From date",
  common_den: "to",
  ten_thuoc_hoa_chat_vat_tu_y_te_tieu_hao: "Names of drugs, chemicals, medical consumables",
  ham_luong_nong_do_quy_cach_dong_goi: "Content, concentration, packing specifications",
  ky_ghi_ro_ho_ten: "(Sign and write full name)",
  nguoi_tao_phieu: "Voting creator",
  tm_cong_ty: "On behalf of the company",
  chi_tiet_cong_no: "Details liabilities",
  so_kiem_soat: "Control number",
  bien_ban_kiem_nhap_thuoc: "Drug import record",
  bs_chi_dinh: "Doctor appointed",
  phong_tai_chinh_ke_toan: "FINANCIAL ACCOUNTING",
  mau_bao_cao_ve_khang_sinh: "Antibiotic report form",
  so_giuong_benh_ke_hoach_thuc_ke: "Planned/actual number of hospital beds",
  cong_suat_su_dung_giuong_benh: "Bed usage capacity:...(%)",
  tt_hoat_chat: "Active ingredient information",
  tt_biet_duoc: "Brand name drug information",
  nong_do_ham_luong: "Concentration/Concentration",
  ma_atc: "Code ATC",
  ten_biet_duoc: "Brand name",
  don_vi_don_goi: "Packing unit",
  duong_dung: "Usage route",
  bao_cao_su_dung_thuoc: "Drug use report",
  thoi_gian_kiem_ke_thuoc_tinh_den: "Drug inventory time up to",
  lich_su_nhap_tra_ncc: "Payment import history",
  nguoi_thu_tien: "Collecter",
  no_cuoi_ky: "End-of-term debt",
  phat_sinh_trong_ky: "Born in the period",
  tong_hoa_don: "Total bill",
  txt_can_tra: "Need to pay",
  txt_thuc_tra: "Real pay",
  txt_ghi_no: "Debit",
  txt_ghi_co: "Credit",
  da_kiem_ke_tai: "Inventoryed at",
  common_gio: "Hours",
  ho_ten: "Name",
  noti_lay_danh_sach_that_bai: "Get list failure",
  khong_duoc_de_trong: "${label} cannot blank charaters",
  toi_da_ky_tu: "${label} maximum ${max} charaters",
  thong_tin_doi_tuong: "Object infomation",

  ten_nguoi_than: "Parent name",
  sdt_nguoi_than: "Parent phone number",
  quan_he_gia_dinh: "Family relationship",
  dia_chi_nguoi_than: "Address of relatives",
  //update
  ten_nguoi_than: "Relative's name",
  tong_chi_phi_KCB: "Total cost",
  bh_thanh_toan: "Payment insurance",
  benh_nhan_da_tt: "Paid",
  benh_nhan_chua_tt: "Unpaid",
  can_nang: "Weight",
  chieu_cao: "Hieght",

  tien_su_benh: "Medical history",
  tien_sua_benh_ban_than: "Personal medical history",
  benh_su: "Medical history",
  kham_benh: "Medical examination",
  tien_su_san_khoa: "Obstetric history",
  tien_su_benh_gia_dinh: "Family medical history",
  tien_su_di_ung: "History of allergies",
  trieu_chung_lam_sang: "Clinical symptoms",
  tai_nan_thuong_tich: "Injury",
  toan_than: "Body",
  cac_bo_phan: "Parts",
  tuan_hoan: "Cyclic",
  ho_hap: "Respiratory",
  tieu_hoa: "Digestion",
  than_tiet_nieu: "Urinary kidney",
  noi_tiet: "Endocrine",
  co_xuong_khop: "Musculoskeletal",
  than_kinh: "Nerve",
  bs_kham: "Doctor examines",
  chan_doan_so_bo: "Preliminary diagnosis",
  icd_benh_chinh: "ICD Main disease",
  icd_yhct_chinh: "ICD Traditional medicine",
  icd_benh_kem_theo: "ICD Including diseases",
  idc_yhct_kem: "ICD Accompanying traditional medicine",
  chan_doan_xac_dinh: "Implementing the quadrants",
  huong_xu_tri: "Treatment direction",
  ngay_tai_kham: "Re-examination date",
  loi_dan: "Advice",
  hoi_benh: "Ask the sick",
  kham_tong_quan: "General examination",
  chan_doan_xu_tri: "Diagnosis and treatment",
  confirm_huy_xuat_kho: "Are you sure to cancel the shipment?",
  confirm_huy_thanh_toan_don_thuoc: "Are you sure to cancel the payment?",
  confirm_huy_duyet_don_thuoc: "Are you sure to cancel your prescription?",
  confirm_ket_thuc_cuoc_kham: "Are you sure you want to end the exam?",
  ke_don: "Prescription",
  vui_long_nhap_chan_doan_so_bo: "Please enter preliminary diagnosis",
  nguon_khach: "Source of customers",
  mau_thong_tin: "Information form",

  //CÁCH DÙNG
  them_moi_cach_dung: "Added new usage",
  ten_cach_dung: "Usage name",
  vui_long_nhap_ten_cach_dung: "Please enter username",
  thong_tin_ck: "Information about the examination",
  don_thuoc_cu: "Old prescriptions",
  ket_qua_cls: "Subclinical results",
  xem_nhanh: "Quick view",
  xem_nhanh: "View detail",
  ngay_gio_y_lenh: "Order date and time",
  so_ngay_sd: "Number of days of use",
  huong_dan_su_dung: "User manual",
  xem_nhanh_lich_su_kham_benh: "Quick view of medical history",
  lich_su_kham_benh: "Medical examination history",
  tien_su: "Antecedent",
  new_so_ngay_sd: "Number of days of use",
  new_moi_ngay: "Every day use",
  tan_suat_sd: "Frequency of use",
  txt_ngay: "date",

  chan_doan_hinh_anh: "Image analysation",
  phieu_kham_benh: "Medical bill",
  don_thuoc_dich_vu: "Prescription",
  ma_benh_nhan: "Patient number",
  chan_doan_chinh: "Main diagnosis",
  chan_doan_phu: "Secondary diagnosis",
  ngay_dung: "Use date",
  chi_dan_dung_thuoc: "Medication instructions",
  loi_dan_bac_sy: "Doctor's advice",
  ten_bo_hoac_me_cua_tre: "Name of the child's father or mother or the person who brought the child for medical examination and treatment",
  kham_lai_xin_mang_theo_don: "For re-examination, please bring this form",
  title_phieu_chi_dinh_cho_bn_ngoai_bh: "INDICATION FORM FOR OUT-INSURANCE PATIENTS",
  title_phieu_chi_dinh_dich_vu: "INDICATION FORM SERVICES",
  title_phieu_bao_cao_y_te: "Medical reporter",
  khoa_phong: "Departments",
  tong_tien_nguoi_benh_phai_tra: "Total amount paid by the patient",
  da_duoc_tu_van_day_du:
    "Have been fully consulted and agreed to perform services at the clinic at the price listed in the medical service price list.",
  da_duoc_tu_van_day_du_v2: 'Have been fully consulted and agrees to perform services at the clinic at the prices listed in the medical service price list.',
  error_loi_ket_noi: "Connection errors . Please log in again",
  cho_thuc_hien: "Waiting",
  Dang_thuc_hien: "Processing",

  ban_than: "Self",
  gia_dinh: "Family",

  vui_long_chon_thuoc: "Please choose drug",
  don_thuoc_mau: "Sample prescription",
  vui_long_chon_kho: "Please select storage",
  ma_don: "Sample prescription code",
  ten_don: "Sample prescription name",
  danh_sach_thuoc_chi_dinh_trong_don: "Drug list",
  in_don_tu_van: "Print advice pres",

  phong_tiep_don: "Reception room",
  noti_chua_luu_thay_doi_don_thuoc_khi_bam_in: "You have not saved the prescription yet. Please save before printing.",
  noti_vui_long_nhap: "Please enter",
  phieu_tu_van: "Counseling prescription",
  noti_don_thuoc_khong_co_thuoc_byt: "No drugs of ministry of health founded in prescription",
  noti_don_thuoc_khong_co_thuoc_tpcn: "No dietary supplement founded in prescription",
  noti_vui_long_nhap_day_du_cac_truong_bat_buoc: "Please enter required fields",
  noti_vui_long_nhap_day_du_cac_truong_bat_buoc_cua_thuoc_trong_don: "Please fulfill required information of drugs in prescription",
  noti_vui_long_nhap_dung_dinh_dang: "Please enter correct format",
  nhap_gia_tri_lon_hon: "Enter value bigger than",
  va_nho_hon_hoac_bang: "and equal or smaller than",
  chua_co_lich_su: "No history",
  chua_chon_benh_nhan_vao_kham: "No patient",

  //popup thông tin khám
  ttk_tieu_de: "List of templates",
  ttk_tao_moi: "Add new model",
  nhap_ten_mau: "Model name",
  luu_mau: "Save Template",
  sua_mau: "Edit Template",
  msg_ten_mau: "Please enter a template name",
  msg_xoa_mau_thanh_cong: "Delete sample information successfully",
  tooltip_sua_mau: "Edit examination information form",
  txt_xac_nhan_xoa_mau: "Are you sure you want to delete this template?",
  msg_luu_mau_thanh_cong: "Sample saved successfully!",
  msg_sua_mau_thanh_cong: "Modify successful!",

  ket_qua_xet_nghiem: "Test results",
  thong_tin_ket_qua_xn: "Information about test results",
  file_dinh_kem: "File ",
  ten_xet_nghiem: "Test name",
  gia_tri_tham_chieu: "Reference value",
  ngay_nhan_kq: "Date result",
  thiet_bi: "Device",
  so_chay: "Number run",
  ngay_chay: "Date run",
  ngay_duyet: "Date confirm",
  chi_dinh: "Point",
  thong_tin_file_dinh_kem: "Attached file information",
  khoang_tham_chieu: "KReference range",
  nguoi_lay_mau: "Sampler",
  thoi_gian_lay_mau: "Sampling time",
  ket_qua_nam_lech: "Results in the range of",
  ket_qua_nam_giua: "Result in the center",
  ket_qua_in_dam: "Result in bold",
  trai: "the left",
  phai: "the right",
  label_ngay_tiep_don: "Reception date",
  khong_dung_dinh_dang: "is not match format",

  ten_hinh_thuc_thanh_toan: "Name payment method",
  cate_hinh_thuc_thanh_toan: "Payment method",

  answer_co: "Yes",
  answer_khong: "No",
  khai_thac_benh_su: "History extraction",
  thong_tin_benh_nhan: "Patient info",
  question_1: "Are you allergic to foods, drugs or substances?",
  question_2: "Have you been hospitalized or had surgery before?",
  question_3: "Have you ever had a blood transfusion?",
  question_4: "Are you taking any medications or treatments?",
  question_family: "Has anyone in the family had previous illnesses?",
  question_info_1: "From what sources do you know our clinic?",
  question_sinh_con: "Have you ever given birth?",
  question_nhap_vien: "Have you ever been hospitalized or treated for an injury?",
  nguyen_vong_khac: "Other wishes",
  lich_kham_chua_hoan_thanh: "The patient has an incomplete day of examination",
  huyet_ap_cao: "High Blood Pressure",
  tieu_duong: "Diabetes",
  tai_bien_nao: "Brain stroke",
  benh_tim_mach: "Heart-related diseaes",
  benh_than: "Kidney disease",
  benh_kawasaki: "Kawasaki disease",
  sot_xuat_huyet: "Dengue",
  benh_lao: "Labor",
  viem_da_di_ung: "Allergic dermatitis",
  other: "Other",
  ung_thu: "Cancer",
  dau_nua_dau: "Migraine",
  bao_chi: "Newspapers",
  cong_ty_ban_be: "Company/Friend",
  save_image: "Save image",
  error_khong_co_dich_vu: "Sevice empty",
  action_dung: "Stop",
  chup_tu_dong: "Auto",
  so_luong_anh_toi_da: "Total image max 6",
  thoi_gian_dem_lui: "Countdown time",
  tiep_tuc_tai_anh_len: "Continue",
  chinh_sua_anh: "Edit image",
  chon_anh: "Select image",
  tai_len_hinh_anh: "Upload image",

  ma_loai_dich_vu: "Service type code",
  nhom_loai_dich_vu: "Service type group",
  truong_khoa_chan_doan_hinh_anh: "Department of diagnostic imaging",
  thong_ke_hoat_dong_chan_doan_tham_do_chuc_nang: "Functional diagnostic activity statistics",
  thoi_gian_chi_dinh: "Specify time",
  ngay_thanh_toan: "Date of Payment",
  ma_kh: "Client code",
  bao_cao_doanh_thu_kh: "Sale report by customer",
  bao_cao_phieu_thu_dich_vu: "Service receipt report",
  phieu_dich_vu: "Service Voucher",

  CIS_ql_pk: "CIS Quản lý phòng khám",
  LIS_ql_xn: "LIS - Quản lý xét nghiệm",
  RIS_ql_cdha: "RIS - Quản lý CĐHA",
  PRM_ql_cskh: "PRM - Quản lý CSKH",
  home_vtc: "Home - Về trang chủ",
  noti_khong_the_xoa_cuoc_kham_co_dich_vu_da_thanh_toan: "Can't executed examination, one of service had pay",
  khuyen_nghi: "Recommendations",
  cap_nhat_phan_mem: "Migrate to new version",
  content_update_version: "New version is already, please login to have a new feature experience",
  content_update_version_reload: "New version is already, please reload to have a new feature experience",
  thu_gon: "Collapse",
  mo_rong: "Expand",
  trang_thai_kham: "Medical examination status",
  trang_thai_thanh_toan: "Payment status",
  field_Dat_kham: "Booking",

  da_thanh_toan: "Had pay",
  can_tao_lich_lam_viec: "You need to create the schedule for this service",
  tong_kh_thanh_toan: "Total payment",
  tong_tien_hoan: "Total refund",

  da_xac_thuc: "Authenticated",
  chuyen_huong_toi_trang_chu: "Returning to homepage",
  quan_ly_tai_khoan: "Account managment",
  thong_tin_tai_khoan: "Account infomation",
  doi_mat_khau: "Change password",
  mat_khau_cu: "Old password",
  mat_khau_moi: "New password",
  nhap_lai_mat_khau_moi: "Confirm password",
  content_doi_mat_khau: "Please valid the password before change",
  vui_long_xac_nhapn_mat_khau: "Please confirm password first",
  xac_nhan_mat_khau_khong_dung: "Confirm password is not correct",

  common_noi_dung: "Content",
  title_khai_thac_thong_tin_kb: "Exploiting medical examination information",
  them_moi_thong_tin_khai_thac_kham_benh: "Add new information about medical examination and treatment",
  confirm_xoa_lich_su: "Are you sure you want to delete this history?",
  danh_sach_thuoc_chi_dinh_trong_don: "List of drugs indicated in the prescription",
  don_chi_dinh: "Application form",
  don_vang_lai_khach_le: "Current application, retail customer",
  ky_thuat: "Technology",

  lam_moi: "Reset",
  nguoi_chi_dinh: "Designator",
  trang_thai_ca_kham: "exam status",
  phieu_hoan: "Refund receipt",
  phieu_thu: "Receipt",

  lua_chon_phan_he: "Select working module",
  home_intro:
    "Smart solutions to manage clinics, chains of specialized and polyclinic clinics effectively, conveniently, and optimally, anytime, anywhere",
  bao_cao_thong_ke: "Report, statistic",
  sub_title_danh_muc: "System administration, employ- ee, category configuration",
  sub_title_cis: "Management of medical exami- nation and treatment activities",
  sub_title_duoc_ngoai_tru: "Manage pharmaceutical supplies, cabinets",
  sub_title_lis: "Management of laboratories and testing centers",
  sub_title_ris: "Management of diagnostic imaging center",
  sub_title_prm: "Manage customer care, increase sales",
  sub_title_bao_cao: "Manage reports, statistics of all medical activities",
  sub_title_pacs: "Image storage and transmission system",
  dia_chi_deepcare: "No. 35 Le Van Thiem, Thanh Xuan Trung Ward, Thanh Xuan District, Hanoi",

  luu_y_mat_khau_mac_dinh: "The account password currently in use is the temporary password, please change it to a new one",
  noti_mat_khau_ko_ok: "Please enter a qualified password !",
  dk_do_dai_mat_khau: "Password must be between 8-24 characters",
  dk_mat_khau_bao_gom: "Include number, uppercase letter, lowercase letter",
  dk_mat_khau_ky_tu: "Include at least one special character !@#$^*()_",
  cs_dat_kham: "Medical booking facility",
  tong: "Total",
  noti_chuyen_cskcb: "Data will be lost if change clinic. Do you still want to continue?",
  cisLabel: "Medical management",
  home: "Home",

  quan_ly_tam_ung: 'Advance management',
  quan_ly_cong_no: 'Debt management',
  quan_ly_goi_dv: 'Service, course bundle management',
  danh_sach_goi_dv: 'Service, course bundle list',
  quan_ly_dk_goi_dv: 'Service, course bundle subscription management',
  quan_ly_su_dung_goi_dv: 'Service bundle usage management',
  kham_dich_vu: "Service examination",
  goi_dich_vu: "Service package",
  chi_dinh_goi_dich_vu: "Specify a service pack",
  dk_goi_dv: "Subscribe service bundle",
  thong_tin_sd_goi_dv: "Service bundle usage information",
  dang_ky_goi_dv: "Sign up for a service pack",

  loai_goi: "Service type",
  ma_voucher: "Code voucher",
  tt_goi_dich_vu: "Service pack information",
  lich_su_su_dung: "Usage history",
  lich_su_thanh_toan: "Payment history",
  gia_goi_dich_vu: "Service package price",
  CCCD_Passport: "Identification/Passport",
  loai_gia: "Price Type",
  don_gia_phan_bo: "Allocation unit price",
  thanh_tien_phan_bo: "Into allotted money",
  tong_sl: "Total",
  tong_tien_phan_bo: "Total amount allotted",
  kich_hoat_goi_dv: "Activate service, treatment pack",
  ngay_hieu_luc: "Effective date",

  chua_hoat_dong: "Not active yet",
  hoat_dong: "Active",
  tam_ngung: "Pending",
  sap_het_han: "Expired soon",
  het_han: "Expired",

  them_moi_goi_dv: "Create packet type",
  chinh_sua_goi_dv: "Edit packet type",

  ma_goi: "Packet code",
  ten_goi: "Packet name",
  ngay_tao: "Created date",
  gia_ban_goi: "Packet price",
  ngay_het_han: "Expired date",
  so_ngay_su_dung: "Used date",
  thao_tac: "Operation",

  thong_tin_goi_dv: "Service packet information",
  ma_goi_dv: "Packet service code",
  ten_goi_dv: "Packet service name",
  loai_goi_dv: "Packet service type",
  chi_dinh_goi_dv: "Specify service packet",
  goi_co_san: "Avaiable packet",
  sao_chep: "Copy",
  ty_le: "Ratio",
  nguoi_cap_nhat: "Updater",
  ngay_cap_nhat: "Update day",
  thue_VAT: "VAT Tax",
  goi_dv: "Service bundle",
  ngay_kich_hoat: "Active date",
  ghi_chu: "Note",
  dinh_muc: "Quota",
  da_su_dung: "Used",
  con_lai: "Remaining",
  so_du_cong_no: "Surplus/Debt",
  ngay_giao_dich: "Transaction date",
  dich_vu_lieu_trinh_ngay: "Therapy servoce day",
  xem_ket_qua: "View result",

  ly_do_tam_ung: "Reason for advance",
  so_tien_tam_ung: "The advance",
  nguoi_nop_tien: "Payer",
  thoi_gian_tao_phieu: "Voting creation time",
  ma_phieu_tam_ung: "Advance coupon code",
  thong_tin_phieu_tam_ung: "Advance ticket information",
  phieu_thu_tam_ung: "advance receipt",
  noti_tam_ngung_sd_goi_dv: 'Customers will not be able to continue using the service pack. Are you sure you want to lock the service pack?',
  noti_hoan_thanh_sd_goi_dv: 'Customers will not be able to continue using the service pack. Are you sure you want to complete the service package before the due date?',
  tao_moi_that_bai: "new creation failed !",
  bn_da_dang_ky_goi_dv_nay: 'Patient has subscribed to this service pack',
  xac_nhan_xoa_dang_ky_goi_dv: 'Customers will not be able to continue using the service pack. Are you sure you want to delete the service pack?',
  ngay_kt_lon_hon_ngay_het_han: 'The service package is terminated after the expiration date of the package',
  dk_goi_thanh_cong: 'Package registration successful.',
  xac_nhan_khoa_dang_ky_goi_dv: 'Customers will not be able to continue using the service pack. Are you sure you want to deactivate the service pack?',
  lay_thong_tin_that_bai: 'Retrieve failure information !',
  dung_goi: 'Stop service',
  hoan_tat_goi: 'Complete package',

  warning_over_size: "The total file size exceeds 5M, please reduce the size to be able to save the file.",
  noti_khoa_goi_dich_vu: 'Customers will not be able to continue using the service pack. Are you sure you want to lock the service pack?',
  noti_tam_ngung_goi_dich_vu: 'A locked service package cannot be registered for new customers. Customers who have already registered for the package can still continue to use the package. Are you sure you want to lock the service package?',
  noti_xoa_goi_dich_vu: 'The service pack after deletion cannot be restored, are you sure you want to delete the service pack?',
  noti_xoa_mien_giam_goi: 'The exemption after deletion cannot be restored, are you sure you want to delete this exemption?',
  kham_doan: "Examine group",
  lich_su_kham: 'Medical history',
  chi_tiet_ca_kham: 'Detail medical examination',
  thong_tin_ca_kham: 'Medical examination info',
  chua_co_ket_qua: 'Result not avaiable yet',

  phieu_huy: "Cancel Receipt",
  phieu_tam_ung: "Advance Receipt",
  phieu_hoan_ung: "Refund Receipt",
  ma_ca_kham: 'Examination code',
  quan_ly_tam_hoan_ung: "Advance and refund management",
  ly_do_tam_hoan_ung: "Reason for advance/ refund",
  ngay_tao_phieu: "Ticket creation date",
  tong_tien_tam_ung: "Total advance money",
  so_tien_hoan_ung: "Refund amount",
  hinh_thuc_chi: "Payment method",
  ly_do_hoan_ung: "Reason for refund",
  thong_tin_hoan_ung: "Refund receipt information",
  phieu_hoan_tam_ung: "Refund advance money receipt",
  phieu_thu_goi_dv: "Service bundle receipt",
  phieu_hoan_goi_dv: "Service bundle refund receipt",
  phieu_tam_dat_coc: "Advance/ deposit receipt",
  ho_ten_ng_nop_tien: "Full name of the payer",
  so_tien_da_tam_ung: "Advance amount",
  hinh_thuc_chi_hoan_ung: "Refund payment method",
  phieu_chi_hoan_ung: "Refund advance receipt",
  cap: "Provide",
  nhap_dung_dinh_dang_so: "Enter the correct number format",
  ho_va_ten_khach_hang: 'Customer name',
  thanh_toan: 'Paid',
  length_phone: 'Invalid length',
  dich_vu_da_su_dung: "Service used",
  settlement: 'Settlement',
  gioi_tinh_nam: "Male",
  gioi_tinh_nu: "Female",
  gioi_tinh_khac: 'Other',
  gioi_tinh: "Gender",
  noti_thao_tac_that_bai: 'Task failed',
  btn_mau_chi_dinh_dv: "Service order form",
  khach_hang_dua: "Given by the customer",

  nhom_doi_tuong_nguoi_dung: 'User object group',
  so_tien_khach_hang_dua: "Money given by customer",
  mental: 'Mental',
  left_eye: 'Left eye',
  right_eye: 'Right eye',
  eyesight_without_glasses: "Eyesight without glasses",
  eyesight_with_glasses: "Eyesight with glasses",

  left_ear: 'Left ear',
  right_ear: 'Right ear',
  speak_normally: 'Speak normally',
  speak_whisper: 'Speak whisper',
  damaged_teeth: 'Damaged teeth',
  tooth_diagram: 'Tooth diagram',
  child_jaw: 'Child jaw',
  adult_jaw: 'Adult jaw',
  upper_jaw: 'Upper jaw',
  lower_jaw: 'Lower jaw',
  error_maxillary_mandibular: "Please enter maxillary/mandibular diagnosis",

  nguoi_duyet: "Approver",
  thoi_gian_duyet: "Browsing time",
  nguoi_nhap: "Importer",
  tim_file: "File",
  in_ket_qua: "Print the results",

  them_httt: 'Add payment method',
  xoa_tat_ca: 'Delete all',
  danh_sach_hinh_anh: 'Images list',
  link_hinh_anh: 'Image link',
  su_dung_anh: 'Use images',
  chinh_sua_hinh_anh: 'Edit images',
  text_quet_qr: 'Please scan the QR code to see detailed images',
  link_ket_qua: 'Result link',
  quan_ly_mien_giam: 'Exemption management',
  them_nhom_doi_tuong_nguoi_dung: 'Add user groups',
  them_co_che_mien_giam: 'Add exemption mechanism',
  hen_tai_kham: 'Make a follow-up appointment',
  title_mau_chi_dinh_dich_vu: "Service order model",
  danh_sach_chi_dinh_dich_vu: "List of service orders",
  da_sao_chep: "Copied",
  do_thi_luc: "Measuring visual acuity",
  thi_luc: "eye sight",
  khong_kinh: "no glass",
  co_kinh: "have glasses",
  hearing_test_results: "Hearing test results",
  thinh_luc: 'Force',


  luu_mau_chi_dinh_dich_vu: "Save the service order form",
  so_tam_ung: 'Advance book',
  so_cong_no: 'Debt book',
  noti_xoa_so: 'Are you sure you want to delete this book?',
  tien_mat_dau_ca: 'Starting cash',
  tien_mat_cuoi_ca: 'Ending cash',
  quyet_toan: 'Settlement',
  noti_quyet_toan: 'Are you sure you want to settle the shift?',
  hien_thi: 'Show',
  on_one_page: 'On 1 page',
  truoc: 'Prev',
  tiep: 'Next',
  chon_tat_ca: "Select all",
  them_goi_dich_vu: 'Add service package',
  dang_ky: 'Subscription',
  tong_SL: 'Total quantity',
  ma_dich_vu: 'Exam code',
  tra_sau: 'Postpaid',
  so_tien_da_thanh_toan: 'Paid money',
  QUET_TOAN_LOI: 'No cases during the day!',
  NO_BILL_IN_SHIFT: 'Ca without invoice!',
  thanh_toan_tam_ung: 'Refund payment',
  chi_dinh_dich_vu: "Service orders",
  trang_thai_da_cap_nhat: "Status is Updated",
  huy_thuc_hien_full: "Cancel execution",
  danh_sach_dich_vu: "Service list",
  dich_vu_chi_dinh: "Designated service",
  tong_can_thanh_toan: 'Total payment required',
  bo_phan_tiep_don: 'Reception department',
  title_modal_finish_examination: 'There are old examinations that have not been completed. Do you want to end the old examinations?',
  tao_phieu_chi_dinh_dich_vu: 'Create service orders',
  chi_tiet_phieu_chi_dinh_dich_vu: 'Service order details',
  tien_khach_dua: 'Customer paid money',
  so_tien_thanh_toan: "Payment amount",
  luu_cong_no: 'Save debt',
  vui_long_lua_chon_so_luu_cong_no: 'Please select the debt record book',
  ly_do_huy_phieu: "Reason for cancellation",
  noti_nhap_ly_do_huy: 'Please enter reason for cancellation', note_print_xn: "Test results are only valid on the corresponding sample received at the Laboratory.",
  note_print_xn_2: "Values ​​in bold are values ​​outside the reference clause",
  technical_and_specialized_gynecology: "In charge of expertise",
  so_dien_thoai: "Phone number",
  phieu_ket_qua_xet_nghiem: "Test result form",
  TT_cho: "Queue order",

  tien_su_benh_khac: "Other medical history",
  phieu_ket_qua: "Results slip",
  phan_loai_bt: "Classification of diseases",
  ngay_sinh: "Birthday",
  implementer: 'Implementer',
  implementation_date: 'Implementation date',
  hang_bang_lx: "Driving license",
  kq_do_chi_so_sinh_ton: "Results of vital index measurement",
  thong_tin_ket_qua_do: "Measurement result information",
  specialist: "Specialist",
  tro_ly_y_khoa_ai: "AI medical assistant",
  tro_ly_ai: "AI assistant",
  vui_long_nhap_chan_doan_xac_dinh: "MedicationPlease enter a definitive diagnosis",

  ho_so_benh_an: "Medical record",
  noti_dong_bo_doi_tuong_kh: "Do you want to synchronize customer targets for all services in the examination?",
  sync: "Sync",
  ko_hien_thi_lai: "Do not show again",
  kham_goi_dich_vu: 'Check the service package',
  label_sd_thong_tin_kh_cu: 'Customer information has been created, do you want to use existing information?',
  trung_thong_tin: 'Client already exists',

  tool_tip_quan_tri: 'Categories and subsystems management',
  tool_tip_cis: 'Medical examination and treatment activities management',
  tool_tip_lis: 'Laboratory management',
  tool_tip_ris: 'Diagnostic imaging department management',
  tool_tip_prm: 'Customer care management',
  tool_tip_duoc: 'Outpatient pharmacy management',
  tool_tip_bao_cao: 'Reports and statistics management',
  warn_thong_tin_tham_khao_hoi_chuyen_gia: "Information is for reference only, please consult an expert",
  de_xuat_dich_vu: "Service recommendation",
  tool_tip_pacs: 'Manage image storage',
  dang_ky_goi_lieu_trinh: 'Register bundles, treatments',
  danh_sach_chuyen_khoa: "List of specialties",
  tiep_don_moi: "New appointment",
  tao_phieu_chi_dinh_goi_dich_vu: "Create a ticket to specify a service package",
  thuc_hien_can_lam_sang: "Performing Clinical Examination",
  huy_tao: "Cancel creation",
  tiep_don_moi: 'New reception',
  thuc_hien_dv_cls: 'Perform paraclinical services',
  kham_goi_lieu_trinh: 'Examination package, treatment process',
  ten_goi_lieu_trinh: 'Bundle, course of treatment name',
  nhom_goi: 'Bundle group',
  goi_lieu_trinh: 'Treatment bundle',
  danh_sach_dich_vu_duoc_chi_dinh: 'List of specified services',
  goi_dv_lieu_trinh: 'Service, treatment bundle',

  // Đánh giá tình trạng buồn ngủ,

  danh_gia_tt_buon_ngu: "Assessing sleepiness",

  ngoi_va_doc: "Sit and read",
  ngoi_yen_cong_cong: "Sit still in public places",
  nam_nghi_buoi_chieu: "Lie down and rest in the afternoon",
  ngoi_im_lang_sau_an_chua: "Sit quietly after lunch (don't drink alcohol at lunch)",
  watch_truyen_hinh: "Watching TV",
  ngoi_im_mot_tieng: "Sit for an hour as a passenger in a car",
  ngoi_va_noi_chuyen: "Sit and talk with another person",
  ngoi_trong_xe_oto: "Sitting in the car, stopping the car for a few minutes due to traffic",

  danh_gia_tt_buon_ngu_qs1: "Inability to fall asleep (0 points)",
  danh_gia_tt_buon_ngu_qs2: "Slightly likely to fall asleep (1 point)",
  danh_gia_tt_buon_ngu_qs3: "Moderately likely to fall asleep (2 points)",
  danh_gia_tt_buon_ngu_qs4: "High possibility of falling asleep (3 points)",

  mau_kham_benh: "Medical examination form",
  kham_noi_tq: "General internal examination",
  kham_noi_giac_ngu: "Internal examination - sleep",

  kham_hong: "Throat examination",
  diem_mallampati: "Mallampati Point",
  diem_epworth: "Epworth Point",
  danh_gia_tt_lq_giac_ngu: "Assessing conditions related to sleepiness",
  point_stop_bang: "STOP-BANG point",
  danh_gia_stop_bang: "Risk assessment STOP-BANG",
  other_parts: "Other parts",

  mallampati_1: "Grade 1: Nucleus, uvula and soft palate are completely visible.",
  mallampati_2: "Layer 2: Hard and soft palate, upper part of tonsils and nostrils visible.",
  mallampati_3: "Grade 3: Soft and hard palate and base of uvula visible.",
  mallampati_4: "Grade 4: Only hard palate visible.",

  epworth_1_6: "score: Normal sleep",
  epworth_7_8: "score: Moderate drowsiness",
  epworth_9_24: "score: Abnormal sleepiness (likely pathological)",

  stop_bang_high: "high risk of OSA.",
  stop_bang_thap: "low risk of OSA.",
  stop_bang_min3: "≥ 3 or 4 signs = ",
  stop_bang_max3: "< 3 signs = ",

  thang_diem_stop_bang: "STOP-BANG risk score",
  stop_bang_ngay_to: "Snoring loudly (louder than talking or loud enough to be heard through the door)",
  stop_bang_thuong_met: "Often tired or sleepy during the day",
  stop_bang_observed: "Apnea observed during sleep",
  stop_bang_huyet_ap: "High blood pressure or being treated for high blood pressure",
  stop_bang_bmi: "BMI > 35 kg/m2",
  stop_bang_age: "Age > 50 years old",
  stop_bang_circumference: "Neck circumference > 40 cm (> 15 3/4 inches)",
  stop_bang_gt_nam: "Male gender",

  so_diem_tc: "Total standard score",
  trieu_chung_thuc_the: "Physical symptoms",
  thang_diem: "Score scale",
  bmi: "BMI (kg/m2)",
  vong_co: "Necklace (cm)",
  vong_bung: "Belly circumference (cm)",
  note_up_file_that_bai: "Upload File failed!",
  khach_moi: "New customer",
  ph_tram_thang_du: "Overvalue percent",
  tong_tien_kh_dua: "Total money customer give",

  su_dung: "use",

  duoc_tbyt: "Pharmacy, medical equipment",
  sub_title_duoc_tbyt: "Management of drug sales and medical equipment",
  ke_don_thuoc_tbyt: "Prescribe drugs, medical equipment",
  bac_si_chuyen_khoa: "Specialists",
  dich_vu_da_thanh_toan: "Paid service",
  warn_khach_hang_co_dich_vu_da_duoc_chi_dinh: "Customer whose service has been assigned",
  warn_ban_co_muon_tiep_tuc_chi_dinh_dich_vu: "Do you want to continue specifying the service",
  danh_sach_lich_kham_chua_tt: "List of unpaid medical appointments",
  noti_loi_tai_trang: "Sorry, An error occurred while loading the page, please try again",

  exemption_mechanism: 'Exemption mechanism',
  dky_lieu_trinh: "Register for treatment",
  lieu_trinh_de_xuat: "Proposed treatment",
  qly_su_dung: "Usage management",
  ke_hoach_sd: "Usage plan",
  qly_goi_lieu_trinh: "Service packages and treatments management",
  so_buoi_sd: "Used time",
  dv_sd_trong_goi: "Packaged services",
  thong_tin_thuoc_vat_tu_sd: "Information on drugs and supplies used",
  dv_chua_dung: "Unused service",
  chi_dinh_thuoc_vat_tu_trong_goi: "Specify medicine and supplies in the package",
  thuoc_vat_tu_chua_dung: "Unused medical supplies",
  thong_tin_goi_lieu_trinh: "Treatment package information",
  cach_thuc_thu_thuat: "Surgical procedure",
  phuong_phap_thu_thuat: "Surgical method",
  loai_thu_thuat: "Surgical type",
  duoc_vat_tu_sd: "Materials used",
  them_vat_tu_ngoai_goi: "Add supplies outside the package",
  tinh_trang_truoc_th: "preoperative condition",
  tinh_trang_khi_th: "Condition during surgery",
  tinh_trang_sau_th: "Condition after surgery",
  thanh_phan_thuc_hien: "Participants",
  nv_thuc_hien_chinh: "Main implementation staff",
  nv_ho_tro: "Support staff",

  thong_tin_kham_dv: "Service examination information",
  so_tien_no_cua_kh: "Amount owed by the customer",
  da_vuot_han_muc_no: "has exceeded the specified limit of the service package being used.",
  chac_chan_muon_tiep_tuc_chi_dinh_dv: "Are you sure you want to continue assigning services to this customer?",
  chua_tao_phieu_thu_muon_tao_ko: "You haven't created a receipt yet, do you want to create a service receipt?",
  vui_long_chon_so_luu_phieu_thu: "Please select the receipt book",
  tao_phieu_thu: "Create receipt",
  huy_phieu_thu: "Cancel receipt",
  huy_phieu_thu_da_tao: "Cancel the created receipt",
  tien_trinh_sd_goi: "Process of using the package",
  vat_tu_tieu_hao_tu_truc: "Consumables, on-call cabinets",
  sub_title_vt: "Manage supplies, consumables, on-call cabinets, and emergency cabinets",
  sub_title_kd: "Managing corporate health checks",
  vat_tu_tu_truc: "Supplies,\non-duty cabinet",
  khamdoan: "Group examination",
  sd_tien_tam_ung: "Use advance money",
  tong_tam_ung: "Total advance",
  tien_hoan_ung_ko_the_lon_hon_tien_tam_ung: "The refund amount cannot be greater than the advance amount!",
  in_phieu_cong_no: "Print debt slips",
  phieu_thu_cong_no: "Debt collection slip",
  tu_van_goi_lieu_trinh: "Consulting on treatment packages",
  thoi_han_sd_goi: "Package expiration date",
  ngay_hieu_luc_ban_goi: "Effective date of package sale",
  ngay_het_han_ban_goi: "Package expiration date",
  so_ngay_sd_goi_tinh_tu_ngay_kich_hoat: "The number of days to use the package is calculated from the date of package activation",
  so_lan_su_dung_dich_vu: "Number of service uses",
  goi_dung_1_lan: "Disposable package",
  goi_dung_nhieu_ngay: "Package for many days",
  lieu_trinh_duoc_de_xuat: "Recommended course of treatment",
  nguoi_de_xuat: "Proposer",
  chi_dinh_dv_trong_goi: "Specify the service in the package",
  bac_si_de_xuat: "Doctor suggested",
  ngay_dat_hen: "Appointment date",
  gio_dat_hen: "Appointment hour",
  nhan_ban: "Replication",
  nhan_ban_ke_hoach: "Duplicate the plan",
  vui_long_nhap_sl_nhan_ban: "Please enter the number of days you want to clone",
  sl_nhan_ban: "Number of clones",
  tt_thuoc_vat_tu_trong_goi: "Information on drugs and supplies in the package",
  chi_dinh_thuoc_vat_tu_ngoai_goi: "Specify drugs and supplies outside the package",
  kho_duoc_vat_tu: "Drugs, supplies storage",
  ma_thuoc_vt: "Med, supply code",
  ten_thuoc_vtyt_hoa_chat: "Drug, med equipment/chemical name",
  tt_thu_thuat: "Tips information",
  sd_mau: "Use templates",
  luu_thanh_mau: "Save as template",
  ma_mau: "Template code",
  ten_mau: "Template name",
  hinh_anh_thu_thuat: "Trick images",
  tt_vien_chinh: "main informant",
  phu: "Sub",
  dung_cu_vien: "Pellet tools",
  gm_chinh: "Chief anesthesiologist",
  gm_phu: "Assistant anesthesiologist",
  giup_viec: "Domestic worker",
  vui_long_thay_doi_tt_truoc_luu: "Please change the information before saving",
  gui_ma_thanh_cong_vui_long_kiem_tra_email: "Code sent successfully, please check your email",
  xac_thuc_thanh_cong_vui_long_nhap_mk: "Authorization success. Please, enter your new password",
  vui_long_chon_httt: "Please, select your payment type",
  dang_duoc_sd: "In used",
  chua_duoc_nhap: "Not entered yet",
  vui_long_chon_lo_thuoc_khac: "Please choose another batch of medicine",
  da_het_hang_trong_kho: "Out of stock",
  da_duoc_them_vao_phieu: "Added to ticket",
  vui_long_chon_thuoc_khac: "Please, choose another medicine",
  vui_long_them_thuoc: "Please add medicine",
  vui_long_nhap_lo_thuoc_so_luong: "Please select and enter complete information about the batch and quantity of the medicine",
  thuoc_da_tam_dung_nhap: "Drug import has been temporarily stopped",
  ko_them_tu_dong_duoc_thuoc: "Medication cannot be added automatically",
  loai_don_thuoc: "Prescription types",
  lien_thong_don_thuoc: "Communicate prescriptions",
  sl_ke_don: "Prescription quantity",
  sl_ban: 'Sell quantity',
  bo_loc_nang_cao: "Advance filter",
  don_chua_ban: "Sold",
  don_da_ban: "Unsold",
  trang_thai_ban_don_thuoc: "Prescription sold status",
  chua_lien_thong: "Disconnect",
  da_lien_thong: "Connected",
  noti_chuyen_toi_phan_he_de_chinh_sua: "Edit from module",
  thoi_gian_thuc_hien: "Time",
  menu_phauthuat_thuthuat: "Surgical procedures",
  tip_chinh_sua_cot: "Config columns",
  tip_tai_xuong: "Download",
  nghi_ngo_u_phoi: "Suspected lung tumor",
  nghi_ngo_lao_phoi: "Suspected pulmonary tuberculosis",
  benh_khac: "Other diseases",
  benh_ve_gan: "Liver disease",
  benh_ve_he_tiet_nieu: "Urinary system disorder",
  benh_ve_tuyen_tuy: "Pancreatic disease",
  benh_ve_lach: "Gallbladder disease",
  benh_ve_tuyen_tien_liet: "Prostate gland disorder",
  benh_ve_bo_phan_tu_cung: "Uterine disorder",
  benh_ve_da_day: "Stomach disorder",
  don_thuoc_chuan: "Standard prescription",
  don_thuoc_gay_nghien: "Addictive prescription",
  don_thuoc_huong_than: "Psychotropic prescription",
  don_thuoc_y_hoc_co_truyen: "Traditional medicine prescription",
  loai_khach_hang: 'Customer types',
  ma_luot_kham: "visit code",
  benh_nhan_cu: 'Old patient',
  benh_nhan_moi: "New patient",
  title_xuat_ban: "Drug sales",
  title_xuat_tra: "supplier payment",
  title_xuat_huy: "Cancellation",
  tra_kq: "Return",
  huy_thuc_hien: "Cancel",
  noti_phien_dang_nhap_da_het_han: 'Login session has expired',
  noti_kho_thuoc_khong_duoc_phan_quyen: "noti_kho_thuoc_khong_duoc_phan_quyen",
  msg_hien_khong_kha_dung: "Is not avaliable now",
  msg_khong_co_mau_in: "No printing form !",
  common_mau: "Form",
  loading_he_thong_dang_xac_thuc: "System is authentication",
  dien_bien_cua_benh: "Disease progression",
  xem_truyen_hinh: "Watch TV",

  lien_thong: 'Interconnection',
  trang_thai_lien_thong: 'Connect status',
  du_dieu_kien: "Eligible",
  chua_du_dieu_kien: "Unqualified",
  trang_thai_mua: "Buying status",
  dieu_kien_lien_thong: "Interconnection conditions",
  don_thuoc_yhct: "Traditional medicine prescription",
  content_msg_chua_du_dieu_kien_lien_thong: "Prescriptions are not eligible for transfer, please update information before transfer",
  dong_bo_don_thuoc: "Synchronize prescriptions",

  da_co_hinh_anh: "has images",
  cau_hinh_ngon_ngu_that_bai: "Config language failure !",
  noti_truy_cap_bi_tu_choi: "access deny",
  bac_si_tu_van: "Consulting doctor",
  thuoc_dieu_tri_de_xuat: "Suggested medication",
  phieu_thong_tin_tu_van_cua_bac_si: "Doctor's consultation information form",
  thuoc_dieu_tri: "Treatment medicine",
  phac_do_dieu_tri: "Treatment regimen",
  lieu_trinh_dieu_tri: "Treatment course",
  phieu_thong_tin_tu_van_khach_hang: "Customer consultation information sheet",
  field_ten_phieu: "Ticket name",
  tao_phieu_xuat: "Create export ticket",
  family_bo: 'Father',
  family_me: 'Mother',
  family_vo: 'Wife',
  family_chong: 'Husband',
  family_con: 'Children',
  family_khac: 'Other',
  xoa_phong: "Delete room",
  sua_phong: "Edit room",
  xoa_khoa: "Delete department",
  sua_khoa: "Edit department",
  xoa_don_vi: "Delete unit",
  sua_don_vi: "Edit unit",
  sua_giuong: "Edit bed",
  xoa_giuong: "Delete bed",
  xoa_thu_thuat: "Delete surgery",
  sua_thu_thuat: "Edit surgery",
  xoa_nhom: "Delete group",
  sua_nhom: "Edit group",
  xoa_loai_dich_vu: "Delete service type",
  sua_loai_dich_vu: "Edit service type",
  xoa_dich_vu: "Delete service",
  sua_dich_vu: "edit service",
  sua_hang_san_xuat: "Edit manufacturer",
  xoa_hang_san_xuat: "Delete manufacturer",
  xoa_duong_dung: "Delete How-to-use of drug",
  sua_duong_dung: "Edit How-to-use of drug",
  xoa_hoat_chat: "Remove active ingredients",
  sua_hoat_chat: "Edit active ingredients",
  xoa_thuoc: "Delete medicine",
  sua_thuoc: "Edit medicine",
  xoa_kho_thuoc: "Delete drugstore",
  sua_kho_thuoc: "Edit drugstore",
  xoa_danh_muc_may: "Delete machine catalog",
  sua_danh_muc_may: "Edit machine catalog",
  xoa_xuat_khac: "Delete other export",
  sua_xuat_khac: "Edit other export",
  xoa_nguon: "Delete source",
  sua_nguon: "Edit source",
  xoa_phuong_phap: "Delete method",
  sua_phuong_phap: "Edit method",
  xoa_ly_do: "Delete reason",
  sua_ly_do: "Edit reason",
  noti_da_co_cuoc_hen_trong_ngay: "There is an appointment available for the selected date",
  noti_huy_phieu_thanh_cong: "noti_huy_phieu_thanh_cong",
  noti_huy_phieu_that_bai: "noti_huy_phieu_that_bai",
  title_huy_phieu_thu_dich_vu: "Cancel service receipt",
  label_ly_do_huy_phieu: "Cancel service receipt resason",
  tt_gui_PACS: 'PACS status',
  da_gui: "Sent",
  gui_loi: "Failure",
  chua_gui: "Unsent",
  gui_PACS: "Send PACS",
  btn_da_tra_kq: "Completed",
  status_Dang_kham_short: "Examining",
  tab_Hoan_thanh_short: "Completed",
  Cho_kham_short: "Waiting",
  tab_chi_dinh_can_lam_sang: "Paraclinical indications",
  quan_ly_vat_tu_hoa_chat: "Materials, chemicals management",
  phan_loai_benh_tat: "Disease classification",
  co_thai: "Pregnant",
  phieu_ke_hoach_dieu_tri: "Treatment plan form",
  ke_hoach_dieu_tri: "Treatment plan",
  thuoc_vat_tu_su_dung: "Medicines and supplies used",
  dieu_duong_truong: "Chief Nursing",
  noti_truy_cap: "Access",
  noti_de_kiem_tra_don_thuoc_cua_ban: "To check your prescription's",
  noti_vui_long_nhap_loai_don_thuoc: "Please, enter your prescription type",

  giay_chuyen_vien: "Clinic referral",
  kinh_gui: "Dear",
  pk_tran_trong_gt: "Our clinic is pleased to refer",
  da_dc_dieu_tri_kh_benh_tai: "Received treatment/examination at",
  tom_tat_benh_an: "Health record summary",
  dau_hieu_lam_sang: "Clinical signs",
  cac_xet_nghiem: "All tests",
  thuoc_da_dung: "Used medicines",
  tinh_trang_luc_chuyen_vien: "Patient's status while being transferred",
  ly_do_chuyen_vien: "Reason for transferring clicic",
  chuyen_hoi: "Transferred at",
  phuong_tien_van_chuyen: "Transportation",
  ho_ten_ng_dua_di: "Delivering person's full name",
  bac_si_dieu_tri: "Attending doctor",
  giam_doc_pk: "Clinic's director",
  thoi_gian_chuyen_vien: "Clinic transfer time",
  phieu_chuyen_vien: "Clinic referral",

  in_benh_an_ngoai_tru: "Print outpatient medical records",
  goi_de_xuat_khac: "Other proposed packages",
  goi_dich_vu_lieu_trinh_can_thanh_toan: "Service packages and treatments require payment",
  vui_long_chon_so_cong_no: "Please select debt book !",
  goi_bi_trung: "Duplicate package",
  vui_long_nhap_so_tien_khach_hang_co_the_tai_su_dung: "Please enter the amount the customer can reuse",
  ban_co_chac_chan_muon_tat_toan_goi: "Are you want to settle this packgage",
  tuy_chinh_cot: "Config columns",
  httt_bi_trung: "Dupilicate payment method",
  no_cu: "Old debt",
  lich_su_giao_dich: "Trade history",
  bang_ke_cong_no: "Liabilities lists",
  noti_vui_long_chon_ngay_muon_in_ke_hoach: "Please, select date to print",
  quan_ly_su_dung: "Usage management",
  noti_da_het_thuoc_trong_kho: "Medicine is out of stock",
  noti_ban_chua_chon_nha_cung_cap: "You have not selected a supplier",
  noti_vui_long_nhap_thong_tin_lo_thuoc: 'Please enter drug batch information',
  noti_ban_chua_nhap_thong_tin_phieu: "You have not entered ticket information yet",
  noti_thuoc_khong_thuoc_nha_cung_cap: 'Drugs not owned by the supplier',
  ket_thuc: "Finish",
  ket_thuc_va_in: "Finished and print",
  tien_du_sau_khi_tat_toan_goi_dich_vu: "Remaining money after finalizing the service package",
  hoa_don_lien: "Copy",
  noti_khong_the_huy_phieu_vi_co_thuoc_da_ke: "The prescription cannot be cancelled because the medication has already been prescribed",
  noti_co_loi_xay_ra_khong_the_huy_phieu: "Error detached ! can't elluminate ballot",
  nguoi_thuc_hien_chinh: "Main implementer",
  phieukhambenh_emp_qr_oncare: "Please scan the QR code to download the Oncare apps and track your result",
  stop_bang_cao: "High risk of OSA",
  title_giai_phap_phong_kham: "Smart solution for managing clinics and polyclinic chains effectively, conveniently, anytime, anywhere on any platform",
  them_chi_tiet_doi_tuong: "Add object details",
  ap_dung_tat_ca: "Apply all",
  in_barcode: "Print barcodes",
  barcode_thuong: "Normal barcode",
  nghiem_phap_duong: "Oral glucose tolerance test",
  glucose_mau: "Blood sugar",
  ban_co_chac_chan_muon_huy_sid: "Are you sure you want to cancel SID ?",
  in_ma_SID: "Print SID",
  ong_mau_khong_ten: "Unknown test tube",
  danh_sach_dich_vu_can_hoan_tien: "Service list requires refund",
  noti_ban_co_muon_xua_het_anh_khong: "Are you sure want to delete all ?",
  noti_vui_long_ket_noi_camera: "Please, connect to your camera",
  modal_hay_xac_nhan: "Please, confirm ...",
  new: "New",
  administration: "Admin-istration",
  kham_benh_short: "Medical exam",
  dvt: "Unit",
  nguoi_ho_tro: "Assistant",
  dich_truyen_vat_tu: 'Infusion fluids and supplies',
  dien_bien_tinh_trang_khach_hang: "Evolution of customer status",
  truoc_thuc_hien: "Before execution",
  trong_khi_thuc_hien: "During execution",
  sau_khi_thuc_hien: "After execution",
  tro_ly_bac_si: "Doctor assistant",
  phieu_dang_ky_dich_vu: "Service registration form",
  already_duplicated: "Already duplicated !",
  phieu_benh_dieu_tri: "Medical treatment report",
  aivi_note_1: "Ultrasound is a paraclinical examination. It should be combined with clinical examination to detect diseases early in pregnancy",
  aivi_note_2: "Ultrasound can only detect 80-90% of fetal defects",
  aivi_note_3: "When you go back for a checkup, you need to bring these results with you",
  in_benh_trinh: "Print medical record",
  benh_trinh_dieu_tri: "Medical record",
  vui_long_chon_ngay_muon_in_benh_trinh: "Please select the date to print the medical report",
  chi_so_suc_khoe: "Health index",
  ko_co_dv_thu_thuat: "Surgical procedures do not exist",
  toi_da_duoc_tu_van_va_dong_y: "I have been fully informed and agree to use the service",
  tu_van: "Counseling",
  tt_kham_goi_lieu_trinh: "Treatment packages information",
  goi_kham: "Examination bundle",
  goi_dieu_tri: "Treatment bundle",

  vui_long_chon_ngay_dat_hen: "Please select an appointment date",
  chinh_sua_phieu_thu_thanh_toan: "Edit payment receipt",
  print_all: "Print all",
  warning_chinh_sua_hoa_don: "The old receipt will be canceled and a new one will be updated. Are you sure you want to update new receipt information?",
  toc_do_truyen: "Transfer rate",
  tinh_trang_mau: "Sample status",
  loai_mau: "Sample type",
  dat: "qualified",
  namAu_haThanh_248_note_1: "Ultrasound is not harmful to health.",
  namAu_haThanh_248_note_2: "Bring these results with you when you go to the doctor next time.",
  trieu_chung: "Symptoms",
  kham_goi: "Bundle examination",
  ky_dien_tu: "Electronic signature",
  vui_long_chon_bs_kham: "Please choose a doctor !",
  tg_lay_mau: "Sampling time",
  tg_nhan_mau: "Receiving time",
  CSTC: "Reference value",
  note_print_xn_5: "Results may vary between different laboratories",
  ko_the_huy_do_cap_nhat_trang_thai: "Cancellation is not possible because the ticket status has been updated",
  vong_hong_chau: "Pelvic circumference",
  tuoi_man_kinh: "Age of menopause",
  quest_gay_xuong: "Have you ever broken a bone?",
  quest_di_kham_loang_xuong: "Have you ever been examined for osteoporosis?",
  gan: "Liver disease",
  than: "Kidney disease",
  roi_loan_lipit: "Lipid disorders",
  loang_xuong: "Osteoporosis",
  gay_xuong: "Fracture",
  field_ly_do_tu_van_dinh_duong: "Reasons for nutrition and exercise consultation",
  field_mot_ngay_an_dien_hinh_hien_nay: "A typical day of eating today",
  field_mot_ngay_tap_va_hoat_dong_dien_hinh_hien_nay: "A typical day of training and activities today",
  don_dinh_duong: "Single nutrition",
  don_tap_luyen: "Training menu",
  luu_y_chung: "General note",
  tu_van_xu_tri: "Management consulting",
  vong_bung_qua_eo: "Circumference around the waist",
  vong_bung_qua_ron: "waist around the navel",
  vong_bung_qua_gai_chau_truoc_tren: "Circle the abdomen over the anterior superior iliac spine",
  muc_do_cam_ket_cua_khach_hang: "Level of customer commitment",
  noti_dong_bo_ngay_th: "Do you want to synchronize implementation date for all services in the examination?",
  khuyen_mai: "Promotion",
  thuc_thu: "Real revenue",
  thuoc_co_san: "Available medicine",
  noti_sua_gia_lieu_trinh: "The service and speed reduction information in the package will be updated again, are you sure you want to apply the service price?",
  huy_cong_no: "Cancel debt",
  kham_thuong: "Normal examination",
  huy_cong_no_warning: "Receipts will be canceled when you cancel the account. \nAre you sure you want to cancel this account from the customer?",
  address: "address",
  drugList: "list of drugs",
  so_tien_khach_thanh_toan: "Customer payment amount",
  nhap_ly_do: "Enter reason",
  ly_do: "reason",
  paid_2: "Paid",
  bang_ke_thanh_toan_goi: "Treatment package payment statement",

  total_money: 'Total amount',
  totalExemptions: "Total exemption",
  debtS: "Debt",
  chua_tat_toan_het: "Not yet settled",
  thuoc_sap_het_han: "This medicine is about to expire",
  tt_goi_lieu_trinh: "Treatment bundle info",
  chua_chon_kh_da_tt: "No paid customer is selected",
  dieu_duong_giu: "Nursing care",
};

export default en;
