import JsBarcode from "jsbarcode";
import moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import i18n, { languageKeys, languages } from "../../../../i18n";
import Images from "components/Images/Images";
import { formatNumberToPrice, layDiaChi, readPrice } from "helpers";
import { enDateFormat } from "constants/keys";
import { renderReason } from "components/PdfTemplates/sohoadon/ChiTietSoTamUng";

export const stylePhieuTamUng = `
#phieu-tam-ung * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }
#phieu-tam-ung .logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
    object-position: top center;
    margin-top: 4px;
}
#phieu-tam-ung .nguoi-nop-tien { font-size: 12px; }
#phieu-tam-ung .thu-ngan { font-size: 12px; }
#phieu-tam-ung .header { display: flex; gap: 10px; position:relative; font-size: 10px;}
#phieu-tam-ung .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 10px; }
#phieu-tam-ung .title { text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto;}
#phieu-tam-ung .subtitle { margin-block: 0.25rem; font-size: 12px; font-weight: bold; }
#phieu-tam-ung .table * { line-height: 1.2em; }
#phieu-tam-ung .table { page-break-inside:auto; }
#phieu-tam-ung tr    { page-break-inside:avoid; page-break-after:auto; }
#phieu-tam-ung thead { display:table-header-group }
#phieu-tam-ung tfoot { display:table-footer-group }
#phieu-tam-ung .table { margin-top: 0.5rem; margin-bottom: 0.75rem; }
#phieu-tam-ung .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-tam-ung .table th, .table td { padding: 5px; }
#phieu-tam-ung .table th { text-align: start; }
#phieu-tam-ung .noBorder2 { border-right: none; }
#phieu-tam-ung .noBorder { border-left: none; }
#phieu-tam-ung .foot { display: flex; justify-content: space-between; padding-top: 20px; }
#phieu-tam-ung .avatar { height: 80px; width: 80px; padding-right: 15px; }
#phieu-tam-ung .anotherFoot { display: flex; padding-top: 10px; }
#phieu-tam-ung .anotherFoot > div { width: 50%; text-align: center; }
#phieu-tam-ung .des { font-style: italic; }
#phieu-tam-ung .sub-barcode-text { font-size:12px;position:absolute;top:50px; left:50%; line-height: 1.2em; transform: translateX(-50%);}
#phieu-tam-ung .phone-website { display: flex; gap: 24px; align-items: center }

@media print {
   #phieu-tam-ung, #phieu-tam-ung .foot-break { break-inside: avoid; }
   #phieu-tam-ung { page-break-after: always;}
}
`;


export const stylePhieuTamUngGL = `
#phieu-tam-ung * { font-size: 14pt; font-family: "Times New Roman", Times, serif; }
#phieu-tam-ung .logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
    object-position: top center;
    margin-top: 4px;
}
#phieu-tam-ung .header { display: flex; gap: 10px; position:relative; font-size: 14pt;}
#phieu-tam-ung .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 16pt; }
#phieu-tam-ung .title { text-transform: uppercase; font-size: 16pt; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto;}
#phieu-tam-ung .subtitle { margin-block: 0.25rem; font-size: 14pt; font-weight: bold; }
#phieu-tam-ung .table * { line-height: 1.2em; }
#phieu-tam-ung .table { page-break-inside:auto; }
#phieu-tam-ung tr    { page-break-inside:avoid; page-break-after:auto; }
#phieu-tam-ung thead { display:table-header-group }
#phieu-tam-ung tfoot { display:table-footer-group }
#phieu-tam-ung .table { margin-top: 0.5rem; margin-bottom: 0.75rem; }
#phieu-tam-ung .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-tam-ung .table th, .table td { padding: 5px; }
#phieu-tam-ung .table th { text-align: start; }
#phieu-tam-ung .noBorder2 { border-right: none; }
#phieu-tam-ung .noBorder { border-left: none; }
#phieu-tam-ung .foot { display: flex; justify-content: space-between; padding-top: 20px; }
#phieu-tam-ung .avatar { height: 80px; width: 80px; padding-right: 15px; }
#phieu-tam-ung .anotherFoot { display: flex; padding-top: 10px; }
#phieu-tam-ung .anotherFoot > div { width: 50%; text-align: center; }
#phieu-tam-ung .des { font-style: italic; }
#phieu-tam-ung .sub-barcode-text { font-size:14pt;position:absolute;top:50px; left:50%; line-height: 1.2em; transform: translateX(-50%);}
#phieu-tam-ung .phone-website { display: flex; gap: 24px; align-items: center }

#phieu-tam-ung .nguoi-nop-tien { font-size: 14pt; }
#phieu-tam-ung .thu-ngan { font-size: 14pt; }

@media print {
   #phieu-tam-ung, #phieu-tam-ung .foot-break { break-inside: avoid; }
   #phieu-tam-ung { page-break-after: always;}
}
`;

const PhieuTamUng = ({ data, rid }) => {
    const userProfile = useSelector((state) => state.auth.user);
    useEffect(() => {
        if (!!rid && !!data.MA_BENH_NHAN) {
            JsBarcode(`#barcode-${rid}`)
                .options({ font: "OCR-B" })
                .CODE128(data.MA_BENH_NHAN, {
                    text: ` `,
                    fontSize: 10,
                    width: 1.2,
                    height: 40,
                    textMargin: 0,
                    margin: 0,
                    padding: 0,
                })
                .render();
        }
    }, [data.MA_BENH_NHAN, rid]);

    return Array(2)
        .fill(null)
        .map((_, index) => (
            <div id="phieu-tam-ung" style={index === 0 ? { marginTop: -7 } : {}} key={index}>
                {/* <div className="header">
                    {userProfile.benh_vien.ANH_DAI_DIEN && <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN}/>}

                    <div style={{ display: "flex", gap: '80px', justifyContent: "space-between", width: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <div className="hospital-name">{userProfile.benh_vien.TEN_CSKCB}</div>
                            <div>
                                {i18n.t(languageKeys.field_Dia_chi)}: {layDiaChi(userProfile.benh_vien)}
                            </div>
                            <div className="phone-website">
                                <div>
                                    {i18n.t(languageKeys.dien_thoai)}: {userProfile.benh_vien.SO_DIEN_THOAI}
                                </div>
                                <div>
                                    Website: {userProfile?.benh_vien?.WEBSITE || ''}
                                </div>
                            </div>
                            <div>
                                Email: {userProfile?.benh_vien?.EMAIL || ''}
                            </div>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center', textAlign: 'center', position: 'relative', minWidth: '200px'}}>
                            <svg id={`barcode-${rid}`}></svg>
                            <div className="sub-barcode-text">
                                <div style={{width: '300px', textAlign: 'center'}}>
                                    {i18n.t(languageKeys.ma_kh)}: <b>{data.MA_BENH_NHAN}</b>
                                </div>
                                <div style={{width: '300px', textAlign: 'center'}}>
                                    {i18n.t(languageKeys.ma_phieu)}: <b>{data.MA_PHIEU}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="header">
          {userProfile.benh_vien.ANH_DAI_DIEN && <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN}/>}

          <div style={{ display: "flex", gap: '80px', justifyContent: "space-between", width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <div className="hospital-name">{userProfile.benh_vien.TEN_CSKCB}</div>
              <div>
                <span className="txt-700">{i18n.t(languageKeys.field_Dia_chi)}</span>: {layDiaChi(userProfile.benh_vien)}
              </div>
              <div className="phone-website">
                <div>
                  <span className="txt-700">{i18n.t(languageKeys.dien_thoai)}</span>: {userProfile.benh_vien.SO_DIEN_THOAI}
                </div>
              </div>
              <div>
                  <span className="txt-700">Website</span>: {userProfile?.benh_vien?.WEBSITE || ''}
                </div>
              <div>
                <span className="txt-700">Email</span>: {userProfile?.benh_vien?.EMAIL || ''}
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', textAlign: 'center', position: 'relative', minWidth: '150px' }}>
              <svg id={`barcode-${rid}`}></svg>
              <div className="sub-barcode-text">
                <div style={{ width: 300, textAlign: 'center' }}>
                  {i18n.t(languageKeys.ma_kh)}:<b> {data.MA_BENH_NHAN}</b>
                </div>
                <div style={{ width: 300, textAlign: 'center' }}>
                  {i18n.t(languageKeys.field_So_phieu)}: <b>{data.MA_PHIEU}</b>
                </div>
              </div>
            </div>

          </div>
        </div>


                <div className="title">
                    {i18n.t(languageKeys.phieu_tam_dat_coc)}
                </div>

                <div className="subtitle" style={{ textAlign: "center", lineHeight: "1em" }}>
                    ({i18n.t(languageKeys.hoa_don_lien)} {index + 1}: {index + 1 === 1 ? i18n.t(languageKeys.thu_ngan_giu) : i18n.t(languageKeys.khach_hang_giu)})
                </div>

                <div style={{ height: 30 }} />

                <div>
                    <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                        <div style={{ display: "flex", gap: "20%" }}>
                            <div>
                                {" "}
                                {i18n.t(languageKeys.ho_va_ten_khach_hang)}: <b>{data.TEN}</b>
                            </div>
                        </div>
                        <div style={{ display: "flex", gap: "20%" }}>
                            <div>
                                {" "}
                                {i18n.t(languageKeys.field_Ngay_sinh)}:
                                {" "}
                                {data.NGAY_SINH?.length === 4
                                    ? data.NGAY_SINH
                                    : moment(data.NGAY_SINH).isValid()
                                        ? moment(data.NGAY_SINH).format("DD/MM/YYYY")
                                        : moment(data.NGAY_SINH, "DDMMYYYY").format("DD/MM/YYYY")
                                }
                            </div>
                        </div>
                    </div>

                    <div>
                        {" "}
                        {i18n.t(languageKeys.dia_chi)}: {[data.DIA_CHI_CHI_TIET, data.TEN_PHUONG_XA, data.TEN_QUAN_HUYEN, data.TEN_TINH_THANH].filter(i => i !== null && i !== undefined && i !== "").join(", ")}
                    </div>

                    <div>
                        {" "}
                        {i18n.t(languageKeys.ho_ten_ng_nop_tien)}: {data.NGUOI_NOP_TIEN}
                    </div>

                    <div>
                        {" "}
                        {i18n.t(languageKeys.field_hinh_thuc_thanh_toan)}: {data.TEN_HINH_THUC_THANH_TOAN}
                    </div>

                    <div>
                        {" "}
                        {i18n.t(languageKeys.ly_do_tam_ung)}: {renderReason(data?.LY_DO || "") }
                    </div>

                    <div>
                        {" "}
                        {data?.LOAI_PHIEU === 'THANH_TOAN' ? i18n.t(languageKeys.so_tien_da_thanh_toan) : i18n.t(languageKeys.so_tien_tam_ung)}: <b>{formatNumberToPrice(data.SO_TIEN, "đ", true)}</b>
                    </div>

                    <i style={{ marginLeft: 100 }}>
                        {" "}
                        {i18n.t(languageKeys.bang_chu)}: {i18n.language === languages.tieng_viet ? DocTienBangChu(data.SO_TIEN) : readPrice(data.SO_TIEN)}

                    </i>

                </div>

                <div className="foot-break" style={{ minHeight: "20vh", marginTop: '20px' }}>

                    <div className="anotherFoot">
                        <div style={{ marginTop: 30 }}>
                            <b className="nguoi-nop-tien">{i18n.t(languageKeys.nguoi_nop_tien)}</b>
                            <div className="des">({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</div>
                        </div>

                        <div>
                            <div style={{ display: "flex", justifyContent: "center", }}>
                                {data?.ngay_tao ?
                                    <i>
                                        {data.gio ? data.gio + ", " : ""}{data.ngay_tao}
                                    </i>
                                    :
                                    <i
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                            marginTop: 15,
                                        }}
                                    >
                                        {moment().format(enDateFormat)}
                                    </i>}
                            </div>
                            <b className="thu-ngan" >{i18n.t(languageKeys.data_Thu_ngan)}</b>
                            <div className="des">({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</div>
                            <div style={{ marginTop: 50, fontWeight: 'bold' }}>{data?.CHU_KY || userProfile.CHU_KY}</div>
                        </div>
                    </div>
                </div>
            </div>
        ));
};

export default PhieuTamUng;

var ChuSo = new Array(" không ", " một ", " hai ", " ba ", " bốn ", " năm ", " sáu ", " bảy ", " tám ", " chín ");
var Tien = new Array("", " nghìn", " triệu", " tỷ", " nghìn tỷ", " triệu tỷ");

var digit = new Array(" zero ", " one ", " two ", " three ", " four ", " five ", " six ", " seven ", " eight ", " nine ");
var money = new Array("", " thousand", " milion", " bilion", " thousand bilion", " milion bilion");

//1. Hàm đọc số có ba chữ số;
function DocSo3ChuSo(baso) {
    var tram;
    var chuc;
    var donvi;
    var KetQua = "";
    tram = parseInt(baso / 100);
    chuc = parseInt((baso % 100) / 10);
    donvi = baso % 10;
    if (tram === 0 && chuc === 0 && donvi === 0) return "";
    if (tram !== 0) {
        KetQua += ChuSo[tram] + " trăm ";
        if (chuc === 0 && donvi !== 0) KetQua += " linh ";
    }
    if (chuc !== 0 && chuc !== 1) {
        KetQua += ChuSo[chuc] + " mươi";
        if (chuc === 0 && donvi !== 0) KetQua = KetQua + " linh ";
    }
    if (chuc === 1) KetQua += " mười ";
    switch (donvi) {
        case 1:
            if (chuc !== 0 && chuc !== 1) {
                KetQua += " mốt ";
            } else {
                KetQua += ChuSo[donvi];
            }
            break;
        case 5:
            if (chuc === 0) {
                KetQua += ChuSo[donvi];
            } else {
                KetQua += " lăm ";
            }
            break;
        default:
            if (donvi !== 0) {
                KetQua += ChuSo[donvi];
            }
            break;
    }
    return KetQua;
}

//2. Hàm đọc số thành chữ (Sử dụng hàm đọc số có ba chữ số)
function DocTienBangChu(SoTien) {
    SoTien = Number(SoTien);
    var lan = 0;
    var i = 0;
    var so = 0;
    var KetQua = "";
    var tmp = "";
    var ViTri = new Array();
    if (SoTien < 0) return `${i18n.t(languageKeys.noti_so_tien_am)} !`;
    if (SoTien === 0) return `${i18n.t(languageKeys.noti_khong_dong)} !`;
    if (SoTien > 0) {
        so = SoTien;
    } else {
        so = -SoTien;
    }
    if (SoTien > 8999999999999999) {
        //SoTien = 0;
        return "Số quá lớn!";
    }
    ViTri[5] = Math.floor(so / 1000000000000000);
    if (isNaN(ViTri[5])) ViTri[5] = "0";
    so = so - parseFloat(ViTri[5].toString()) * 1000000000000000;
    ViTri[4] = Math.floor(so / 1000000000000);
    if (isNaN(ViTri[4])) ViTri[4] = "0";
    so = so - parseFloat(ViTri[4].toString()) * 1000000000000;
    ViTri[3] = Math.floor(so / 1000000000);
    if (isNaN(ViTri[3])) ViTri[3] = "0";
    so = so - parseFloat(ViTri[3].toString()) * 1000000000;
    ViTri[2] = parseInt(so / 1000000);
    if (isNaN(ViTri[2])) ViTri[2] = "0";
    ViTri[1] = parseInt((so % 1000000) / 1000);
    if (isNaN(ViTri[1])) ViTri[1] = "0";
    ViTri[0] = parseInt(so % 1000);
    if (isNaN(ViTri[0])) ViTri[0] = "0";
    if (ViTri[5] > 0) {
        lan = 5;
    } else if (ViTri[4] > 0) {
        lan = 4;
    } else if (ViTri[3] > 0) {
        lan = 3;
    } else if (ViTri[2] > 0) {
        lan = 2;
    } else if (ViTri[1] > 0) {
        lan = 1;
    } else {
        lan = 0;
    }
    for (i = lan; i >= 0; i--) {
        tmp = DocSo3ChuSo(ViTri[i]);
        KetQua += tmp;
        if (ViTri[i] > 0) KetQua += Tien[i];
        if (i > 0 && tmp.length > 0) KetQua += ","; //&& (!string.IsNullOrEmpty(tmp))
    }
    if (KetQua.substring(KetQua.length - 1) === ",") {
        KetQua = KetQua.substring(0, KetQua.length - 1);
    }
    KetQua = KetQua.substring(1, 2).toUpperCase() + KetQua.substring(2);

    return KetQua + " vnđ"; //.substring(0, 1);//.toUpperCase();// + KetQua.substring(1);
}

function numToWords(n) {
    const arr = (x) => Array.from(x);
    const num = (x) => Number(x) || 0;
    const str = (x) => String(x);
    const isEmpty = (xs) => xs.length === 0;
    const take = (n) => (xs) => xs.slice(0, n);
    const drop = (n) => (xs) => xs.slice(n);
    const reverse = (xs) => xs.slice(0).reverse();
    const comp = (f) => (g) => (x) => f(g(x));
    const not = (x) => !x;
    const chunk = (n) => (xs) => isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];
    let a = [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
    ];
    let b = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
    let g = ["", "thousand", "million", "billion", "trillion", "quadrillion", "quintillion", "sextillion", "septillion", "octillion", "nonillion"];
    // this part is really nasty still
    // it might edit this again later to show how Monoids could fix this up
    let makeGroup = ([ones, tens, huns]) => {
        return (
            [num(huns) === 0 ? "" : a[huns] + " hundred ", num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + "-") || "", a[tens + ones] || a[ones]].join(
                ""
            ) + " dong"
        );
    };
    // "thousands" constructor; no real good names for this, i guess
    let thousand = (group, i) => (group === "" ? group : `${group} ${g[i]}`);
    // execute !
    if (typeof n === "number") return numToWords(String(n));
    if (n === "0") return "zero";
    return comp(chunk(3))(reverse)(arr(n)).map(makeGroup).map(thousand).filter(comp(not)(isEmpty)).reverse().join(" ") + " dong";
}