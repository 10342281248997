import { put, select } from 'redux-saga/effects';
import { takeLatest } from '@redux-saga/core/effects';
import { apis } from "../../../constants";
import { common_post } from 'helpers';
import { getChiTietGoiLieuTrinh, getDsGoiLieuTrinh, getKeHoachSuDung, getLieuTrinhData, getPhacDoDieuTri, saveKeHoachSuDung, savePhacDoLieuTrinh, setDoneFetch, setLtrLoading, setPhacDoID, setPlanData, setServiceInitList, setServicePlanList, setServiceUsageList, setSupplyInitList, setSupplyPlanList, setSupplyUsageList, setUsedBundleList, updateDsDeXuat, updateDsGoiLieuTrinh } from 'ducks/slices/GoiDichVu/GoiVatTu.slice';
import i18n, { languageKeys } from '../../../i18n';
import { notification } from 'antd';
import { getUser } from 'ducks/slices/authSlice';
import { setSelectLoading } from 'ducks/slices/loadingSlice';
import moment from 'moment';
import { packageSubscriptionListSuccess } from 'ducks/slices/GoiDichVu/DKGoiDichVu/DKGoiDichVuSlice';
import { formatPatientData } from 'pages/QuanLyTiepDon/hooksTiepDon';

export function* watchDoGoiVatTu() {
  yield takeLatest(savePhacDoLieuTrinh, handlesavePhacDoLieuTrinh)
  yield takeLatest(getPhacDoDieuTri, handleGetPhacDoDieuTri)
  yield takeLatest(saveKeHoachSuDung, handleSaveKeHoachSuDung)
  yield takeLatest(getKeHoachSuDung, handleGetKeHoachSuDung)
  yield takeLatest(getDsGoiLieuTrinh, handleGetDsGoiLieuTrinh)
  yield takeLatest(getChiTietGoiLieuTrinh, handleChiTietGoiLieuTrinh)
}


function* handlesavePhacDoLieuTrinh(action) {
  const { data, afterSuccess, isNew, newCustomerData, isFast } = action.payload;
  try {
    yield put(setLtrLoading(true));
    //
    if (isNew && data.PHAN_LOAI === "PHAC_DO_KHOI_TAO") {
      const patientData = yield formatPatientData(newCustomerData, false);
      if (!data.BENH_NHAN_ID) {
        const resNC = yield common_post(apis.tiep_don_tao_benh_nhan, patientData);
        if (!!resNC) {
          data.BENH_NHAN_ID = resNC.ID;
          data.MA_BENH_NHAN = resNC.MA_BENH_NHAN;
        } else {
          return notification.error({
            placement: "bottomLeft",
            message: i18n.t(languageKeys.noti_tao_moi_benh_nhan_that_bai),
          });
        }
      } else {
        const resCI = yield common_post(apis.tiep_don_kiem_tra_thong_tin, patientData);
        if (resCI.status !== "OK") {
          return notification.error({
            placement: "bottomLeft",
            message: i18n.t(languageKeys.trung_thong_tin),
          });
        }
      }
    }
    //
    const res = yield common_post(isNew ? apis.luuPhacDoDieuTri : (data.PHAN_LOAI === "PHAC_DO_SU_DUNG" ? apis.suaTienTrinhSuDung : apis.chinhSuaPhacDoDieuTri), data)
    if (res) {
      if (res.status === 'OK') {
        notification.success({
          message: isNew ? i18n.t(languageKeys.them_moi_thanh_cong) : i18n.t(languageKeys.cap_nhat_thanh_cong),
          placement: 'bottomLeft'
        });
        //
        if (data?.THONG_TIN_GOI?.IS_ACTIVE && isNew && !isFast) {
          const resDk = yield common_post(apis.lay_ds_dk_goi_dich_vu, {
            search_string: '',
            TU_NGAY: moment().format("YYYYMMDD"),
            DEN_NGAY: moment().format("YYYYMMDD"),
            page: 1,
            limit: 15,
            ARR_GOI_DV_ID: [],
            ARR_LOAI_GOI_ID: [],
            ARR_TRANG_THAI: []
          })
          if (resDk.status === "OK") {
            yield put(packageSubscriptionListSuccess({
              page: 1,
              limit: 15,
              data: resDk?.result?.map((obj) => ({ ...obj, key: obj.ID })),
              total: resDk.total
            }));
            afterSuccess(resDk?.result?.find(i => i.GOI_DV_ID === res.GOI_DV_ID), data.BENH_NHAN_ID, data.MA_BENH_NHAN, res.GOI_DV_ID)
          }
        } else {
          afterSuccess(undefined, data.BENH_NHAN_ID, data.MA_BENH_NHAN, res.GOI_DV_ID)
        }
        //
      } else {
        notification.error({
          message: isNew ? i18n.t(languageKeys.them_moi_that_bai) : i18n.t(languageKeys.cap_nhat_that_bai),
          placement: 'bottomLeft'
        });
      }
    } else {
      notification.error({
        message: isNew ? i18n.t(languageKeys.them_moi_that_bai) : i18n.t(languageKeys.cap_nhat_that_bai),
        placement: 'bottomLeft'
      });
    }
  } catch (err) {
    notification.error({
      message: isNew ? i18n.t(languageKeys.them_moi_that_bai) : i18n.t(languageKeys.cap_nhat_that_bai),
      placement: 'bottomLeft'
    });
    console.log(err.message);
  } finally {
    yield put(setLtrLoading(false));
  }
}


function* handleGetPhacDoDieuTri(action) {
  const { kham_benh, cls } = yield select((state) => state.data)
  const { data, afterSuccess } = action.payload;
  try {
    yield put(setLtrLoading(true));
    const res = yield common_post(data.PHAN_LOAI === "PHAC_DO_SU_DUNG" ? apis.layTienTrinhSuDung : apis.layPhacDoDieuTri, data)
    if (res) {
      if (res.status === 'OK') {
        const formatRes = res.result?.map(item => ({
          ...item,
          DANH_SACH_DICH_VU: (item.DANH_SACH_DICH_VU || item.DS_DV)?.map(dsdv => ({
            ...dsdv,
            DV_ID: dsdv.DV_ID || dsdv.DICH_VU_ID,
            DICH_VU_ID: dsdv.DICH_VU_ID || dsdv.DV_ID,
            TEN_DICHVU: dsdv.TEN_DICHVU || dsdv.TEN_DV,
            LIST_PHONG_ID: kham_benh.find(i => i.DV_ID === dsdv.DICH_VU_ID || i.DV_ID === dsdv.DV_ID)?.LIST_PHONG_ID
              || cls.flatMap(i => i.DICH_VU).find(i => i.DV_ID === dsdv.DICH_VU_ID || i.DV_ID === dsdv.DV_ID)?.LIST_PHONG_ID,
            old: true,
            // BAC_SI_DE_XUAT: item.BAC_SI_DE_XUAT
          })),
          DANH_SACH_VAT_TU: item.DANH_SACH_VAT_TU.map(i => ({ ...i, old: true })),
          THONG_TIN_GOI: {
            ...(item.THONG_TIN_GOI || item),
            SO_BUOI_SU_DUNG: item.SO_BUOI_SU_DUNG,
            GHI_CHU_GOI: item.GHI_CHU,
          }
        }))
        //
        if (data.PHAN_LOAI === "PHAC_DO_DE_XUAT" || !data.PHAN_LOAI) {
          yield put(updateDsDeXuat(formatRes || []))
          afterSuccess(formatRes)
        }
        //
        if (data.PHAN_LOAI === "PHAC_DO_KHOI_TAO") {
          yield put(setPhacDoID({
            key: "initID",
            value: formatRes?.[0].ID
          }))
          yield put(setServiceInitList(formatRes?.[0].DANH_SACH_DICH_VU || []))
          yield put(setSupplyInitList(formatRes?.[0]?.DANH_SACH_VAT_TU ? [...formatRes[0].DANH_SACH_VAT_TU, {}] : [{}]))
          yield put(setDoneFetch("doneFetchInit"))
          afterSuccess(formatRes?.[0]?.THONG_TIN_GOI)
        }
        //
        if (data.PHAN_LOAI === "PHAC_DO_SU_DUNG") {
          yield put(setPhacDoID({
            key: "usageID",
            value: formatRes?.[0].PHAC_DO_ID
          }))
          yield put(setServiceUsageList(formatRes?.[0].DANH_SACH_DICH_VU || []))
          yield put(setSupplyUsageList(formatRes?.[0]?.DANH_SACH_VAT_TU ? [...formatRes[0].DANH_SACH_VAT_TU, {}] : [{}]))
          yield put(setDoneFetch("doneFetchUsage"))
          yield put(setUsedBundleList(formatRes?.[0].DA_SU_DUNG || []))
          afterSuccess(formatRes?.[0]?.THONG_TIN_GOI)
        }
        //
      } else {
        notification.error({
          message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
          placement: 'bottomLeft'
        });
      }
    } else {
      notification.error({
        message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
        placement: 'bottomLeft'
      });
    }
  } catch (err) {
    notification.error({
      message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
      placement: 'bottomLeft'
    });
    console.log(err.message);
  } finally {
    yield put(setLtrLoading(false));
  }
}


function* handleSaveKeHoachSuDung(action) {
  const { data, afterSuccess, isNew } = action.payload;
  try {
    yield put(setLtrLoading(true));
    const res = yield common_post(isNew ? apis.luuKeHoachSuDung : apis.suaKeHoachSuDung, data)
    if (res) {
      if (res.status === 'OK') {
        notification.success({
          message: isNew ? i18n.t(languageKeys.them_moi_thanh_cong) : i18n.t(languageKeys.cap_nhat_thanh_cong),
          placement: 'bottomLeft'
        });
        afterSuccess()
      } else {
        notification.error({
          message: isNew ? i18n.t(languageKeys.them_moi_that_bai) : i18n.t(languageKeys.cap_nhat_that_bai),
          placement: 'bottomLeft'
        });
      }
    } else {
      notification.error({
        message: isNew ? i18n.t(languageKeys.them_moi_that_bai) : i18n.t(languageKeys.cap_nhat_that_bai),
        placement: 'bottomLeft'
      });
    }
  } catch (err) {
    notification.error({
      message: isNew ? i18n.t(languageKeys.them_moi_that_bai) : i18n.t(languageKeys.cap_nhat_that_bai),
      placement: 'bottomLeft'
    });
    console.log(err.message);
  } finally {
    yield put(setLtrLoading(false));
  }
}


function* handleGetKeHoachSuDung(action) {

  const userProfile = yield select(getUser)
  const { kham_benh, cls } = yield select((state) => state.data)
  const { doneFetchInit, doneFetchUsage, serviceInitList, serviceUsageList } = yield select(getLieuTrinhData)
  const { payload, afterSuccess, PHAN_LOAI } = action.payload;

  let genSource = []
  let numDay = 0

  try {
    yield put(setLtrLoading(true));
    //
    const res1 = yield common_post(apis.layKeHoachSuDung, payload)
    if (res1) {
      if (res1.status === 'OK') {
        if (res1.result?.[0]) {
          yield put(setPhacDoID({ key: "planID", value: res1.result[0].LICH_KHAM_ID }))
          yield put(setPlanData(res1.result.map(item => ({
            ...item,
            DANH_SACH_DICH_VU: item.DANH_SACH_DICH_VU?.map(dv => ({
              ...dv,
              DV_ID: dv.DICH_VU_ID,
              DON_GIA_PHAN_BO: dv.GIA_DICH_VU,
              _id: dv.DICH_VU_GOC
                ? `${dv.DICH_VU_ID}_${dv.DICH_VU_GOC}`
                : dv.DICH_VU_ID,
              LIST_PHONG_ID: kham_benh.find(i => i.DV_ID === dv.DICH_VU_ID)?.LIST_PHONG_ID
                || cls.flatMap(i => i.DICH_VU).find(i => i.DV_ID === dv.DICH_VU_ID)?.LIST_PHONG_ID,
              THANH_TIEN_PHAN_BO: dv.GIA_DICH_VU * dv.SO_LUONG
            })),
            NGAY_DAT_HEN: item.DANH_SACH_DICH_VU?.[0]?.NGAY,
            GIO_DAT_HEN: item.DANH_SACH_DICH_VU?.[0]?.GIO_BAT_DAU || item.DANH_SACH_DICH_VU?.[0]?.GIO_KET_THUC
              ? [moment(item.DANH_SACH_DICH_VU[0].GIO_BAT_DAU, "HH:mm"), moment(item.DANH_SACH_DICH_VU[0].GIO_KET_THUC, "HH:mm")]
              : undefined,
            DANH_SACH_VAT_TU: [...(item.DANH_SACH_VAT_TU || []), {}],
          }))))
        }
        yield put(setDoneFetch("doneFetchPlan"))
      } else {
        notification.error({
          message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
          placement: 'bottomLeft'
        });
      }
    } else {
      notification.error({
        message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
        placement: 'bottomLeft'
      });
    }

    //
    // if (PHAN_LOAI === "PHAC_DO_KHOI_TAO" && doneFetchInit) return;
    // if (PHAN_LOAI === "PHAC_DO_SU_DUNG" && doneFetchUsage) return;
    if ((PHAN_LOAI === "PHAC_DO_KHOI_TAO" && doneFetchInit) || (PHAN_LOAI === "PHAC_DO_SU_DUNG" && doneFetchUsage)) {
      //
      if (PHAN_LOAI === "PHAC_DO_KHOI_TAO") {
        genSource = serviceInitList
      }
      if (PHAN_LOAI === "PHAC_DO_SU_DUNG") {
        genSource = serviceUsageList
      }
    } else {
      //
      const res2 = yield common_post(PHAN_LOAI === "PHAC_DO_SU_DUNG" ? apis.layTienTrinhSuDung : apis.layPhacDoDieuTri, { ...payload, PHAN_LOAI })
      if (res2) {
        if (res2.status === 'OK') {
          const formatRes = (res2.result || res2.TAT_CA)?.map(item => ({
            ...item,
            DANH_SACH_DICH_VU: (item.DANH_SACH_DICH_VU || item.DS_DV)?.map(dsdv => ({
              ...dsdv,
              DV_ID: dsdv.DV_ID || dsdv.DICH_VU_ID,
              DICH_VU_ID: dsdv.DICH_VU_ID || dsdv.DV_ID,
              TEN_DICHVU: dsdv.TEN_DICHVU || dsdv.TEN_DV,
              LIST_PHONG_ID: kham_benh.find(i => i.DV_ID === dsdv.DICH_VU_ID || i.DV_ID === dsdv.DV_ID)?.LIST_PHONG_ID
                || cls.flatMap(i => i.DICH_VU).find(i => i.DV_ID === dsdv.DICH_VU_ID || i.DV_ID === dsdv.DV_ID)?.LIST_PHONG_ID,
              old: true,
              // BAC_SI_DE_XUAT: item.BAC_SI_DE_XUAT
            })),
            DANH_SACH_VAT_TU: item.DANH_SACH_VAT_TU.map(i => ({ ...i, old: true })),
            THONG_TIN_GOI: {
              ...(item.THONG_TIN_GOI || item),
              SO_BUOI_SU_DUNG: item.SO_BUOI_SU_DUNG,
              GHI_CHU_GOI: item.GHI_CHU,
            }
          }))
          if (PHAN_LOAI === "PHAC_DO_KHOI_TAO") {
            yield put(setPhacDoID({
              key: "initID",
              value: formatRes?.[0].ID
            }))
            yield put(setServiceInitList(formatRes?.[0].DANH_SACH_DICH_VU || []))
            yield put(setSupplyInitList(formatRes?.[0]?.DANH_SACH_VAT_TU ? [...formatRes[0].DANH_SACH_VAT_TU, {}] : [{}]))
            //
            yield put(setServicePlanList(formatRes?.[0].DANH_SACH_DICH_VU || []))
            yield put(setSupplyPlanList(formatRes?.[0]?.DANH_SACH_VAT_TU || []))
            //
            yield put(setDoneFetch("doneFetchInit"))
          }
          if (PHAN_LOAI === "PHAC_DO_SU_DUNG") {
            yield put(setPhacDoID({
              key: "usageID",
              value: formatRes?.[0].PHAC_DO_ID
            }))
            yield put(setServiceUsageList(formatRes?.[0].DANH_SACH_DICH_VU || []))
            yield put(setSupplyUsageList(formatRes?.[0]?.DANH_SACH_VAT_TU ? [...formatRes[0].DANH_SACH_VAT_TU, {}] : [{}]))
            //
            yield put(setServicePlanList(formatRes?.[0].DANH_SACH_DICH_VU || []))
            yield put(setSupplyPlanList(formatRes?.[0]?.DANH_SACH_VAT_TU || []))
            //
            yield put(setDoneFetch("doneFetchUsage"))
            yield put(setUsedBundleList(formatRes?.[0].DA_SU_DUNG || []))
          }
          afterSuccess(formatRes?.[0]?.THONG_TIN_GOI)
          //
          genSource = formatRes?.[0]?.DANH_SACH_DICH_VU
          numDay = formatRes?.[0]?.SO_BUOI_SU_DUNG

        } else {
          notification.error({
            message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
            placement: 'bottomLeft'
          });
        }
      } else {
        notification.error({
          message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
          placement: 'bottomLeft'
        });
      }
    }

    // Auto generate plan
    if (!res1.result?.[0]) {
      if (numDay) {
        const formattedBundleList =
          genSource
            .map(item => ({
              ...item,
              _id: item.DV_ID,
              DV_KEM_THEO: item.DV_KEM_THEO?.map(dvkt => ({
                ...dvkt,
                _id: `${dvkt.DV_KEM_THEO_ID || dvkt.DICH_VU_ID}_${item.DV_ID}`,
              }))
            }))
        let autoArr = []
        for (let i = 1; i <= numDay; i++) {
          autoArr.push({
            DANH_SACH_DICH_VU: formattedBundleList.reduce((arr, obj) => {
              if (obj.SO_LUONG >= i) {
                obj.DV_KEM_THEO?.[0]
                  ? arr.push(...obj.DV_KEM_THEO.map(dvkt => ({ ...dvkt, SO_LUONG: 1 })))
                  : arr.push({ ...obj, SO_LUONG: 1 });
              }
              return arr.map(i => ({
                ...i,
                THANH_TIEN_PHAN_BO: i.DON_GIA_PHAN_BO,
                MIEN_GIAM: i.MIEN_GIAM / obj.SO_LUONG,
                TONG_TIEN: i.DON_GIA_PHAN_BO - i.MIEN_GIAM / obj.SO_LUONG,
                HO_TEN_NGUOI_TAO: `${userProfile?.HO} ${userProfile?.TEN}`,
                NGAY_TAO: moment().format('YYYYMMDD'),
                GIO_TAO: moment().format('H:mm'),
                HO_TEN_NGUOI_CAP_NHAT: undefined,
                NGAY_CAP_NHAT: undefined,
                GIO_CAP_NHAT: undefined,
              }));
            }, []),
            DANH_SACH_VAT_TU: [{}]
          })
        }
        yield put(setPlanData(autoArr.filter(i => i.DANH_SACH_DICH_VU[0])))
      } else {
        yield put(setPlanData([
          { DANH_SACH_DICH_VU: [], DANH_SACH_VAT_TU: [{}] }
        ]))
      }
    }

    //
  } catch (err) {
    notification.error({
      message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
      placement: 'bottomLeft'
    });
    console.log(err.message);
  } finally {
    yield put(setLtrLoading(false));
  }
}


function* handleGetDsGoiLieuTrinh() {
  try {
    yield put(setSelectLoading(true));
    const res = yield common_post(apis.get_packet_service_list, {
      page: 1,
      limit: 99999,
      NHOM_GOI: ['GOI_LIEU_TRINH']
    })
    if (res) {
      if (res.status === 'OK') {
        yield put(updateDsGoiLieuTrinh(res));
      }
    }
  } catch (err) {
    if (err instanceof Error) console.log(err.message)
  } finally {
    yield put(setSelectLoading(false));
  }
}


function* handleChiTietGoiLieuTrinh(action) {
  const userProfile = yield select(getUser)
  const { kham_benh, cls } = yield select((state) => state.data)
  const { ID, afterSuccess, SBSD } = action.payload
  try {
    yield put(setLtrLoading(true));
    const res = yield common_post(apis.get_packet_service_list, { ID })
    if (res) {
      if (res.status === 'OK') {
        const formatRes = res.result?.map(item => ({
          ...item,
          DS_DV: item.DS_DV
            ?.map(dsdv => ({
              ...dsdv,
              DICH_VU_ID: dsdv.DV_ID,
              LIST_PHONG_ID: kham_benh.find(i => i.DV_ID === dsdv.DV_ID)?.LIST_PHONG_ID
                || cls.flatMap(i => i.DICH_VU).find(i => i.DV_ID === dsdv.DV_ID)?.LIST_PHONG_ID,
            }))
          // Lọc bỏ dv trùng vs dv trong combo
          // ?.filter(dv => !item.DS_DV?.some(d => d.DV_KEM_THEO?.some(i => i.DICH_VU_ID === dv.DV_ID)))
        }))
        if (afterSuccess) {
          afterSuccess(formatRes, res?.VAT_TU)
        } else {
          yield put(setServiceInitList(
            formatRes?.[0]?.DS_DV
              ? formatRes[0].DS_DV?.map(i => (
                {
                  ...i,
                  HO_TEN_NGUOI_TAO: `${userProfile?.HO} ${userProfile?.TEN}`,
                  NGAY_TAO: moment().format('YYYYMMDD'),
                  GIO_TAO: moment().format('H:mm'),
                  HO_TEN_NGUOI_CAP_NHAT: null,
                  NGAY_CAP_NHAT: null,
                  GIO_CAP_NHAT: null,
                  //
                  SO_LUONG: i.SO_LUONG * SBSD,
                  THANH_TIEN: i.THANH_TIEN * SBSD,
                  THANH_TIEN_PHAN_BO: i.THANH_TIEN_PHAN_BO * SBSD,
                  MIEN_GIAM: (
                    i.MIEN_GIAM_PHAN_TRAM
                      ? i.THANH_TIEN_PHAN_BO * SBSD / 100 * i.MIEN_GIAM_PHAN_TRAM
                      : i.MIEN_GIAM
                  ) || 0,
                  TONG_TIEN: (
                    (i.MIEN_GIAM_PHAN_TRAM
                      ? i.TONG_TIEN / 100 * i.MIEN_GIAM_PHAN_TRAM
                      : i.TONG_TIEN
                    ) || i.THANH_TIEN_PHAN_BO
                  ) * SBSD,
                }
              )) : []
          ))
          yield put(setSupplyInitList(res?.VAT_TU ? [...res.VAT_TU, {}] : [{}]))
        }
      } else {
        notification.error({
          message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
          placement: 'bottomLeft'
        });
      }
    } else {
      notification.error({
        message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
        placement: 'bottomLeft'
      });
    }
  } catch (err) {
    notification.error({
      message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
      placement: 'bottomLeft'
    });
    console.log(err.message);
  } finally {
    yield put(setLtrLoading(false));
  }
}