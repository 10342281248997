import React from "react";
import style from "./style.module.less";
import i18n, { languageKeys } from "../../../i18n";
import { Row, Col, Button } from "antd";
import moment from "moment";
import { convertDateToValue, removeTags, rid } from "../../../helpers";
import { dataPartnerCode, HUONG_XU_TRI } from "../../../constants/keys";
import { NamePhongKhamMat, NamePhongRHM, NamePhongTMH, NamePhongTai } from "pages/QuanLyKhamBenh/views/ThongTinKham/constantsTTK";
import { keyFormKham } from "constants/data";
import { useSelector } from "react-redux";

const ItemThongTinKham = ({ item, openDeXuat }) => {
  const userProfile = useSelector((state) => state.auth.user);

  const valur_mall = [
    { label: i18n.t(languageKeys.mallampati_1), value: "LOP1" },
    { label: i18n.t(languageKeys.mallampati_2), value: "LOP2" },
    { label: i18n.t(languageKeys.mallampati_3), value: "LOP3" },
    { label: i18n.t(languageKeys.mallampati_4), value: "LOP4" },
  ]
  const getiItemList = (KEY, title) => {
    if (item[`${KEY}`]) {
      return (
        <p style={styles.margin_left}>
          &bull; {title}: {item[`${KEY}`]}
        </p>
      );
    }
  };

  const convertKetQua = (arr, type) => {
    if (Array.isArray(arr) && !!arr?.length) {
      let data = arr
        .filter((item) => !!item && item?.KET_QUA && item.PHAN_LOAI === type)
        .map((item) => {
          if (type === "CDHA") {
            return item.KET_QUA;
          }
          return <li key={item.KET_QUA}>{item.KET_QUA}</li>;
        });

      if (type !== "CDHA") {
        data = Array.from(new Map(data.map(item => [item.key, item])).values());
      } else {
        data = Array.from(new Set(data));
      }
      return data;
    } else return [];
    // if (Array.isArray(arr) && !!arr?.length) {
    //   let data = arr
    //     .filter((item) => !!item && item?.KET_QUA && item.PHAN_LOAI === type)
    //     .map((item) => {
    //       if(type == "CDHA"){
    //         return item.KET_QUA
    //       }
    //       return <li>{item.KET_QUA}</li>;
    //     });
    //   return data;
    // } else return [];
  };

  const getValueMall = (value) => {
    if (!value) return
    const getTex = valur_mall?.find(obj => obj.value == value)?.label || ""
    return getTex
  }

  const getTexEp = (TONG_DIEM_BUON_NGU) => {
    if (1 <= TONG_DIEM_BUON_NGU && TONG_DIEM_BUON_NGU <= 6) {
      return `${TONG_DIEM_BUON_NGU} - ${i18n.t(languageKeys.epworth_1_6)}`
    }

    if (7 <= TONG_DIEM_BUON_NGU && TONG_DIEM_BUON_NGU <= 8) {
      return `${TONG_DIEM_BUON_NGU} - ${i18n.t(languageKeys.epworth_7_8)}`
    }

    if (9 <= TONG_DIEM_BUON_NGU && TONG_DIEM_BUON_NGU <= 24) {
      return `${TONG_DIEM_BUON_NGU} - ${i18n.t(languageKeys.epworth_9_24)}`
    }

    return ""
  }

  const getStopBang = (DIEM_STOP_BANG) => {
    if (3 <= DIEM_STOP_BANG) {
      return `${DIEM_STOP_BANG} - ${i18n.t(languageKeys.stop_bang_cao)}`
    }

    if (DIEM_STOP_BANG > 0 && DIEM_STOP_BANG < 3) {
      return `${DIEM_STOP_BANG} - ${i18n.t(languageKeys.stop_bang_thap)}`
    }
    return ""

  }

  let ds_ket_qua_xn = convertKetQua(item.ds_dv_cls, "XET_NGHIEM");
  let ds_ket_qua_cdha = convertKetQua(item.ds_dv_cls, "CDHA");

  const render_kham_benh = () => {
    // if (item.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongKhamMat)) {
      if (item?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_MAT)) {
      return (
        <div className={style["table"]}>
          <p className={style["text-bold"]}>1. {i18n.t(languageKeys.do_thi_luc)}: </p>
          <table>
            <tr>
              <th style={{ width: "200px" }}>{i18n.t(languageKeys.thi_luc)}</th>
              <th style={{ width: "200px" }}>{i18n.t(languageKeys.left_eye)}</th>
              <th style={{ width: "200px" }}>{i18n.t(languageKeys.right_eye)}</th>
            </tr>
            <tr>
              <td style={{ width: "200px" }}>{i18n.t(languageKeys.khong_kinh)}</td>
              <td style={{ width: "200px", textAlign: "center" }}>{item?.MAT_TRAI_K_KINH}</td>
              <td style={{ width: "200px", textAlign: "center" }}>{item?.MAT_PHAI_K_KINH}</td>
            </tr>
            <tr>
              <td style={{ width: "200px" }}>{i18n.t(languageKeys.co_kinh)}</td>
              <td style={{ width: "200px", textAlign: "center" }}>{item?.MAT_TRAI_CO_KINH}</td>
              <td style={{ width: "200px", textAlign: "center" }}>{item?.MAT_PHAI_CO_KINH}</td>
            </tr>

          </table>

          <p className={style["text-bold"]}>
            2. {i18n.t(languageKeys.field_chuan_doan_benh_chinh)}: <span>{layStringBenh(item.CHAN_DOAN)}</span>
          </p>

          <p className={style["text-bold"]}>
            3. {i18n.t(languageKeys.field_Ma_benh_kem_theo)}: <span>{layStringBenh(item.MA_BENH_KEM_THEO)}</span>
          </p>
          <p className={style["text-bold"]}>
            4. {i18n.t(languageKeys.chan_doan_xac_dinh)}: <span>{item.CHAN_DOAN_XAC_DINH}</span>
          </p>
          <p className={style["text-bold"]}>
            5. {i18n.t(languageKeys.ket_qua_cls)}: <span>{item?.ket_qua_cls || ""}</span>
            <ul style={{ margin: 0 }}>
              {!!ds_ket_qua_xn.length && (
                <li className={style['ds_ket_qua_cdha']}>
                  {i18n.t(languageKeys.xet_nghiem)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_xn.map((item) => item)}</ul>
                </li>
              )}
              {/* {!!ds_ket_qua_cdha.length && (
                <li>
                  {i18n.t(languageKeys.chan_doan_hinh_anh)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_cdha.map((item) => item)}</ul>
                </li>
              )} */}
              {!!ds_ket_qua_cdha.length && (
              <li className={style['ds_ket_qua_cdha']}>
                {i18n.t(languageKeys.chan_doan_hinh_anh)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_cdha.map((item) => {
                  return  <li style={{display:"flex"}}> * <span style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: item }}/> </li>
                })}</ul>
              </li>
            )}
            </ul>
          </p>
          <p className={style["text-bold"]}>
            6. {i18n.t(languageKeys.xu_tri)}: <span>{item.XU_TRI ? HUONG_XU_TRI[`${item.XU_TRI}`] : ""}</span>
          </p>
          <p className={style["text-bold"]}>
            7. {i18n.t(languageKeys.loi_dan)}: <span>{item.LOI_DAN}</span>
          </p>
          <p className={style["text-bold"]}>
            8. {i18n.t(languageKeys.ngay_tai_kham)}: <span>{item?.TAI_KHAM_NGAY?.length > 0 && convertDateToValue(item?.TAI_KHAM_NGAY)}</span>
          </p>
        </div>
      );
    }
    // if (item.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongTai) || item.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongTMH)) {
      if (item?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_TAI_MUI_HONG)) {
      return (
        <div className={style["table"]}>
          <p className={style["text-bold"]}>1. {i18n.t(languageKeys.hearing_test_results)}: </p>
          <table>
            <tr>
              <th style={{ width: "200px" }}>{i18n.t(languageKeys.thinh_luc)}</th>
              <th style={{ width: "200px" }}>{i18n.t(languageKeys.left_ear)}</th>
              <th style={{ width: "200px" }}>{i18n.t(languageKeys.right_ear)}</th>
            </tr>
            <tr>
              <td style={{ width: "200px" }}>{i18n.t(languageKeys.speak_normally)}</td>
              <td style={{ width: "200px", textAlign: "center" }}>{item?.NOI_THUONG_TRAI}</td>
              <td style={{ width: "200px", textAlign: "center" }}>{item?.NOI_THUONG_PHAI}</td>
            </tr>
            <tr>
              <td style={{ width: "200px" }}>{i18n.t(languageKeys.speak_whisper)}</td>
              <td style={{ width: "200px", textAlign: "center" }}>{item?.NOI_THAM_TRAI}</td>
              <td style={{ width: "200px", textAlign: "center" }}>{item?.NOI_THAM_PHAI}</td>
            </tr>
          </table>

          <p className={style["text-bold"]}>
            2. {i18n.t(languageKeys.field_chuan_doan_benh_chinh)}: <span>{layStringBenh(item.CHAN_DOAN)}</span>
          </p>

          <p className={style["text-bold"]}>
            3. {i18n.t(languageKeys.field_Ma_benh_kem_theo)}: <span>{layStringBenh(item.MA_BENH_KEM_THEO)}</span>
          </p>
          <p className={style["text-bold"]}>
            4. {i18n.t(languageKeys.chan_doan_xac_dinh)}: <span>{item.CHAN_DOAN_XAC_DINH}</span>
          </p>
          <p className={style["text-bold"]}>
            5. {i18n.t(languageKeys.ket_qua_cls)}: <span>{item?.ket_qua_cls || ""}</span>
            <ul style={{ margin: 0 }}>
              {!!ds_ket_qua_xn.length && (
                <li className={style['ds_ket_qua_cdha']}>
                  {i18n.t(languageKeys.xet_nghiem)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_xn.map((item) => item)}</ul>
                </li>
              )}
              {/* {!!ds_ket_qua_cdha.length && (
                <li>
                  {i18n.t(languageKeys.chan_doan_hinh_anh)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_cdha.map((item) => item)}</ul>
                </li>
              )} */}
              {!!ds_ket_qua_cdha.length && (
              <li className={style['ds_ket_qua_cdha']}>
                {i18n.t(languageKeys.chan_doan_hinh_anh)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_cdha.map((item) => {
                  return  <li style={{display:"flex"}}> * <span style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: item }}/> </li>
                })}</ul>
              </li>
            )}
            </ul>
          </p>
          <p className={style["text-bold"]}>
            6. {i18n.t(languageKeys.xu_tri)}: <span>{item.XU_TRI ? HUONG_XU_TRI[`${item.XU_TRI}`] : ""}</span>
          </p>
          <p className={style["text-bold"]}>
            7. {i18n.t(languageKeys.loi_dan)}: <span>{item.LOI_DAN}</span>
          </p>
          <p className={style["text-bold"]}>
            8. {i18n.t(languageKeys.ngay_tai_kham)}: <span>{item?.TAI_KHAM_NGAY?.length > 0 && convertDateToValue(item?.TAI_KHAM_NGAY)}</span>
          </p>
        </div>
      );
    }
    // if (item.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongRHM)) {
      if (item?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_RANG_HAM_MAT)) {
      return (
        <div className={style["table"]}>
          <p className={style["text-bold"]}>1. {i18n.t(languageKeys.damaged_teeth)}:{item?.RANG_TON_THUONG} </p>
          <p className={style["text-bold"]}>
            2. {i18n.t(languageKeys.field_chuan_doan_benh_chinh)}: <span>{layStringBenh(item.CHAN_DOAN)}</span>
          </p>

          <p className={style["text-bold"]}>
            3. {i18n.t(languageKeys.field_Ma_benh_kem_theo)}: <span>{layStringBenh(item.MA_BENH_KEM_THEO)}</span>
          </p>
          <p className={style["text-bold"]}>
            4. {i18n.t(languageKeys.chan_doan_xac_dinh)}:
          </p>

          <p className={style["text-bold"]} style={{ display: "flex", flexDirection: "column", marginLeft: "12px" }}>
            <p className={style["text-bold"]}>{i18n.t(languageKeys.upper_jaw)}: <span>{item?.HAM_TREN_XAC_DINH}</span> </p>
            <p className={style["text-bold"]}>{i18n.t(languageKeys.lower_jaw)}: <span>{item?.HAM_DUOI_XAC_DINH}</span> </p>
          </p>
          <p className={style["text-bold"]}>
            5. {i18n.t(languageKeys.ket_qua_cls)}: <span>{item?.ket_qua_cls || ""}</span>
            <ul style={{ margin: 0 }}>
              {!!ds_ket_qua_xn.length && (
                <li className={style['ds_ket_qua_cdha']}>
                  {i18n.t(languageKeys.xet_nghiem)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_xn.map((item) => item)}</ul>
                </li>
              )}
              {/* {!!ds_ket_qua_cdha.length && (
                <li>
                  {i18n.t(languageKeys.chan_doan_hinh_anh)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_cdha.map((item) => item)}</ul>
                </li>
              )} */}
              {!!ds_ket_qua_cdha.length && (
              <li className={style['ds_ket_qua_cdha']}>
                {i18n.t(languageKeys.chan_doan_hinh_anh)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_cdha.map((item) => {
                  return  <li style={{display:"flex"}}> * <span style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: item }}/> </li>
                })}</ul>
              </li>
            )}
            </ul>
          </p>
          <p className={style["text-bold"]}>
            6. {i18n.t(languageKeys.xu_tri)}: <span>{item.XU_TRI ? HUONG_XU_TRI[`${item.XU_TRI}`] : ""}</span>
          </p>
          <p className={style["text-bold"]}>
            7. {i18n.t(languageKeys.loi_dan)}: <span>{item.LOI_DAN}</span>
          </p>
          <p className={style["text-bold"]}>
            8. {i18n.t(languageKeys.ngay_tai_kham)}: <span>{item?.TAI_KHAM_NGAY?.length > 0 && convertDateToValue(item?.TAI_KHAM_NGAY)}</span>
          </p>
        </div>
      );
    }
    if (item?.KHAM_NOI_GIAC_NGU == 1) {
      return <>
        <p className={style["text-bold"]}>
          1. {i18n.t(languageKeys.trieu_chung_thuc_the)}:
          <br />
          <ul style={{ margin: 0 }}>
            <li>
              {i18n.t(languageKeys.kham_hong)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{item?.KHAM_HONG || ""}</ul>
            </li>
            <li>
              {i18n.t(languageKeys.diem_mallampati)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{getValueMall(item?.DIEM_MALLAMPATI)}</ul>
            </li>
            <li>
              {i18n.t(languageKeys.cac_bo_phan_khac)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{item?.CAC_BO_PHAN_KHAC || ""}</ul>
            </li>
          </ul>
        </p>
        <p className={style["text-bold"]}>2. {i18n.t(languageKeys.thang_diem)}:
          <br />
          <ul style={{ margin: 0 }}>
            <li>
              Epworth: {getTexEp(item?.TONG_DIEM_BUON_NGU) || ""}
            </li>
            <li>
              STOP-BANG: {getStopBang(item?.DIEM_STOP_BANG) || ""}
            </li>
          </ul>
        </p>

        <p className={style["text-bold"]}>
          3. {i18n.t(languageKeys.field_chuan_doan_benh_chinh)}: <span>{layStringBenh(item.CHAN_DOAN)}</span>
        </p>

        <p className={style["text-bold"]}>
          4. {i18n.t(languageKeys.field_Ma_benh_kem_theo)}: <span>{layStringBenh(item.MA_BENH_KEM_THEO)}</span>
        </p>
        <p className={style["text-bold"]}>
          5. {i18n.t(languageKeys.chan_doan_xac_dinh)}: <span>{item.CHAN_DOAN_XAC_DINH}</span>
        </p>
        <p className={style["text-bold"]}>
          6. {i18n.t(languageKeys.ket_qua_cls)}: <span>{item?.ket_qua_cls || ""}</span>
          <ul style={{ margin: 0 }}>
            {!!ds_ket_qua_xn.length && (
              <li className={style['ds_ket_qua_cdha']}>
                {i18n.t(languageKeys.xet_nghiem)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_xn.map((item) => item)}</ul>
              </li>
            )}
            {/* {!!ds_ket_qua_cdha.length && (
              <li>
                {i18n.t(languageKeys.chan_doan_hinh_anh)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_cdha.map((item) => item)}</ul>
              </li>
            )} */}
            {!!ds_ket_qua_cdha.length && (
              <li className={style['ds_ket_qua_cdha']}>
                {i18n.t(languageKeys.chan_doan_hinh_anh)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_cdha.map((item) => {
                  return  <li style={{display:"flex"}}> * <span style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: item }}/> </li>
                })}</ul>
              </li>
            )}
          </ul>
        </p>
        <p className={style["text-bold"]}>
          7. {i18n.t(languageKeys.xu_tri)}: <span>{item.XU_TRI ? HUONG_XU_TRI[`${item.XU_TRI}`] : ""}</span>
        </p>
        <p className={style["text-bold"]}>
          8. {i18n.t(languageKeys.loi_dan)}: <span>{item.LOI_DAN}</span>
        </p>
        <p className={style["text-bold"]}>
          9. {i18n.t(languageKeys.ngay_tai_kham)}: <span>{item?.TAI_KHAM_NGAY?.length > 0 && convertDateToValue(item?.TAI_KHAM_NGAY)}</span>
        </p>
      </>;
    }

    return (
      <>
        <p className={style["text-bold"]}>1. {i18n.t(languageKeys.toan_than)}: </p>
        <p>{item.TOAN_THAN}</p>
        <p className={style["text-bold"]}>2. {i18n.t(languageKeys.cac_bo_phan)}: </p>
        {item.TUAN_HOAN && (
          <p style={styles.margin_left}>
            &bull; {i18n.t(languageKeys.tuan_hoan)}: {item.TUAN_HOAN}
          </p>
        )}
        {item.HO_HAP && (
          <p style={styles.margin_left}>
            &bull; {i18n.t(languageKeys.ho_hap)}: {item.HO_HAP}
          </p>
        )}
        {item.TIEU_HOA && (
          <p style={styles.margin_left}>
            &bull; {i18n.t(languageKeys.tieu_hoa)}: {item.TIEU_HOA}
          </p>
        )}
        {item.THAN_TIET_NIEU && (
          <p style={styles.margin_left}>
            &bull; {i18n.t(languageKeys.than_tiet_nieu)}: {item.THAN_TIET_NIEU}
          </p>
        )}
        {item.NOI_TIET && (
          <p style={styles.margin_left}>
            &bull; {i18n.t(languageKeys.noi_tiet)}: {item.NOI_TIET}
          </p>
        )}
        {item.CO_XUONG_KHOP && (
          <p style={styles.margin_left}>
            &bull; {i18n.t(languageKeys.co_xuong_khop)}: {item.CO_XUONG_KHOP}
          </p>
        )}
        {item.THAN_KINH && (
          <p style={styles.margin_left}>
            &bull; {i18n.t(languageKeys.than_kinh)}: {item.THAN_KINH}
          </p>
        )}
        {item.TAM_THAN && (
          <p style={styles.margin_left}>
            &bull; {i18n.t(languageKeys.mental)}: {item.TAM_THAN}
          </p>
        )}

        <p className={style["text-bold"]}>
          3. {i18n.t(languageKeys.field_chuan_doan_benh_chinh)}: <span>{layStringBenh(item.CHAN_DOAN)}</span>
        </p>

        <p className={style["text-bold"]}>
          4. {i18n.t(languageKeys.field_Ma_benh_kem_theo)}: <span>{layStringBenh(item.MA_BENH_KEM_THEO)}</span>
        </p>
        <p className={style["text-bold"]}>
          5. {i18n.t(languageKeys.chan_doan_xac_dinh)}: <span>{item.CHAN_DOAN_XAC_DINH}</span>
        </p>
        <p className={style["text-bold"]}>
          6. {i18n.t(languageKeys.ket_qua_cls)}: <span>{item?.ket_qua_cls || ""}</span>
          <ul style={{ margin: 0 }}>
            {!!ds_ket_qua_xn.length && (
              <li className={style['ds_ket_qua_cdha']}>
                {i18n.t(languageKeys.xet_nghiem)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_xn.map((item) => item)}</ul>
              </li>
            )}
            {!!ds_ket_qua_cdha.length && (
              <li className={style['ds_ket_qua_cdha']}>
                {i18n.t(languageKeys.chan_doan_hinh_anh)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_cdha.map((item) => {
                  return  <li style={{display:"flex"}}> * <span style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: item }}/> </li>
                })}</ul>
              </li>
            )}
          </ul>
        </p>
        <p className={style["text-bold"]}>
          7. {i18n.t(languageKeys.xu_tri)}: <span>{item.XU_TRI ? HUONG_XU_TRI[`${item.XU_TRI}`] : ""}</span>
          &nbsp;
          {item.XU_TRI === 'LIEU_TRINH' && <Button size="small" type="primary" onClick={openDeXuat}>{i18n.t(languageKeys.lieu_trinh_de_xuat)}</Button>}
        </p>
        <p className={style["text-bold"]}>
          8. {i18n.t(languageKeys.loi_dan)}: <span>{item.LOI_DAN}</span>
        </p>
        <p className={style["text-bold"]}>
          9. {i18n.t(languageKeys.ngay_tai_kham)}: <span>{item?.TAI_KHAM_NGAY?.length > 0 && convertDateToValue(item?.TAI_KHAM_NGAY)}</span>
        </p>
      </>
    );
  };


  const splitStringToArray = (key, value) => {
    return item?.[`${key}`]?.split('\n')?.filter(obj => obj?.trim()?.length !== 0)
  }

  const renderBody = () => {

    if(item?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_DINH_DUONG)) {
      return <Row wrap={false}>
        <Col flex={"auto"} className={style["left-thong-tin"]}>
          <div className={style["title-box"]} style={{ display: "flex", gap: 100 }}>
            <p>I. {i18n.t(languageKeys.kham_benh).toUpperCase()}  </p>
            <p>{i18n.t(languageKeys.field_Dich_vu)}:  {item?.TEN_DICHVU || ""}  </p>
          </div>
          <div className={style["box-info"]}>
            <div>
              <p className={style["text-bold"]}>
                1. {i18n.t(languageKeys.field_ly_do_tu_van_dinh_duong)}:

              </p>
              <p>
                <ul>
                  {
                    splitStringToArray("LY_DO_VAO_VIEN")?.length > 0 ? <>
                      {splitStringToArray("LY_DO_VAO_VIEN")?.map(obj => <li>{obj}</li>)}
                    </> : ""
                  }
                </ul>
              </p>
            </div>
            <div>
              <p className={style["text-bold"]}>2. {i18n.t(languageKeys.field_mot_ngay_an_dien_hinh_hien_nay)}: </p>
              <p>
                <ul>
                  {
                    splitStringToArray("CHE_DO_AN_UONG")?.length > 0 ? <>
                      {splitStringToArray("CHE_DO_AN_UONG")?.map(obj => <li>{obj}</li>)}
                    </> : ""
                  }
                </ul>
              </p>
            </div>

            <div>
              <p className={style["text-bold"]}>3. {i18n.t(languageKeys.field_mot_ngay_tap_va_hoat_dong_dien_hinh_hien_nay)}: </p>
              <p>
                <ul>
                  {
                    splitStringToArray("CHE_DO_LUYEN_TAP")?.length > 0 ? <>
                      {splitStringToArray("CHE_DO_LUYEN_TAP")?.map(obj => <li>{obj}</li>)}
                    </> : ""
                  }
                </ul>
              </p>
            </div>
            <div >
              <p className={style["text-bold"]}>4. {i18n.t(languageKeys.don_dinh_duong)}: </p>
              <p>
                <ul>
                  {
                    splitStringToArray("DON_DINH_DUONG")?.length > 0 ? <>
                      {splitStringToArray("DON_DINH_DUONG")?.map(obj => <li>{obj}</li>)}
                    </> : ""
                  }
                </ul>
              </p>
            </div>
            <div >
              <p className={style["text-bold"]}>5. {i18n.t(languageKeys.don_tap_luyen)}: </p>
              <p>
                <ul>
                  {
                    splitStringToArray("DON_TAP_LUYEN")?.length > 0 ? <>
                      {splitStringToArray("DON_TAP_LUYEN")?.map(obj => <li>{obj}</li>)}
                    </> : ""
                  }
                </ul>
              </p>
            </div>
            <div >
              <p className={style["text-bold"]}>6. {i18n.t(languageKeys.luu_y_chung)}: </p>
              <p>
                <ul>
                  {
                    splitStringToArray("LOI_DAN")?.length > 0 ? <>
                      {splitStringToArray("LOI_DAN")?.map(obj => <li>{obj}</li>)}
                    </> : ""
                  }
                </ul>
              </p>
            </div>
            <div >
              <p className={style["text-bold"]}>7. {i18n.t(languageKeys.muc_do_cam_ket_cua_khach_hang)}: {item?.MUC_DO_CAM_KET || " "}</p>
            </div>
            <div >
              <p className={style["text-bold"]}>8. {i18n.t(languageKeys.field_ngay_tai_kham)}: <span>{item?.TAI_KHAM_NGAY?.length > 0 && convertDateToValue(item?.TAI_KHAM_NGAY)}</span></p>
            </div>
          </div>

        </Col>
        <Col flex={"200px"}>
          <div className={style["title-box"]}>
            <p>{i18n.t(languageKeys.field_Chi_so_sinh_ton)}</p>
          </div>
          <div style={{ paddingLeft: 15, paddingTop: 10 }}>
            <p style={styles.chi_so}>
              {i18n.t(languageKeys.field_Mach)} {i18n.t(languageKeys.field_Lan_phut)}: <span className={style["text-bold"]}>{item.CHI_SO_SINH_TON.MACH}</span>{" "}
            </p>
            <p style={styles.chi_so}>
              {i18n.t(languageKeys.field_Nhiet_do)} {i18n.t(languageKeys.field_Do_C)}: <span className={style["text-bold"]}>{item.CHI_SO_SINH_TON.NHIET_DO}</span>{" "}
            </p>
            <p style={styles.chi_so}>
              {i18n.t(languageKeys.field_Huyet_ap)} (mmHg):{" "}
              <span className={style["text-bold"]}>
                {item.CHI_SO_SINH_TON.TAM_TRUONG ? `${item.CHI_SO_SINH_TON.TAM_THU}/${item.CHI_SO_SINH_TON.TAM_TRUONG}` : ""}
              </span>{" "}
            </p>
            <p style={styles.chi_so}>
              {i18n.t(languageKeys.field_Nhip_tho)} {i18n.t(languageKeys.field_Lan_phut)}: <span className={style["text-bold"]}>{item.CHI_SO_SINH_TON.NHIP_THO}</span>{" "}
            </p>
            <p style={styles.chi_so}>
              {i18n.t(languageKeys.can_nang)} (kg): <span className={style["text-bold"]}>{item.CHI_SO_SINH_TON.CAN_NANG}</span>
            </p>
            <p style={styles.chi_so}>
              {i18n.t(languageKeys.chieu_cao)} (cm): <span className={style["text-bold"]}>{item.CHI_SO_SINH_TON.CHIEU_CAO}</span>
            </p>
            <p style={styles.chi_so}>
              {i18n.t(languageKeys.bmi)}: <span className={style["text-bold"]}>{item.BMI}</span>
            </p>
            <p style={styles.chi_so}>
              {i18n.t(languageKeys.vong_co)}: <span className={style["text-bold"]}>{item.VONG_CO}</span>
            </p>
            {
              [dataPartnerCode.dth].includes(userProfile.partner_code) ? (
                <>
                  <p style={styles.chi_so}>
                  SpO2: <span className={style["text-bold"]}>{item?.SP_O2 || ""}</span>
                  </p>
                  <p style={styles.chi_so}>
                    {i18n.t(languageKeys.vong_bung_qua_eo)}: <span className={style["text-bold"]}>{item.VONG_BUNG?.split(",")?.[0]}</span>
                  </p>
                  <p style={styles.chi_so}>
                    {i18n.t(languageKeys.vong_bung_qua_ron)}: <span className={style["text-bold"]}>{item.VONG_BUNG?.split(",")?.[1]}</span>
                  </p>
                  <p style={styles.chi_so}>
                    {i18n.t(languageKeys.vong_bung_qua_gai_chau_truoc_tren)}: <span className={style["text-bold"]}>{item.VONG_BUNG?.split(",")?.[2]}</span>
                  </p>
                </>
              ) : (
                <>
                  <p style={styles.chi_so}>
                    {i18n.t(languageKeys.vong_bung)}: <span className={style["text-bold"]}>{item.VONG_BUNG}</span>
                  </p>
                </>
              )
            }
          </div>
        </Col>
      </Row>

    }

    return <Row wrap={false}>
      <Col flex={"auto"} className={style["left-thong-tin"]}>
        <div className={style["title-box"]}  style={{display:"flex", gap:100}}>
          <p>I. {i18n.t(languageKeys.thong_tin_kham_nhanh).toUpperCase()}</p>
          <p>{i18n.t(languageKeys.field_Dich_vu)}:  {item?.TEN_DICHVU || ""}  </p>
        </div>
        <div className={style["box-info"]}>
          <p className={style["text-bold"]}>
            1. {i18n.t(languageKeys.field_Ly_do_kham)}:{" "} <span>{item.LY_DO_VAO_VIEN}</span>
          </p>
          <div style={{ display: "flex" }}>
            <p className={style["text-bold"]}>2. {i18n.t(languageKeys.field_Qua_trinh_benh_ly)}: </p>{" "}
            <p>{item.QUA_TRINH_BENH_LY}</p>
          </div>

          <p className={style["text-bold"]}>3. {i18n.t(languageKeys.tien_su)}: </p>
          {getiItemList("TIEN_SU_BENH", i18n.t(languageKeys.tien_sua_benh_ban_than))}
          {getiItemList("TIEN_SU_SAN_KHOA", i18n.t(languageKeys.tien_su_san_khoa))}
          {getiItemList("TIEN_SU_DI_UNG", i18n.t(languageKeys.tien_su_di_ung))}
          {getiItemList("TAI_NAN_THUONG_TICH", i18n.t(languageKeys.tai_nan_thuong_tich))}
          {getiItemList("TIEN_SU_GIA_DINH", i18n.t(languageKeys.tien_su_benh_gia_dinh))}
          {getiItemList("TIEN_SU_BENH_KHAC", i18n.t(languageKeys.tien_su_benh_khac))}
        </div>

        <div className={style["title-box"]} style={{ borderTop: "1px solid #EBEBEB", marginTop: 10 }}>
          <p>II. {i18n.t(languageKeys.kham_benh).toUpperCase()}</p>
        </div>
        <div className={style["box-info"]}>{render_kham_benh()}</div>
      </Col>
      <Col flex={"200px"}>
        <div className={style["title-box"]}>
          <p>{i18n.t(languageKeys.field_Chi_so_sinh_ton)}</p>
        </div>
        <div style={{ paddingLeft: 15, paddingTop: 10 }}>
          <p style={styles.chi_so}>
            {i18n.t(languageKeys.field_Mach)} {i18n.t(languageKeys.field_Lan_phut)}: <span className={style["text-bold"]}>{item.CHI_SO_SINH_TON.MACH}</span>{" "}
          </p>
          <p style={styles.chi_so}>
            {i18n.t(languageKeys.field_Nhiet_do)} {i18n.t(languageKeys.field_Do_C)}: <span className={style["text-bold"]}>{item.CHI_SO_SINH_TON.NHIET_DO}</span>{" "}
          </p>
          <p style={styles.chi_so}>
            {i18n.t(languageKeys.field_Huyet_ap)} (mmHg):{" "}
            <span className={style["text-bold"]}>
              {item.CHI_SO_SINH_TON.TAM_TRUONG ? `${item.CHI_SO_SINH_TON.TAM_THU}/${item.CHI_SO_SINH_TON.TAM_TRUONG}` : ""}
            </span>{" "}
          </p>
          <p style={styles.chi_so}>
            {i18n.t(languageKeys.field_Nhip_tho)} {i18n.t(languageKeys.field_Lan_phut)}: <span className={style["text-bold"]}>{item.CHI_SO_SINH_TON.NHIP_THO}</span>{" "}
          </p>
          <p style={styles.chi_so}>
            {i18n.t(languageKeys.can_nang)} (kg): <span className={style["text-bold"]}>{item.CHI_SO_SINH_TON.CAN_NANG}</span>
          </p>
          <p style={styles.chi_so}>
            {i18n.t(languageKeys.chieu_cao)} (cm): <span className={style["text-bold"]}>{item.CHI_SO_SINH_TON.CHIEU_CAO}</span>
          </p>
          <p style={styles.chi_so}>
            {i18n.t(languageKeys.bmi)}: <span className={style["text-bold"]}>{item.BMI}</span>
          </p>
          <p style={styles.chi_so}>
            {i18n.t(languageKeys.vong_co)}: <span className={style["text-bold"]}>{item.VONG_CO}</span>
          </p>
          <p style={styles.chi_so}>
            SpO2: <span className={style["text-bold"]}>{item?.SP_O2 || ""}</span>
          </p>

          {
            [dataPartnerCode.dth].includes(userProfile.partner_code) ? (
              <>
                <p style={styles.chi_so}>
                  {i18n.t(languageKeys.vong_bung_qua_eo)}: <span className={style["text-bold"]}>{item.VONG_BUNG?.split(",")?.[0]}</span>
                </p>
                <p style={styles.chi_so}>
                  {i18n.t(languageKeys.vong_bung_qua_ron)}: <span className={style["text-bold"]}>{item.VONG_BUNG?.split(",")?.[1]}</span>
                </p>
                <p style={styles.chi_so}>
                  {i18n.t(languageKeys.vong_bung_qua_gai_chau_truoc_tren)}: <span className={style["text-bold"]}>{item.VONG_BUNG?.split(",")?.[2]}</span>
                </p>
              </>
            ) : (
              <>
                <p style={styles.chi_so}>
                  {i18n.t(languageKeys.vong_bung)}: <span className={style["text-bold"]}>{item.VONG_BUNG}</span>
                </p>
              </>
            )
          }

        </div>
      </Col>
    </Row>

  }

  return (
    <div className={style["container"]}>
      <Row className={style["date"]} gutter={10}>
        <Col span={5}>
          <p>
            {i18n.t(languageKeys.field_Ngay_kham)}: {item.NGAY_TIEP_DON && moment(item.NGAY_TIEP_DON, "YYYYMMDD").format("DD/MM/YYYY")}
          </p>
        </Col>
        <Col span={7}>
          <p>
            {i18n.t(languageKeys.field_Noi_kham)}: {item?.TEN_CSKCB}
          </p>
        </Col>
        <Col span={12}>
          <p>
            {i18n.t(languageKeys.field_Dia_chi)}: {item?.DIA_CHI_CSKCB}
          </p>
        </Col>
      </Row>
      {renderBody()}
    </div>
  );
};
export default React.memo(ItemThongTinKham);

const layStringBenh = (arr) => {
  let mang_benh = [];
  for (let i = 0; i < arr.length; i++) {
    let string = arr[i].MA_ICD ? `${arr[i].MA_ICD} - ${arr[i].TEN_ICD}` : `${arr[i].TEN_ICD}`;
    mang_benh.push(string);
  }
  if (mang_benh.length > 0) {
    return mang_benh.join(" ; ");
  } else {
    return "";
  }
};

const styles = {
  chi_so: {
    marginTop: 10,
  },
  margin_left: {
    marginLeft: 10,
  },
  ds_ket_qua_cdha: {
    p: {
      
    }
  }
};
