const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
    debtList: [],
    patientInfo: {},
    unpaidDebtList: {},
    paidDebtList: [],
    refundServiceList: [],
    advanceMoney: {},
    receiptList: [],
    next_page: null,
}

const debtManagementReducer = createSlice({
    name: 'DebtManagementReducer',
    initialState,
    reducers: {
        getDebtList: () => {},
        getDebtListSuccess: (state, { payload }) => {
            state.debtList = payload.result
            state.next_page = payload.next_page
            // state.patientInfo = {
            //     ID: payload[0]?.BENH_NHAN_ID || '',
            //     billId: payload[0]?.HOA_DON_ID || '',
            //     examinationCode: payload[0]?.MA_CUOC_KHAM || '',
            //     LICH_KHAM_ID: payload[0]?.LICH_KHAM_ID || '',
            // }
        },
        updatePatientInfo: (state, { payload }) => {
            state.patientInfo = payload
        },
        getUnpaidDebt: () => {},
        getUnpaidDebtSuccess: (state, { payload }) => {
            state.unpaidDebtList = payload
        },
        getPaidDebt: () => {},
        getPaidDebtSuccess: (state, { payload }) => {
            state.paidDebtList = payload
        },
        getRefundServiceList: () => {},
        getRefundServiceListSuccess: (state, { payload }) => {
            state.refundServiceList = payload
        },
        getAdvanceMoney: () => {},
        getAdvanceMoneySuccess: (state, { payload }) => {
            state.advanceMoney = payload
        },
        payDebt: () => {},
        refundMoney: () => {},
        payDebtAndPrint: () => {},
        refundMoneyAndPrint: () => {},
        getListReceipts: () => {},
        getListReceiptsSuccess: (state, { payload }) => {
            state.receiptList = payload
        },
        cancelPaidDebt: () => {},
        resetDebtSlice: (state) => {
            return initialState;
        }
    }
})

export const {
    getDebtList, getDebtListSuccess, updatePatientInfo, getUnpaidDebt, getUnpaidDebtSuccess, getPaidDebt,
    getPaidDebtSuccess, payDebt, getRefundServiceList, getRefundServiceListSuccess, getAdvanceMoney, getAdvanceMoneySuccess,
    refundMoney, payDebtAndPrint, refundMoneyAndPrint, getListReceipts, getListReceiptsSuccess, cancelPaidDebt, resetDebtSlice
} = debtManagementReducer.actions
export const debtManagementState = (state) => state.hospitalFeeManagement.DebtManagement

export default debtManagementReducer.reducer