import React, { useState, useImperativeHandle, useRef } from "react";
import { Col, Row, Button, Empty } from "antd";
import style from "./styleChiTiet.module.less";
import i18n, { languageKeys } from "../../i18n";
import cn from "classnames";
import ItemThongTinKham from "./components/ItemThongTinKham";
import ItemKetQuaCLS from "./components/ItemKetQuaCLS";
import ItemDonThuoc from "./components/ItemDonThuoc";
import { HLog, rid } from "../../helpers";
import ModalPDF from "../Modal/ModalPDF";
import { useSelector } from "react-redux";
import { apis } from "../../constants";
import KetQuaXN from "pages/QuanLyKhamBenh/views/DsDichVuCLS/KetQuaXn/KetQuaXN";
import _ from "lodash";
import DvDaTraKQ from "pages/RisNhapTay/TraKetQuaCdha/components/DvDaTraKQ";
import LieuTrinhDeXuat from "pages/QuanLyGoiDichVu/GoiLieuTrinh/LieuTrinhDeXuat/LieuTrinhDeXuat";

const keyTabs = {
  THONG_TIN_KHAM: "THONG_TIN_KHAM",
  DON_THUOC: "DON_THUOC",
  KET_QUA_CLS: "KET_QUA_CLS",
};

const ChiTietLichSuKham = React.forwardRef(
  (
    {
      lichKham,
      patientInfo = {},
      fromTiepDon = false,
      setIsModalActive,
      dataKhamBenh = [],
      dataDonThuoc = [],
      dataKetquaCls = [],
      pageDonThuoc,
      pageKhamBenh,
      pageKqCls,
      onLoadMoreKhamBenh = () => {},
      onLoadMoreDonThuoc = () => {},
      onLoadMoreCls = () => {},
      loading,
    },
    ref
  ) => {

    const pdfRef = useRef();
    const kqXnRef = useRef();
    const kqCdRef = useRef();
    const suggestRef = useRef();

    const [tabActive, setTabActive] = useState(keyTabs.THONG_TIN_KHAM);
    useImperativeHandle(ref, () => ({
      reset: handleReset,
    }));

    const userProfile = useSelector((state) => state.auth.user);

    const handlePrint = async (DON_THUOC_ID) => {
      try {
        let dataRequest = {
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          DON_THUOC_ID,
        };
        const api = apis.in_don_thuoc_v2;
        pdfRef.current.openModal(dataRequest, api);
      } catch (error) {
        HLog("loi in don thuoc chitietlichsukham ::", error);
      }
    };
    const handleReset = () => {
      setTabActive(keyTabs.THONG_TIN_KHAM);
    };

    const RenderEmpty = () => {
      return (
        <div style={{ display: "flex", height: "100%" }}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: "auto" }} description={<span>{i18n.t(languageKeys.chua_co_lich_su)}</span>} />
        </div>
      );
    };

    const handleOpenKq = async (type, data) => {
      try {

        let selectedPatient = {};
        // if(_.isObject(data)) {
        //   selectedPatient = {...data};
        // }
        if (_.isObject(patientInfo)) {
          selectedPatient = { ...selectedPatient, ...patientInfo };
        }

        // return
        try {
          if (type === "THU_THUAT") {
            if (userProfile?.config?.LOAI_FORM_THU_THUAT) {
              pdfRef.current.openModal(
                {
                  PHIEU_ID: data?.PHIEU_IN_CLS_ID,
                  LUOT_DIEU_TRI_ID: data?.LUOT_DIEU_TRI_ID,
                  TRANG_THAI: "DA_TRA_KET_QUA",
                  PHONG_ID: data?.PHONG_ID,
                  PHONG_DIEU_TRI_ID: data?.PDT_ID,
                  LICH_KHAM_ID: dataKhamBenh?.[0]?.LICH_KHAM_ID,
                  DICH_VU_ID: data?.DICH_VU_ID,
                },
                apis.layDataPhieuTheoDoiTruyenDich
              )
            } else {
              const payload = {
                ...data,
                patientInfo: {
                  ...selectedPatient,
                },
              };
              await pdfRef.current.openModalWithData(payload, "tuongTrinhThuThuat");
            }
          } else if (type === "XET_NGHIEM") {
            kqXnRef.current.onpenModal({ ...data, }, selectedPatient);
          } else if (type === "CDHA") {

            kqCdRef.current.open({ ...data, TRANG_THAI_KQ: data.TRANG_THAI_KET_QUA });
          }
        } catch (error) {

        }


      } catch (error) {
        HLog(error);
      }
    }


    return (
      <div className={cn(style["container"], fromTiepDon && style["container-tiepdon"], style['style-scroll-bar'])}>
        <Row style={{ marginLeft: 10, paddingBottom: 10 }}>
          <Button
            className={tabActive === keyTabs.THONG_TIN_KHAM ? style["button-active"] : style["button"]}
            onClick={() => setTabActive(keyTabs.THONG_TIN_KHAM)}
          >
            {i18n.t(languageKeys.thong_tin_ck)}
          </Button>
          <Button
            className={tabActive === keyTabs.DON_THUOC ? style["button-active"] : style["button"]}
            onClick={() => setTabActive(keyTabs.DON_THUOC)}
          >
            {i18n.t(languageKeys.don_thuoc_cu)}
          </Button>
          <Button
            className={tabActive === keyTabs.KET_QUA_CLS ? style["button-active"] : style["button"]}
            onClick={() => setTabActive(keyTabs.KET_QUA_CLS)}
          >
            {i18n.t(languageKeys.ket_qua_cls)}
          </Button>
        </Row>

        {/*<List data={dataKhamBenh}>*/}
        {/*    {item => <ItemThongTinKham key = {rid(8)} item={item}/>}*/}
        {/*</List>*/}
        {tabActive === keyTabs.THONG_TIN_KHAM && (
          <div className={cn(style["content-active"], fromTiepDon && style["content-tiepdon"])}>
            {dataKhamBenh.length > 0 && (
              <div>
                {dataKhamBenh.map((item, index) => (
                  <ItemThongTinKham
                    key={rid(8)}
                    item={item}
                    openDeXuat={() => suggestRef.current?.open({ ...patientInfo, BAC_SI_ID: item.BAC_SI_ID })}
                  />
                ))}
              </div>
            )}

            {dataKhamBenh.length === 0 && <RenderEmpty />}

            {pageKhamBenh && pageKhamBenh.currentPage < pageKhamBenh.totalPage && (
              <Row align="center" style={{ marginTop: 10, marginBottom: 5 }}>
                <Button type="primary" onClick={() => onLoadMoreKhamBenh()} loading={loading.loadingKB}>
                  {i18n.t(languageKeys.btn_xem_them)}
                </Button>
              </Row>
            )}
          </div>
        )}

        {tabActive === keyTabs.DON_THUOC && (
          <div className={style["content-active"]}>
            {dataDonThuoc.length > 0 ? (
              dataDonThuoc.map((item, index) => <ItemDonThuoc key={rid(8)} item={item} onPrint={handlePrint} />)
            ) : (
              <RenderEmpty />
            )}
            {pageDonThuoc && pageDonThuoc.currentPage < pageDonThuoc.totalPage && (
              <Row align="center" style={{ marginTop: 10, marginBottom: 5 }}>
                <Button type="primary" onClick={onLoadMoreDonThuoc} loading={loading.loadingDT}>
                  {i18n.t(languageKeys.btn_xem_them)}
                </Button>
              </Row>
            )}
          </div>
        )}

        {tabActive === keyTabs.KET_QUA_CLS && (
          <div className={style["content-active"]}>
            {dataKetquaCls.length > 0 ? dataKetquaCls.map((item, index) => <ItemKetQuaCLS onOpenKq={handleOpenKq} key={rid(8)} item={item} />) : <RenderEmpty />}
            {pageKqCls && pageKqCls.currentPage < pageKqCls.totalPage && (
              <Row align="center" style={{ marginTop: 10, marginBottom: 5 }}>
                <Button type="primary" onClick={onLoadMoreCls} loading={loading.loadingCLS}>
                  {i18n.t(languageKeys.btn_xem_them)}
                </Button>
              </Row>
            )}
          </div>
        )}

        <KetQuaXN ref={kqXnRef} />

        <DvDaTraKQ ref={kqCdRef} patientInfo={patientInfo} footerAction={false} hideTopbar />

        <ModalPDF ref={pdfRef} />

        <LieuTrinhDeXuat ref={suggestRef} readOnly />
      </div>
    );
  }
);

export default React.memo(ChiTietLichSuKham);
