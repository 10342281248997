import React from "react";

export const styleInMaQR = `
@media print {
  #in-ma-qr img {
    page-break-inside: avoid;
    width: 100%;
    max-height: 100%;
    height: auto;
  }
}
`;

const InMaQR = ({ data }) => {
  return (
    <div id="in-ma-qr">
      <img
        src={`data:image/png;base64, ${data.QR}`}
        // src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
        alt="QR"
      />
    </div>
  )
}

export default InMaQR;