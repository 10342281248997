import { Button, Tooltip } from "antd";
import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { useSelector } from "react-redux";
import { keys } from "../../constants";
import { convertDateToValue, HLog, rid } from "../../helpers";
import i18n, { languageKeys } from "../../i18n";
import { layDsLichSuKham } from "../../pages/QuanLyKhamBenh/apisKhamBenh/apisKhamBenh";
import Table from "../Table/Table";

import LichSuKhamDrawer from "./LichSuKhamDrawer/LichSuKhamDrawer";
import style from "./style.module.less";

const LIMIT = 15;

const LichSuKhamBenhComponent = React.forwardRef((props, ref) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [dsLichSuKB, setDsLichSuKb] = useState([]); // danh sach lịch sử khám bệnh
  const [totalResult, setTotalResult] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingView, setLoadingView] = useState(false);
  const [viewDetail, setViewDetail] = useState(false); // true => màn chi tiết cuộc hẹn || fasle => màn danh sách lịch sửa khám bệnh
  const [selectedAppointment, setSelectedAppointment] = useState(null); // cuộc hẹn đã được chọn
  const [visibleLichSu, setvisibleLichSu] = useState(false);
  const [visibleDetailLuotDieuTri, setVisibleDetailLuotDieuTri] = useState(false)
  const editRef = useRef();
  const ellipsisProps = {
    ellipsis: true,
    render: (data) => {
      return (
        <Tooltip placement="topLeft" title={data} zIndex={1}>
          {data === "null" || data === "undefined" || !data ? "" : data}
        </Tooltip>
      );
    },
  };

  useEffect(() => {
    if (props.BENH_NHAN_ID && props.BENH_NHAN_ID !== "" && !!props.isVisible) {
      layDsLichSuKB();
    } else {
      if (props.isVisible && dsLichSuKB.length > 0) {
        setDsLichSuKb([]);
        setCurrentPage(1);
        setTotalResult(0);
        setTotalPage(1);
        setSelectedAppointment(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.BENH_NHAN_ID, props.isVisible]);

  useEffect(() => {
    props.lichSuDrawerVisible && props.lichSuDrawerVisible(visibleLichSu);
  }, [visibleLichSu]);
  // console.log(props, "ok5");

  useEffect(() => {
    if (selectedAppointment) {
      setViewDetail(true);
    } else {
      setViewDetail(false);
    }
  }, [selectedAppointment]);

  useEffect(() => {
    setViewDetail(false);
  }, [props.BENH_NHAN_ID]);

  useImperativeHandle(ref, () => ({
    resetData() {
      setSelectedAppointment();
    },
    visibleDetailLuotDieuTri
  }));

  async function layDsLichSuKB(page = 1, isLoadMore = false) {
    if (page > totalPage && isLoadMore) return;

    setLoadingView(true);

    let dataRequest = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      BENH_NHAN_ID: props.BENH_NHAN_ID,
      limit: LIMIT,
      page,
    };

    const response = await layDsLichSuKham(dataRequest);

    if (response) {
        setDsLichSuKb(
          response.result.map((item) => ({
            ...item,
            key: rid(),
          }))
        );
      setTotalPage(response.count_page);
      setTotalResult(response.total);
      setCurrentPage(response.current_page);
    }

    setLoadingView(false);
  }

  const sharedOnCell = (record) => {
    if (record.NGAY === "BTN") {
      return {
        colSpan: 0,
      };
    }

    return {};
  };

  const columns = [
    {
      title: i18n.t(languageKeys.field_Ngay_bat_dau),
      key: "NGAY",
      dataIndex: "NGAY",
      width: "110px",
      render: (NGAY, record) =>  convertDateToValue(NGAY)
    },
    {
      title: i18n.t(languageKeys.field_Ngay_ket_thuc),
      key: "NGAY_HOAN_THANH",
      dataIndex: "NGAY_HOAN_THANH",
      width: "110px",
      render: (NGAY_HOAN_THANH, record) => convertDateToValue(NGAY_HOAN_THANH),
      onCell: sharedOnCell,
    },
    {
      title: i18n.t(languageKeys.field_Chan_doan_benh_chinh),
      key: "BENH_CHINH",
      dataIndex: "BENH_CHINH",
      onCell: sharedOnCell,
      ...ellipsisProps,
    },
    {
      title: i18n.t(languageKeys.field_Chan_doan_benh_phu_kem_theo),
      key: "BENH_PHU",
      dataIndex: "BENH_PHU",
      ...ellipsisProps,
      render: (data) => data?.map((item) => item?.TEN_ICD || "").join(","),
      onCell: sharedOnCell,
    },
    {
      title: i18n.t(languageKeys.field_Chan_doan_xac_dinh),
      key: "CHAN_DOAN_XAC_DINH",
      dataIndex: "CHAN_DOAN_XAC_DINH",
      onCell: sharedOnCell,
      ...ellipsisProps,
    },
    {
      title: i18n.t(languageKeys.field_Bac_si),
      key: "BAC_SI",
      dataIndex: "BAC_SI",
      width: "150px",
      onCell: sharedOnCell,
    },
    {
      title: i18n.t(languageKeys.field_Phong_thuc_hien),
      key: "PHONG_CHI_DINH",
      dataIndex: "PHONG_CHI_DINH",
      width: "150px",
      onCell: sharedOnCell,
    },
    {
      title: "",
      key: "ACTION",
      dataIndex: "ACTION",
      width: "105px",
      fixed: "right",
      render: (item) => (
        <div>
          <Button className={style["detailBtn"]} type={"primary"}>
            {i18n.t(languageKeys.chi_tiet)}
          </Button>
        </div>
      ),
      onCell: sharedOnCell,
    },
  ];

  const onOpenEdit = (data) => {
    editRef.current.openModal(data);
  };


  if (!viewDetail) {
    return (
      <div style={{ backgroundColor: "#F5F7FB", padding : 10, height : "100%" }}>

        <LichSuKhamDrawer
          ref={editRef}
          lichSuDrawerVisible={setvisibleLichSu}
          patientInfo={props.patientInfo}
          onChangeVisibleDetail = {setVisibleDetailLuotDieuTri}
        />

        <Table
          dataSource={dsLichSuKB}
          className={style["table-container"]}
          columns={columns}
          loading={loadingView}
          onClickRow={(data) => onOpenEdit(data)}
          limit={LIMIT}
          // scroll={{ y: "calc(100vh - 250px)" }}
          showPagination={true}
          currentPage={currentPage}
          totalResult={totalResult}
          onNext={() => layDsLichSuKB(currentPage + 1)}
          onPrev={() => layDsLichSuKB(currentPage - 1)}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
});

export default React.memo(LichSuKhamBenhComponent);
