const apiServices = {
  enable_app_log: window.NODE_ENV, //ẩn, hiện log

  H247_BS_HIS: "https://cis-api.dev.deepcare.vn/", // api môi trường dev CIS
  H247_EVENT_SOURCE: window.REACT_APP_H247_BROADCAST_SERVICE, //api eventsource mt dev

  // GATEWAY
  GATEWAY_LIS: window.GATEWAY + "lis/",
  GATEWAY_CIS: window.GATEWAY + "cis/",
  GATEWAY_BROADCAST: window.GATEWAY + "broadcast/",
  GATEWAY_CIS_IMAGE: window.GATEWAY + "cis-public/",

  // CLIENT
  URL_CIS: window.CIS_WEB_UI_URL,
  URL_LIS: window.LIS_WEB_UI_URL,
  URL_DANH_MUC: window.DM_WEB_UI_URL,
  URL_REPORT: window.RP_WEB_UI_URL,
  URL_PACS: window.PACS_WEB_UI_URL,
  URL_PHR: window.PHR_WEB_UI_URL,
  URL_SUPPLIES: window.SUPPLIES_WEB_UI_URL,

  //KEYCLOAK
  KEYCLOAK_CLIENT_ID: window.KEYCLOAK_CLIENT_ID,
  KEYCLOAK_URL: window.KEYCLOAK_URL,
  KEYCLOAK_REALM: window.KEYCLOAK_REALM,


  //vin chat ai
  vin_scope:"https://vinbrain.onmicrosoft.com/api/.default",
  vin_grant_type:"client_credentials",
  vin_client_id:"75bc74e1-22c3-476e-b36e-21b23ce0fde2",
  vin_client_secret:"cxtq5pvXEc-Uz~WlBMrNB2ZC8EBx6zad6e",
  CONG_DUOC_DEV : "http://beta.donthuocquocgia.vn",
  CONG_DUOC_PROD: "https://donthuocquocgia.vn"
};

export default apiServices;
