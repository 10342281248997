import moment from "moment";
import React from "react";
import { layDiaChi, getSexFromString, getWeekdays, convertDateToValue } from "../../../helpers";
import { useSelector } from "react-redux";
import i18n, { languageKeys, languages } from "../../../i18n";
import { dataPartnerCode, enDateFormat, HUONG_XU_TRI } from "../../../constants/keys";
import { OnCareQR } from "assets/img";
import Images from "components/Images/Images";
import { keyFormKham } from "constants/data";

export const stylePhieuKhamBenh = `
#phieu-kham-benh * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }

#phieu-kham-benh .column {
  float: left;
  width: 33.33%;
}

.benh_vien-name {
  font-size: 10px;
}

#phieu-kham-benh .row:after {
  content: "";
  display: table;
  clear: both;
}

#phieu-kham-benh .container-row {
  margin-top: 8px;
}

#phieu-kham-benh .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#phieu-kham-benh .header { display: flex; gap: 10px; }
#phieu-kham-benh .flex {
    display: flex;
  }
#phieu-kham-benh .flex.justify {
    justify-content: space-around;
  }

#phieu-kham-benh .text-bold {
    font-size: 12px;
    font-weight: bold !important;
  }

  #phieu-kham-benh .table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
  }

  #phieu-kham-benh td, #phieu-kham-benh th {
    border: 1px solid #000;
    // text-align: center;
    padding: 6px;
    // color: #2c3782;
  }

  #phieu-kham-benh .table th:first-child {
    border: 1px solid #000;
    text-align: left;
    padding: 6px;
  }

#phieu-kham-benh .tel {
    margin-right: 50px;
  }
#phieu-kham-benh .txt-700 {
    font-weight: 700;
  }
#phieu-kham-benh .container-title {
  text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2rem auto 1rem auto;
  }
#phieu-kham-benh .container__title--main {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
#phieu-kham-benh .container__ttbn--wrapper {
  margin-top: 6px;
}
#phieu-kham-benh .container__ttbn--wrapper>div {
    margin: 4px 0 0 0;
  }
#phieu-kham-benh .container-wrapper-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  #phieu-kham-benh .container-wrapper-flex .col-3{
    width: 30%;
  }

  #phieu-kham-benh .container-wrapper-flex .col-4{
    width: 40%;
  }

  #phieu-kham-benh .thongtin-khambenh {
    width: 100%;
    display: flex;
    margin-top: 12px;
  }

  #phieu-kham-benh .thongtin-khambenh .left {
    width: 65%;
    display: flex;
    flex-direction: column;
  }

  #phieu-kham-benh .thongtin-khambenh .right {
    width: 35%;
  }

  #phieu-kham-benh .table-border {
    padding: 6px 0;
    border: 1px solid #000;
  }

  #phieu-kham-benh .table-item{
    padding-left:12px;
  }

  #phieu-kham-benh hr.solid {
    border-top: 1px solid #000;
  }

  #phieu-kham-benh .footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 40px;
  }

  #phieu-kham-benh .footer .footer-confirm {
    margin-right: 44px;
    text-align: center;
  }

  #phieu-kham-benh .name-space {
    height: 80px;
  }

  #phieu-kham-benh .txt-size-m{
    font-size: 12px;
  }

  #phieu-kham-benh .upperTxt{
    text-transform: uppercase;
  }

  #phieu-kham-benh .phone-website { 
    display: flex; gap: 24px; align-items: center 
  }

@media print {
#phieu-kham-benh .foot-break { break-inside: avoid; }
}
`;
export const stylePhieuKhamBenhGL = `
#phieu-kham-benh * { font-size: 14pt;font-family: "Times New Roman", Times, serif; }

#phieu-kham-benh .column {
  float: left;
  width: 33.33%;
}


.benh_vien-name {
  font-size: 16pt;
}

#phieu-kham-benh .row:after {
  content: "";
  display: table;
  clear: both;
}

#phieu-kham-benh .container-row {
  margin-top: 8px;
}

#phieu-kham-benh .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#phieu-kham-benh .header { display: flex; gap: 10px; }
#phieu-kham-benh .flex {
    display: flex;
  }
#phieu-kham-benh .flex.justify {
    justify-content: space-around;
  }

#phieu-kham-benh .text-bold {
    font-size: 14pt;
    font-weight: bold !important;
  }

  #phieu-kham-benh .table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
  }

  #phieu-kham-benh td, #phieu-kham-benh th {
    border: 1px solid #000;
    // text-align: center;
    padding: 6px;
    // color: #2c3782;
  }

  #phieu-kham-benh .table th:first-child {
    border: 1px solid #000;
    text-align: left;
    padding: 6px;
  }

#phieu-kham-benh .tel {
    margin-right: 50px;
  }
#phieu-kham-benh .txt-700 {
    font-weight: 700;
  }
#phieu-kham-benh .container-title {
  text-transform: uppercase; font-size: 16pt; font-weight: bold; text-align: center; width: 90%; margin: 2rem auto 1rem auto;
  }
#phieu-kham-benh .container__title--main {
    font-weight: 700;
    font-size: 14pt;
    line-height: 16pt;
  }
#phieu-kham-benh .container__ttbn--wrapper {
  margin-top: 6px;
}
#phieu-kham-benh .container__ttbn--wrapper>div {
    margin: 4px 0 0 0;
  }
#phieu-kham-benh .container-wrapper-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  #phieu-kham-benh .container-wrapper-flex .col-3{
    width: 30%;
  }

  #phieu-kham-benh .container-wrapper-flex .col-4{
    width: 40%;
  }

  #phieu-kham-benh .thongtin-khambenh {
    width: 100%;
    display: flex;
    margin-top: 12px;
  }

  #phieu-kham-benh .thongtin-khambenh .left {
    width: 65%;
    display: flex;
    flex-direction: column;
  }

  #phieu-kham-benh .thongtin-khambenh .right {
    width: 35%;
  }

  #phieu-kham-benh .table-border {
    padding: 6px 0;
    border: 1px solid #000;
  }

  #phieu-kham-benh .table-item{
    padding-left:12px;
  }

  #phieu-kham-benh hr.solid {
    border-top: 1px solid #000;
  }

  #phieu-kham-benh .footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 40px;
  }

  #phieu-kham-benh .footer .footer-confirm {
    margin-right: 44px;
    text-align: center;
  }

  #phieu-kham-benh .name-space {
    height: 80px;
  }

  #phieu-kham-benh .txt-size-m{
    font-size: 14pt;
  }

  #phieu-kham-benh .upperTxt{
    text-transform: uppercase;
  }

  #phieu-kham-benh .phone-website { 
    display: flex; gap: 24px; align-items: center 
  }

@media print {
#phieu-kham-benh .foot-break { break-inside: avoid; }
}
`;

export const stylePhieuKhamBenh5s = `
#phieu-kham-benh * { font-size: 14px; line-height: 1.1rem; font-family: "Times New Roman", Times, serif; }

#phieu-kham-benh .column {
  float: left;
  width: 33.33%;
}

.benh_vien-name {
  font-size: 11px;
}

#phieu-kham-benh .row:after {
  content: "";
  display: table;
  clear: both;
}

#phieu-kham-benh .container-row {
  margin-top: 8px;
}

#phieu-kham-benh .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#phieu-kham-benh .header { display: none; gap: 10px; }
#phieu-kham-benh .flex {
    display: flex;
  }
#phieu-kham-benh .flex.justify {
    justify-content: space-around;
  }

#phieu-kham-benh .text-bold {
    font-size: 13.5px;
    font-weight: bold !important;
  }

  #phieu-kham-benh .table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
  }

  #phieu-kham-benh td, #phieu-kham-benh th {
    border: 1px solid #000;
    // text-align: center;
    padding: 6px;
    // color: #2c3782;
  }

  #phieu-kham-benh .table th:first-child {
    border: 1px solid #000;
    text-align: left;
    padding: 6px;
  }

#phieu-kham-benh .tel {
    margin-right: 50px;
  }
#phieu-kham-benh .txt-700 {
    font-weight: 700;
  }
#phieu-kham-benh .container-title {
  text-transform: uppercase; font-size: 19.5px; font-weight: bold; text-align: center; width: 90%; margin: 2rem auto 1rem auto;
  }
#phieu-kham-benh .container__title--main {
    font-weight: 700;
    font-size: 13px;
    line-height: 14px;
  }
#phieu-kham-benh .container__ttbn--wrapper {
  margin-top: 6px;
}
#phieu-kham-benh .container__ttbn--wrapper>div {
    margin: 4px 0 0 0;
  }
#phieu-kham-benh .container-wrapper-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  #phieu-kham-benh .container-wrapper-flex .col-3{
    width: 30%;
  }

  #phieu-kham-benh .container-wrapper-flex .col-4{
    width: 40%;
  }

  #phieu-kham-benh .thongtin-khambenh {
    width: 100%;
    display: flex;
    margin-top: 12px;
  }

  #phieu-kham-benh .thongtin-khambenh .left {
    width: 65%;
    display: flex;
    flex-direction: column;
  }

  #phieu-kham-benh .thongtin-khambenh .right {
    width: 35%;
  }

  #phieu-kham-benh .table-border {
    padding: 6px 0;
    border: 1px solid #000;
  }

  #phieu-kham-benh .table-item{
    padding-left:12px;
  }

  #phieu-kham-benh hr.solid {
    border-top: 1px solid #000;
  }

  #phieu-kham-benh .footer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 40px;
  }

  #phieu-kham-benh .footer .footer-confirm {
    margin-right: 44px;
    text-align: center;
  }

  #phieu-kham-benh .name-space {
    height: 80px;
  }

  #phieu-kham-benh .txt-size-m{
    font-size: 13px;
  }

  #phieu-kham-benh .upperTxt{
    text-transform: uppercase;
  }

  #phieu-kham-benh .phone-website { 
    display: flex; gap: 24px; align-items: center 
  }

@media print {
#phieu-kham-benh .foot-break { break-inside: avoid; }
}
`;

const PhieuThongTinKhamBenh = ({ data, isOnlyCSST }) => {
  const userProfile = useSelector((state) => state.auth.user);
  let { benh_nhan, chi_so_sinh_ton, hospital, bao_hiem, tom_tat_kham, demo, danh_sach_dich_vu = [], danh_sach_vat_tu = [] } = data;
  const phongDinhDuong = data?.tom_tat_kham?.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_DINH_DUONG);

  const valur_mall = [
    { label: i18n.t(languageKeys.mallampati_1), value: "LOP1" },
    { label: i18n.t(languageKeys.mallampati_2), value: "LOP2" },
    { label: i18n.t(languageKeys.mallampati_3), value: "LOP3" },
    { label: i18n.t(languageKeys.mallampati_4), value: "LOP4" },
  ]
  const getValueMall = (value) => {
    if (!value) return
    const getTex = valur_mall?.find(obj => obj.value == value)?.label || ""
    return getTex
  }

  const getTexEp = (TONG_DIEM_BUON_NGU) => {
    if (1 <= TONG_DIEM_BUON_NGU && TONG_DIEM_BUON_NGU <= 6) {
      return `${TONG_DIEM_BUON_NGU} - ${i18n.t(languageKeys.epworth_1_6)}`
    }

    if (7 <= TONG_DIEM_BUON_NGU && TONG_DIEM_BUON_NGU <= 8) {
      return `${TONG_DIEM_BUON_NGU} - ${i18n.t(languageKeys.epworth_7_8)}`
    }

    if (9 <= TONG_DIEM_BUON_NGU && TONG_DIEM_BUON_NGU <= 24) {
      return `${TONG_DIEM_BUON_NGU} - ${i18n.t(languageKeys.epworth_9_24)}`
    }

    return ""
  }

  const getStopBang = (DIEM_STOP_BANG) => {
    if (3 <= DIEM_STOP_BANG) {
      return `${DIEM_STOP_BANG} - ${i18n.t(languageKeys.stop_bang_cao)}`
    }

    if (DIEM_STOP_BANG > 0 && DIEM_STOP_BANG < 3) {
      return `${DIEM_STOP_BANG} - ${i18n.t(languageKeys.stop_bang_thap)}`
    }
    return ""

  }

  // let ma_benh_kem_theo = Array.isArray(tom_tat_kham.ma_benh_kem_theo)
  //   ? tom_tat_kham.ma_benh_kem_theo.map((item) => [item.ma_benh, item.ten_benh].join(" - ")).join("; ")
  //   : "";
  //
  // let ma_benh_chinh = Array.isArray(tom_tat_kham.ma_benh_chinh)
  // ? tom_tat_kham.ma_benh_chinh.map((item) => [item.ma_benh, item.ten_benh].join(" - ")).join("; ")
  // : "";

  const getBenh = (arr_icd, arr_yhct) => {
    let arr_ten_icd = [];
    let arr_ten_yhct = [];
    if (arr_icd && Array.isArray(arr_icd) && arr_icd.length > 0) {
      arr_ten_icd = arr_icd.map((item) => (item.ma_benh ? `${item.ma_benh} - ${item.ten_benh}` : `${item.ten_benh}`));
    }
    if (arr_yhct && Array.isArray(arr_yhct) && arr_yhct.length > 0) {
      arr_ten_yhct = arr_yhct.map((item) => (item.ma_benh ? `${item.ma_benh} - ${item.ten_benh}` : `${item.ten_benh}`));
    }
    return arr_ten_icd.concat(arr_ten_yhct).join(" ; ");
  };

  const convertLoai = (arr, type) => {
    let data = [];
    if (Array.isArray(arr)) {
      data = arr
        .map((item) => {
          if (item.PHAN_LOAI === type) {
            return item.TEN_DV;
          }
        })
        .filter((item) => !!item)
        .join(", ");
    }

    if (!data.length) {
      data = "";
    }
    return data;
  };

  const convertKetQua = (arr, type) => {
    if (Array.isArray(arr) && !!arr?.length) {
      let data = arr
        .filter((item) => !!item && item?.KET_QUA && item.PHAN_LOAI === type)
        .map((item) => {
          if (type === "CDHA") {
            return item.KET_QUA;
          }
          return <li key={item.KET_QUA}>{item.KET_QUA}</li>;
        });

      if (type !== "CDHA") {
        data = Array.from(new Map(data.map(item => [item.key, item])).values());
      } else {
        data = Array.from(new Set(data));
      }
      return data;
    } else return [];
  };

  //hiển thị ngày tái khám
  const ngayTaiKham = (tom_tat_kham) => {
    let result = "";
    let { ngay_tai_kham, loai_tai_kham, tai_kham_sau } = tom_tat_kham;
    if (!ngay_tai_kham || ngay_tai_kham === "") {
      //nếu không có ngày tái khám
      return result;
    }
    let txtTuan = i18n.t(languageKeys.data_Tuan);
    let txtThu = "";
    if (loai_tai_kham === "NGAY") {
      txtTuan = i18n.t(languageKeys.data_Ngay);
    } else if (loai_tai_kham === "THANG") {
      txtTuan = i18n.t(languageKeys.data_Thang);
    }
    txtThu =
      getWeekdays(moment(ngay_tai_kham, "YYYYMMDD").valueOf()) +
      " " +
      i18n.t(languageKeys.data_Ngay).toLowerCase() +
      " " +
      convertDateToValue(ngay_tai_kham);
    result = i18n.t(languageKeys.field_Tai_kham_sau) + " " + tai_kham_sau + " " + txtTuan.toLowerCase() + ", " + txtThu;

    return result;
  };

  let ds_ten_loai_chi_dinh_xn = convertLoai(data.ds_dv_cls, "XET_NGHIEM");

  let ds_ten_loai_chi_dinh_cdha = convertLoai(data.ds_dv_cls, "CDHA");

  let ds_ket_qua_xn = convertKetQua(data.ds_dv_cls, "XET_NGHIEM");
  let ds_ket_qua_cdha = convertKetQua(data.ds_dv_cls, "CDHA");
  const numberCLS = (form_kham) => {

  }

  const renderCls = (number = "5") => {
    // aivi052428 bỏ mục cls ở phiếu in

    if (userProfile.partner_code  == "aivi052428") {
      return ""
    }


    return <div>
    <div>
      <div className="txt-700">{number}. {i18n.t(languageKeys.ket_qua_cls)}:</div>
      <ul style={{ margin: 0 }}>
        {!!ds_ket_qua_xn.length && (
          <li>
            {i18n.t(languageKeys.xet_nghiem)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_xn.map((item) => item)}</ul>
          </li>
        )}
        {!!ds_ket_qua_cdha.length && (
          <li>
            {i18n.t(languageKeys.chan_doan_hinh_anh)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{ds_ket_qua_cdha.map((item) => <span style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: item }} />)}</ul>
          </li>
        )}
      </ul>
    </div>
  </div>

  }

  const render_kham_benh = () => {
    // if (data.tom_tat_kham.TEN_KHOA_PHONG?.toLowerCase()?.includes(NamePhongKhamMat)) {
    const CommonTitle = () => ( <div className="container__title--main" style={{ marginTop: 10 }}>IV. {i18n.t(languageKeys.field_Kham_benh)?.toUpperCase()}</div>)

    if (phongDinhDuong) {
      return (
        <>
          <div className="container__title--main" style={{ marginTop: 10 }}>
            IV. {i18n.t(languageKeys.tu_van_xu_tri)?.toUpperCase()}
          </div>
          <div style={{ marginTop: "6px" }}>
            <span className="txt-700"> 1. {i18n.t(languageKeys.don_dinh_duong)}: </span> 
            <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.DON_DINH_DUONG)}}/>
          </div>
          <div style={{ marginTop: "6px" }}>
            <span className="txt-700"> 2. {i18n.t(languageKeys.don_tap_luyen)}: </span> 
            <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.DON_TAP_LUYEN)}}/>
          </div>
          <div style={{ marginTop: "6px" }}>
            <span className="txt-700"> 3. {i18n.t(languageKeys.luu_y_chung)}: </span> 
            <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.loi_dan)}} />
          </div>
          <div style={{ marginTop: "6px" }}>
            <span className="txt-700"> 4. {i18n.t(languageKeys.muc_do_cam_ket_cua_khach_hang)}: </span> {tom_tat_kham.MUC_DO_CAM_KET || ""}
          </div>
          <div style={{ marginTop: "6px" }}>
            <span className="txt-700"> 5. {i18n.t(languageKeys.field_ngay_tai_kham)}: </span> {tom_tat_kham.ngay_tai_kham}
          </div>
        </>
      );
    }

  
    if (data.tom_tat_kham.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_MAT)) {
      return (
        <>
          <CommonTitle/>
          <div style={{ marginTop: 6 }} className="table">
            <p className={"text-bold"}>1. {i18n.t(languageKeys.do_thi_luc)}: </p>
            <table style={{ marginBottom: 10 }}>
              <tr>
                <th align="center" style={{ width: "200px", textAlign: 'center' }}>{i18n.t(languageKeys.thi_luc)}</th>
                <th align="center" style={{ width: "200px", textAlign: 'center' }}>{i18n.t(languageKeys.left_eye)}</th>
                <th align="center" style={{ width: "200px", textAlign: 'center' }}>{i18n.t(languageKeys.right_eye)}</th>
              </tr>
              <tr>
                <td style={{ width: "200px" }}>{i18n.t(languageKeys.khong_kinh)}</td>
                <td style={{ width: "200px", textAlign: "center" }}>{data.tom_tat_kham?.MAT_TRAI_K_KINH}</td>
                <td style={{ width: "200px", textAlign: "center" }}>{data.tom_tat_kham?.MAT_PHAI_K_KINH}</td>
              </tr>
              <tr>
                <td style={{ width: "200px" }}>{i18n.t(languageKeys.co_kinh)}</td>
                <td style={{ width: "200px", textAlign: "center" }}>{data.tom_tat_kham?.MAT_TRAI_CO_KINH}</td>
                <td style={{ width: "200px", textAlign: "center" }}>{data.tom_tat_kham?.MAT_PHAI_CO_KINH}</td>
              </tr>
            </table>
          </div>

          <div>
            <div>
              <span className="txt-700">2. {i18n.t(languageKeys.field_chuan_doan_benh_chinh)}: </span>
              {getBenh(data.tom_tat_kham.ma_benh_chinh, data.tom_tat_kham.ma_benh_yhct_chinh)}
            </div>
          </div>
          <div>
            <div>
              <span className="txt-700">3. {i18n.t(languageKeys.field_Ma_benh_kem_theo)}: </span>
              {getBenh(data.tom_tat_kham.ma_benh_kem_theo, data.tom_tat_kham.ma_benh_yhct_kem_theo)}
            </div>
          </div>
          <div>
            <div>
              <span className="txt-700">4. {i18n.t(languageKeys.chan_doan_xac_dinh)}: </span>
            <div dangerouslySetInnerHTML={{__html:convertDownlineTag(data?.tom_tat_kham?.chan_doan_xac_dinh)}}/>

            </div>
          </div>
          {renderCls()}
          <div>
            <div>
              <span className="txt-700">{renderCls()?.length == 0 ? "5": "6" }. {i18n.t(languageKeys.huong_xu_tri)}: </span>
              {tom_tat_kham.xu_tri && HUONG_XU_TRI[`${tom_tat_kham.xu_tri}`] ? HUONG_XU_TRI[`${tom_tat_kham.xu_tri}`] : ""}
              {/* {tom_tat_kham.xu_tri === "LIEU_TRINH" && renderLieuTrinh()} */}
            </div>
          </div>
          <div className="foot-break">
            <span className="txt-700">{renderCls()?.length == 0 ? "6": "7" }. {i18n.t(languageKeys.loi_dan)}: </span>
            {tom_tat_kham.loi_dan}
          </div>
          <div className="foot-break">
            <span className="txt-700">{renderCls()?.length == 0 ? "7": "8" }. {i18n.t(languageKeys.ngay_tai_kham)}: </span>
            {ngayTaiKham(tom_tat_kham)}
          </div>
          {!!tom_tat_kham.TAI_KHAM && (
            <div className="foot-break">
              <span style={{ marginLeft: 30 }}>{i18n.t(languageKeys.ghi_chu)}: </span>
            <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.GHI_CHU_TAI_KHAM)}}/>

            </div>
          )}
        </>
      );
    }
    if (data.tom_tat_kham.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_TAI_MUI_HONG)) {
      return (
        <>
          <CommonTitle/>
          <div style={{ marginTop: 12 }} className="table">
            <p className={"text-bold"}>1. {i18n.t(languageKeys.hearing_test_results)}: </p>
            <table style={{ marginBottom: 10 }}>
              <tr>
                <th align="center" style={{ width: "200px", textAlign: 'center' }}>{i18n.t(languageKeys.thinh_luc)}</th>
                <th align="center" style={{ width: "200px", textAlign: 'center' }}>{i18n.t(languageKeys.left_ear)}</th>
                <th align="center" style={{ width: "200px", textAlign: 'center' }}>{i18n.t(languageKeys.right_ear)}</th>
              </tr>
              <tr>
                <td style={{ width: "200px" }}>{i18n.t(languageKeys.speak_normally)}</td>
                <td style={{ width: "200px", textAlign: "center" }}>{data.tom_tat_kham?.NOI_THUONG_TRAI}</td>
                <td style={{ width: "200px", textAlign: "center" }}>{data.tom_tat_kham?.NOI_THUONG_PHAI}</td>
              </tr>
              <tr>
                <td style={{ width: "200px" }}>{i18n.t(languageKeys.speak_whisper)}</td>
                <td style={{ width: "200px", textAlign: "center" }}>{data.tom_tat_kham?.NOI_THAM_TRAI}</td>
                <td style={{ width: "200px", textAlign: "center" }}>{data.tom_tat_kham?.NOI_THAM_PHAI}</td>
              </tr>
            </table>
          </div>

          <div>
            <div>
              <span className="txt-700">2. {i18n.t(languageKeys.field_chuan_doan_benh_chinh)}: </span>
              {getBenh(data.tom_tat_kham.ma_benh_chinh, data.tom_tat_kham.ma_benh_yhct_chinh)}
            </div>
          </div>
          <div>
            <div>
              <span className="txt-700">3. {i18n.t(languageKeys.field_Ma_benh_kem_theo)}: </span>
              {getBenh(data.tom_tat_kham.ma_benh_kem_theo, data.tom_tat_kham.ma_benh_yhct_kem_theo)}
            </div>
          </div>
          <div>
            <div>
              <span className="txt-700">4. {i18n.t(languageKeys.chan_doan_xac_dinh)}: </span>
              <div dangerouslySetInnerHTML={{__html:convertDownlineTag(data?.tom_tat_kham?.chan_doan_xac_dinh)}}/>
              
            </div>
          </div>
          {renderCls()}
          <div>
            <div>
              <span className="txt-700">{renderCls()?.length == 0 ? "5": "6" }. {i18n.t(languageKeys.huong_xu_tri)}: </span>
              {tom_tat_kham.xu_tri && HUONG_XU_TRI[`${tom_tat_kham.xu_tri}`] ? HUONG_XU_TRI[`${tom_tat_kham.xu_tri}`] : ""}
              {/* {tom_tat_kham.xu_tri === "LIEU_TRINH" && renderLieuTrinh()} */}
            </div>
          </div>
          <div className="foot-break">
            <span className="txt-700">{renderCls()?.length == 0 ? "6": "7" }. {i18n.t(languageKeys.loi_dan)}: </span>
            <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.loi_dan)}}/>

          </div>
          <div className="foot-break">
            <span className="txt-700">{renderCls()?.length == 0 ? "7": "8" }. {i18n.t(languageKeys.ngay_tai_kham)}: </span>
            {ngayTaiKham(tom_tat_kham)}
          </div>
          {!!tom_tat_kham.TAI_KHAM && (
            <div className="foot-break">
              <span style={{ marginLeft: 30 }}>{i18n.t(languageKeys.ghi_chu)}: </span>
              <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.GHI_CHU_TAI_KHAM)}}/>
            </div>
          )}
        </>
      );
    }
    if (data.tom_tat_kham.FORM_KHAM_BENH?.includes(keyFormKham.KHAM_RANG_HAM_MAT)) {
      return (
        <>
          <CommonTitle/>
          <div style={{ marginTop: 12 }} className="table">
            <span className={"text-bold"}>1. {i18n.t(languageKeys.damaged_teeth)}:</span>
            {data.tom_tat_kham?.RANG_TON_THUONG}
          </div>

          <div>
            <div>
              <span className="txt-700">2. {i18n.t(languageKeys.field_chuan_doan_benh_chinh)}: </span>
              {getBenh(data.tom_tat_kham.ma_benh_chinh, data.tom_tat_kham.ma_benh_yhct_chinh)}
            </div>
          </div>
          <div>
            <div>
              <span className="txt-700">3. {i18n.t(languageKeys.field_Ma_benh_kem_theo)}: </span>
              {getBenh(data.tom_tat_kham.ma_benh_kem_theo, data.tom_tat_kham.ma_benh_yhct_kem_theo)}
            </div>
          </div>
          <div>
            <span className="txt-700" style={{ marginBottom: "0 !important" }}>4. {i18n.t(languageKeys.chan_doan_xac_dinh)}:</span>
            <p style={{ display: "flex", flexDirection: "column", marginLeft: "12px", marginBottom: "0 !important", marginTop: "0 !important" }}>
              <div style={{ marginTop: "0 !important", marginBottom: "0 !important" }}>
                <span className={"text-bold"} style={{ marginTop: "0 !important", marginBottom: "0 !important" }}>
                  {i18n.t(languageKeys.upper_jaw)}:
                </span>
                {data.tom_tat_kham?.HAM_TREN_XAC_DINH}
              </div>
              <div style={{ marginTop: "0 !important", marginBottom: "0 !important" }}>
                <span className={"text-bold"} style={{ marginTop: "0 !important", marginBottom: "0 !important" }}>
                  {i18n.t(languageKeys.lower_jaw)}:
                </span>
                {data.tom_tat_kham?.HAM_DUOI_XAC_DINH}
              </div>
            </p>
          </div>
          {renderCls()}
          <div>
            <div>
              <span className="txt-700">{renderCls()?.length == 0 ? "5": "6" }. {i18n.t(languageKeys.huong_xu_tri)}: </span>
              {tom_tat_kham.xu_tri && HUONG_XU_TRI[`${tom_tat_kham.xu_tri}`] ? HUONG_XU_TRI[`${tom_tat_kham.xu_tri}`] : ""}
              {/* {tom_tat_kham.xu_tri === "LIEU_TRINH" && renderLieuTrinh()} */}
            </div>
          </div>
          <div className="foot-break">
            <span className="txt-700">{renderCls()?.length == 0 ? "6": "7" }. {i18n.t(languageKeys.loi_dan)}: </span>
          <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.loi_dan)}}/>
          </div>
          <div className="foot-break">
            <span className="txt-700">{renderCls()?.length == 0 ? "7": "8" }. {i18n.t(languageKeys.ngay_tai_kham)}: </span>
            {ngayTaiKham(tom_tat_kham)}
          </div>
          {!!tom_tat_kham.TAI_KHAM && (
            <div className="foot-break">
              <span style={{ marginLeft: 30 }}>{i18n.t(languageKeys.ghi_chu)}: </span>
          <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.GHI_CHU_TAI_KHAM)}}/>
            </div>
          )}
        </>
      );
    }
    if (data?.tom_tat_kham?.noi_giac_ngu == 1) {
      return <>
        <CommonTitle/>
        <div style={{ marginTop: 12 }}>
          <span className="txt-700">1. {i18n.t(languageKeys.trieu_chung_thuc_the)}:</span>
          <br />
          <ul style={{ margin: 0 }}>
            <li>
              {i18n.t(languageKeys.kham_hong)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{data?.chi_so_giac_ngu?.KHAM_HONG || ""}</ul>
            </li>
            <li>
              {i18n.t(languageKeys.diem_mallampati)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{getValueMall(data?.chi_so_giac_ngu?.DIEM_MALLAMPATI)}</ul>
            </li>
            <li>
              {i18n.t(languageKeys.cac_bo_phan_khac)}:<ul style={{ margin: "0px 0px 0px -20px" }}>{data?.chi_so_giac_ngu?.CAC_BO_PHAN_KHAC || ""}</ul>
            </li>
          </ul>
        </div>
        <div>
          <span className="txt-700">2. {i18n.t(languageKeys.thang_diem)}: </span>
          <br />
          <ul style={{ margin: 0 }}>
            <li>
              Epworth: {getTexEp(data?.chi_so_giac_ngu?.TONG_DIEM_BUON_NGU) || ""}
            </li>
            <li>
              STOP-BANG: {getStopBang(data?.chi_so_giac_ngu?.DIEM_STOP_BANG) || ""}
            </li>
          </ul>
        </div>
       

        <div>
          <div>
            <span className="txt-700">3. {i18n.t(languageKeys.field_chuan_doan_benh_chinh)}: </span>
            {getBenh(data.tom_tat_kham.ma_benh_chinh, data.tom_tat_kham.ma_benh_yhct_chinh)}
          </div>
        </div>
        <div>
          <div>
            <span className="txt-700">4. {i18n.t(languageKeys.field_Ma_benh_kem_theo)}: </span>
            {getBenh(data.tom_tat_kham.ma_benh_kem_theo, data.tom_tat_kham.ma_benh_yhct_kem_theo)}
          </div>
        </div>
        <div>
          <div>
            <span className="txt-700">5. {i18n.t(languageKeys.chan_doan_xac_dinh)}: </span>
          <div dangerouslySetInnerHTML={{__html:convertDownlineTag(data?.tom_tat_kham?.chan_doan_xac_dinh)}}/>

          </div>
        </div>

        {renderCls("6")}
        <div>
          <div>
            <span className="txt-700">{renderCls()?.length == 0 ? "6": "7" }. {i18n.t(languageKeys.huong_xu_tri)}: </span>
            {tom_tat_kham.xu_tri && HUONG_XU_TRI[`${tom_tat_kham.xu_tri}`] ? HUONG_XU_TRI[`${tom_tat_kham.xu_tri}`] : ""}
            {/* {tom_tat_kham.xu_tri === "LIEU_TRINH" && renderLieuTrinh()} */}
          </div>
        </div>
        <div className="foot-break">
          <span className="txt-700">{renderCls()?.length == 0 ? "7": "8" }. {i18n.t(languageKeys.loi_dan)}: </span>
          <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.loi_dan)}}/>
        </div>
        <div className="foot-break">
          <span className="txt-700">{renderCls()?.length == 0 ? "8": "9" }. {i18n.t(languageKeys.ngay_tai_kham)}: </span>
          {ngayTaiKham(tom_tat_kham)}
        </div>
        {!!tom_tat_kham.TAI_KHAM && (
          <div className="foot-break">
            <span style={{ marginLeft: 30 }}>{i18n.t(languageKeys.ghi_chu)}: </span>
            <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.GHI_CHU_TAI_KHAM)}}/>
          </div>
        )}
      </>;
    }
    return (
      <>
        <CommonTitle/>
        <div style={{ marginTop: 12 }}>
          <span className="txt-700">1. {i18n.t(languageKeys.toan_than)}:</span>
          {/* {tom_tat_kham.toan_than} */}
          <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.toan_than)}}/>
        </div>
        <div>
          <div>
            <span className="txt-700">2. {i18n.t(languageKeys.cac_bo_phan)}: </span>  <span dangerouslySetInnerHTML={{__html:convertDownlineTag(data.tom_tat_kham?.cac_bo_phan)}}/>
          </div>
          <ul style={{ margin: 0 }}>
            {!!tom_tat_kham.tuan_hoan && (
              <li>
                {i18n.t(languageKeys.tuan_hoan)}: 
                <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.tuan_hoan)}}/>
                {/* {tom_tat_kham.tuan_hoan} */}
              </li>
            )}
            {!!tom_tat_kham.ho_hap && (
              <li>
                {i18n.t(languageKeys.ho_hap)}:
                <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.ho_hap)}}/>
              </li>
            )}
            {!!tom_tat_kham.tieu_hoa && (
              <li>
                {i18n.t(languageKeys.tieu_hoa)}: 
                <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.tieu_hoa)}}/>
              </li>
            )}
            {!!tom_tat_kham.than_tiet_nieu && (
              <li>
                {i18n.t(languageKeys.than_tiet_nieu)}: 
                <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.than_tiet_nieu)}}/>

              </li>
            )}
            {!!tom_tat_kham.noi_tiet && (
              <li>
                {i18n.t(languageKeys.noi_tiet)}: 
                <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.noi_tiet)}}/>

              </li>
            )}
            {!!tom_tat_kham.co_xuong_khop && (
              <li>
                {i18n.t(languageKeys.co_xuong_khop)}: 
                <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.co_xuong_khop)}}/>

              </li>
            )}
            {!!tom_tat_kham.than_kinh && (
              <li>
                {i18n.t(languageKeys.than_kinh)}:
                <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.than_kinh)}}/>

              </li>
            )}
            {!!tom_tat_kham.TAM_THAN && (
              <li>
                <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.TAM_THAN)}}/>

              </li>
            )}
          </ul>
        </div>
        <div>
          <div>
            <span className="txt-700">3. {i18n.t(languageKeys.field_chuan_doan_benh_chinh)}: </span>
            {getBenh(data.tom_tat_kham.ma_benh_chinh, data.tom_tat_kham.ma_benh_yhct_chinh)}
          </div>
        </div>
        <div>
          <div>
            <span className="txt-700">4. {i18n.t(languageKeys.field_Ma_benh_kem_theo)}: </span>
            {getBenh(data.tom_tat_kham.ma_benh_kem_theo, data.tom_tat_kham.ma_benh_yhct_kem_theo)}
          </div>
        </div>
        <div>
          <div>
            <span className="txt-700">5. {i18n.t(languageKeys.chan_doan_xac_dinh)}: </span>
            <div dangerouslySetInnerHTML={{__html:convertDownlineTag(data?.tom_tat_kham?.chan_doan_xac_dinh)}}/>
          </div>
        </div>
        {renderCls("6")}
       
        <div>
          <div>
            <span className="txt-700">{renderCls()?.length == 0 ? "6": "7" }. {i18n.t(languageKeys.huong_xu_tri)}: </span>
            {tom_tat_kham.xu_tri && HUONG_XU_TRI[`${tom_tat_kham.xu_tri}`] ? HUONG_XU_TRI[`${tom_tat_kham.xu_tri}`] : ""}
            {/* {tom_tat_kham.xu_tri === "LIEU_TRINH" && renderLieuTrinh()} */}
          </div>
        </div>
        <div className="foot-break">
          <span className="txt-700">{renderCls()?.length == 0 ? "7": "8" }. {i18n.t(languageKeys.loi_dan)}: </span>
          <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.loi_dan)}}/>
        </div>
        <div className="foot-break">
          <span className="txt-700">{renderCls()?.length == 0 ? "8": "9" }. {i18n.t(languageKeys.ngay_tai_kham)}: </span>
          {ngayTaiKham(tom_tat_kham)}
        </div>
        {!!tom_tat_kham.TAI_KHAM && (
          <div className="foot-break">
            <span style={{ marginLeft: 30 }}>{i18n.t(languageKeys.ghi_chu)}: </span>
          <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.GHI_CHU_TAI_KHAM)}}/>
          </div>
        )}
      </>
    );
  };

  const check5s = userProfile?.partner_code == "pkdk5s12"
  const renderCSST2 = (key) => {

    if (check5s) {

      if (key == "nhiet_do" && convertString(chi_so_sinh_ton[key])?.length > 0) {
        return  <div className="column txt-size-m">
         {i18n.t(languageKeys.field_Nhiet_do)} {i18n.t(languageKeys.field_Do_C)}: {convertString(chi_so_sinh_ton.nhiet_do)}
      </div>
      }

      if (key == "mach" && convertString(chi_so_sinh_ton[key])?.length > 0) {
        return  <div className="column txt-size-m">
         {i18n.t(languageKeys.field_Mach)} {i18n.t(languageKeys.field_Lan_phut)}: {convertString(chi_so_sinh_ton.mach)}
      </div>
      }

      if (key == "nhip_tho" && convertString(chi_so_sinh_ton[key])?.length > 0) {
        return  <div className="column txt-size-m">
          {i18n.t(languageKeys.field_Nhip_tho)} {i18n.t(languageKeys.field_Lan_phut)}: {convertString(chi_so_sinh_ton.nhip_tho)}
      </div>
      }

      if (key == "can_nang" && convertString(chi_so_sinh_ton[key])?.length > 0) {
        return  <div className="column txt-size-m">
          {i18n.t(languageKeys.can_nang)} (Kg): {convertString(chi_so_sinh_ton.can_nang)}
      </div>
      }

      if (key == "chieu_cao" && convertString(chi_so_sinh_ton[key])?.length > 0) {
        return  <div className="column txt-size-m">
         {i18n.t(languageKeys.chieu_cao)} ( cm): {convertString(chi_so_sinh_ton.chieu_cao)}
      </div>
      }

      if (key == "bmi" && convertString(chi_so_sinh_ton[key])?.length > 0) {
        return  <div className="column txt-size-m">
          {i18n.t(languageKeys.bmi)}: {convertString(chi_so_sinh_ton.bmi)}
      </div>
      }

      if (key == "vong_bung" && convertString(chi_so_sinh_ton[key])?.length > 0) {
        return  <div className="column txt-size-m">
         {i18n.t(languageKeys.vong_bung)}: {convertString(chi_so_sinh_ton.vong_bung)}
      </div>
      }

      if (key == "vong_co" && convertString(chi_so_sinh_ton[key])?.length > 0) {
        return  <div className="column txt-size-m">
          {i18n.t(languageKeys.vong_co)}: {convertString(chi_so_sinh_ton.vong_co)}
      </div>
      }

      if (key == "huyet_ap" && convertString(chi_so_sinh_ton.huyet_ap[0])?.length > 0&& convertString(chi_so_sinh_ton.huyet_ap[1])?.length > 0) {
        return  <div className="column txt-size-m">
        {i18n.t(languageKeys.field_Huyet_ap)} (mmHg):
        {!!chi_so_sinh_ton.huyet_ap && Array.isArray(chi_so_sinh_ton.huyet_ap)
          ? ` ${convertString(chi_so_sinh_ton.huyet_ap[0])} / ${convertString(chi_so_sinh_ton.huyet_ap[1])}`
          : ""}
      </div>
      }

      return ""

      
    } else {

      if (key == "nhiet_do" ) {
        return  <div className="column txt-size-m">
         {i18n.t(languageKeys.field_Nhiet_do)} {i18n.t(languageKeys.field_Do_C)}: {convertString(chi_so_sinh_ton.nhiet_do)}
      </div>
      }

      if (key == "mach" ) {
        return  <div className="column txt-size-m">
         {i18n.t(languageKeys.field_Mach)} {i18n.t(languageKeys.field_Lan_phut)}: {convertString(chi_so_sinh_ton.mach)}
      </div>
      }

      if (key == "nhip_tho" ) {
        return  <div className="column txt-size-m">
          {i18n.t(languageKeys.field_Nhip_tho)} {i18n.t(languageKeys.field_Lan_phut)}: {convertString(chi_so_sinh_ton.nhip_tho)}
      </div>
      }

      if (key == "can_nang" ) {
        return  <div className="column txt-size-m">
          {i18n.t(languageKeys.can_nang)} (Kg): {convertString(chi_so_sinh_ton.can_nang)}
      </div>
      }

      if (key == "chieu_cao" ) {
        return  <div className="column txt-size-m">
         {i18n.t(languageKeys.chieu_cao)} ( cm): {convertString(chi_so_sinh_ton.chieu_cao)}
      </div>
      }

      if (key == "bmi" ) {
        return  <div className="column txt-size-m">
          {i18n.t(languageKeys.bmi)}: {convertString(chi_so_sinh_ton.bmi)}
      </div>
      }

      if (key == "vong_bung" ) {
        return  <div className="column txt-size-m">
         {i18n.t(languageKeys.vong_bung)}: {convertString(chi_so_sinh_ton.vong_bung)}
      </div>
      }


      if (key == "vong_co" ) {
        return  <div className="column txt-size-m">
          {i18n.t(languageKeys.vong_co)}: {convertString(chi_so_sinh_ton.vong_co)}
      </div>
      }

      if (key == "huyet_ap") {
        return  <div className="column txt-size-m">
        {i18n.t(languageKeys.field_Huyet_ap)} (mmHg):
        {!!chi_so_sinh_ton.huyet_ap && Array.isArray(chi_so_sinh_ton.huyet_ap)
          ? ` ${convertString(chi_so_sinh_ton.huyet_ap[0])} / ${convertString(chi_so_sinh_ton.huyet_ap[1])}`
          : ""}
      </div>
      }

      return ""
      
    }

  }
  
  const renderChiSoSinhTon = () => {
    return (
      <>
        <div className="thongtin-khambenh" style={{ display: "flex", flexDirection: "column" }}>
          <div className="container__title--main">II. {i18n.t(languageKeys.field_Chi_so_sinh_ton)?.toUpperCase()}</div>
          <div className="container-row">
            <div className="row">
              {renderCSST2("nhiet_do")}
              {renderCSST2("mach")}
              {/* <div className="column txt-size-m">
                {i18n.t(languageKeys.field_Nhiet_do)} {i18n.t(languageKeys.field_Do_C)}: {convertString(chi_so_sinh_ton.nhiet_do)}
              </div> */}
              {/* <div className="column txt-size-m">
                {i18n.t(languageKeys.field_Mach)} {i18n.t(languageKeys.field_Lan_phut)}: {convertString(chi_so_sinh_ton.mach)}
              </div> */}
              {/* <div className="column txt-size-m">
                {i18n.t(languageKeys.field_Huyet_ap)} (mmHg):
                {!!chi_so_sinh_ton.huyet_ap && Array.isArray(chi_so_sinh_ton.huyet_ap)
                  ? ` ${convertString(chi_so_sinh_ton.huyet_ap[0])} / ${convertString(chi_so_sinh_ton.huyet_ap[1])}`
                  : ""}
              </div> */}
              {renderCSST2("huyet_ap")}

              {/* <div className="column txt-size-m">
                {i18n.t(languageKeys.field_Nhip_tho)} {i18n.t(languageKeys.field_Lan_phut)}: {convertString(chi_so_sinh_ton.nhip_tho)}
              </div> */}
              {renderCSST2("nhip_tho")}
              {/* <div className="column txt-size-m">
                {i18n.t(languageKeys.can_nang)} (Kg): {convertString(chi_so_sinh_ton.can_nang)}
              </div> */}
              {renderCSST2("can_nang")}

              {/* <div className="column txt-size-m">
                {i18n.t(languageKeys.chieu_cao)} ( cm): {convertString(chi_so_sinh_ton.chieu_cao)}
              </div> */}
              {renderCSST2("chieu_cao")}

              {/* <div className="column txt-size-m">
                {i18n.t(languageKeys.bmi)}: {convertString(chi_so_sinh_ton.bmi)}
              </div> */}
              {renderCSST2("bmi")}

              {/* <div className="column txt-size-m">
                {i18n.t(languageKeys.vong_co)}: {convertString(chi_so_sinh_ton.vong_co)}
              </div> */}
              {renderCSST2("vong_co")}

              {
                [dataPartnerCode.dth].includes(userProfile.partner_code) ? (
                  <>
                    <div className="column txt-size-m">
                      SpO2: {convertString(chi_so_sinh_ton.Sp02)}
                    </div>
                    <div className="column txt-size-m">
                      {i18n.t(languageKeys.vong_bung_qua_eo)}: {convertString(chi_so_sinh_ton.vong_bung?.split(",")?.[0])}
                    </div>
                    <div className="column txt-size-m">
                      {i18n.t(languageKeys.vong_bung_qua_ron)}: {convertString(chi_so_sinh_ton.vong_bung?.split(",")?.[1])}
                    </div>
                    <div className="column txt-size-m">
                      {i18n.t(languageKeys.vong_bung_qua_gai_chau_truoc_tren)}: {convertString(chi_so_sinh_ton.vong_bung?.split(",")?.[2])}
                    </div>
                  </>
                ) : (
                  // <div className="column txt-size-m">
                  //   {i18n.t(languageKeys.vong_bung)}: {convertString(chi_so_sinh_ton.vong_bung)}
                  // </div>
              <>{renderCSST2("vong_bung")}</>
                )
              }
              {[dataPartnerCode.dth].includes(userProfile?.partner_code) ? (
                <>
                  <div className="column txt-size-m">
                    {i18n.t(languageKeys.vong_hong_chau)}: {convertString(chi_so_sinh_ton.vong_hong_chau)}
                  </div>
                  <div className="column txt-size-m">
                    {i18n.t(languageKeys.tuoi_man_kinh)}: {convertString(chi_so_sinh_ton.tuoi_man_kinh)}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderThongTinKhamBenh = () => {

    if(phongDinhDuong) {
      return (
        <>
          <div className="container__title--main" style={{ marginTop: 10 }}>III. {i18n.t(languageKeys.hoi_benh)?.toUpperCase()}</div>

          <div style={{ marginTop: "6px" }}>
              <span className="txt-700"> 1. {i18n.t(languageKeys.field_ly_do_tu_van_dinh_duong)}: </span> 
              <span dangerouslySetInnerHTML={{__html: convertDownlineTag(tom_tat_kham.ly_do_kham)}}/>
          </div>
          <div style={{ marginTop: "6px" }}>
              <span className="txt-700"> 2. {i18n.t(languageKeys.field_mot_ngay_an_dien_hinh_hien_nay)}: </span> 
              <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.CHE_DO_AN_UONG)}}/>
              </div>
          <div style={{ marginTop: "6px" }}>
              <span className="txt-700"> 3. {i18n.t(languageKeys.field_mot_ngay_tap_va_hoat_dong_dien_hinh_hien_nay)}: </span> 
              <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.CHE_DO_LUYEN_TAP)}}/>
          </div>
        </>
      )
    }


    return (
      <>
        <div className="thongtin-khambenh">
          <div className="left">
            <div className="container__title--main">III. {i18n.t(languageKeys.thong_tin_kham_benh)?.toUpperCase()}</div>
            <div style={{ marginTop: "6px" }}>
              <span className="txt-700"> 1. {i18n.t(languageKeys.field_Ly_do_kham)}: </span>
              <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.ly_do_kham)}}/>

            </div>
            <div>
              <span className="txt-700"> 2. {i18n.t(languageKeys.field_Qua_trinh_benh_ly)}: </span> 
              <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.qua_trinh_benh_ly)}}/>

            </div>
            {/* <div>
            <span className="txt-700"> 3. {i18n.t(languageKeys.trieu_chung_lam_sang)}: </span> {tom_tat_kham.trieu_chung_lam_sang}
          </div> */}
            <div>
              <div className="txt-700"> 3. {i18n.t(languageKeys.tien_su_benh)}: </div>
              <ul style={{ margin: 0 }}>
                {!!tom_tat_kham.tien_su_ban_than && (
                  <li>
                    {i18n.t(languageKeys.field_Tien_su_ban_than)} :
                    <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.tien_su_ban_than)}}/>
                  </li>
                )}
                {!!tom_tat_kham.tien_su_san_khoa && (
                  <li>
                    {i18n.t(languageKeys.tien_su_san_khoa)}:
                    <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.tien_su_san_khoa)}}/>
                  </li>
                )}
                {!!tom_tat_kham.tien_su_di_ung && (
                  <li>
                    {i18n.t(languageKeys.tien_su_di_ung)}:
                    <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.tien_su_di_ung)}}/>

                  </li>
                )}
                {!!tom_tat_kham.tai_nan_thuong_tich && (
                  <li>
                    {i18n.t(languageKeys.tai_nan_thuong_tich)}: 
                    <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.tai_nan_thuong_tich)}}/>

                  </li>
                )}
                {!!tom_tat_kham.tien_su_gia_dinh && (
                  <li>
                    {i18n.t(languageKeys.field_Tien_su_gia_dinh)}:
                    <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.tien_su_gia_dinh)}}/>

                  </li>
                )}
                {!!tom_tat_kham.tien_su_benh_khac && (
                  <li>
                    {i18n.t(languageKeys.tien_su_benh_khac)}:
                    <div dangerouslySetInnerHTML={{__html:convertDownlineTag(tom_tat_kham.tien_su_benh_khac)}}/>

                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  };

  const isCheckOnlyCanDo = () => {
    if (isOnlyCSST) {
      return <div className="thongtin-khambenh" style={{ display: "flex", flexDirection: "column" }}>
        <div className="container__title--main">II. {i18n.t(languageKeys.thong_tin_ket_qua_do)?.toUpperCase()}</div>
        <div className="container-row">
          <div className="row">
            <div className="column txt-size-m">1. {i18n.t(languageKeys.field_Nhiet_do)}: {convertString(chi_so_sinh_ton.nhiet_do)} {i18n.t(languageKeys.field_Do_C)}</div>
            <div className="column txt-size-m">2. {i18n.t(languageKeys.field_Mach)} {i18n.t(languageKeys.field_Lan_phut)}: {convertString(chi_so_sinh_ton.mach)} </div>
            <div className="column txt-size-m">3. {i18n.t(languageKeys.field_Huyet_ap)}:
              {!!chi_so_sinh_ton.huyet_ap && Array.isArray(chi_so_sinh_ton.huyet_ap)
                ? ` ${convertString(chi_so_sinh_ton.huyet_ap[0])} / ${convertString(chi_so_sinh_ton.huyet_ap[1])}`
                : ""}
              (mmHg)</div>
            <div className="column txt-size-m">4. {i18n.t(languageKeys.field_Nhip_tho)} {i18n.t(languageKeys.field_Lan_phut)}: {convertString(chi_so_sinh_ton.nhip_tho)} </div>
            <div className="column txt-size-m">5. {i18n.t(languageKeys.can_nang)}: {convertString(chi_so_sinh_ton.can_nang)} (Kg)</div>
            <div className="column txt-size-m">6. {i18n.t(languageKeys.chieu_cao)}: {convertString(chi_so_sinh_ton.chieu_cao)} ( cm)</div>
            <div className="column txt-size-m">7. {i18n.t(languageKeys.bmi)}: {convertString(chi_so_sinh_ton.bmi)}</div>
            <div className="column txt-size-m">8. {i18n.t(languageKeys.vong_co)}: {convertString(chi_so_sinh_ton.vong_co)}</div>
            <div className="column txt-size-m">9. {i18n.t(languageKeys.vong_bung)}: {convertString(chi_so_sinh_ton.vong_bung)}</div>
            {
              [dataPartnerCode.dth].includes(userProfile?.partner_code) ? (
                  <>
                    <div className="column txt-size-m">10. {i18n.t(languageKeys.vong_hong_chau)}: {convertString(chi_so_sinh_ton.vong_hong_chau)}</div>
                    <div className="column txt-size-m">11. {i18n.t(languageKeys.tuoi_man_kinh)}: {convertString(chi_so_sinh_ton.tuoi_man_kinh)}</div>
                  </>
              ) : (
                <></>
              )
            }
          </div>
        </div>
      </div>
    } else {
      return <>
        {renderChiSoSinhTon()}
        {renderThongTinKhamBenh()}
        {render_kham_benh()}
      </>
    }
  }

  const renderTitlePhieu = () => {
    const partner_code = userProfile.partner_code;
    if (partner_code === 'dth') {
      return i18n.t(languageKeys.ho_so_benh_an)?.toUpperCase()
    }
    if (partner_code === 'dkhg') {
      return 'Phiếu khám bệnh ngoại trú'.toUpperCase()
    }
    return i18n.t(languageKeys.title_phieu_bao_cao_y_te)?.toUpperCase()
  }

  // ********* Lưu ý ko xóa *********
  //
  // const renderLieuTrinh = () => {
  //   return (
  //     <div className="table" style={{ marginBottom: 6 }}>
  //       <div className="txt-700 txt-size-m" style={{ margin: '4px 10px 4px' }}>Liệu trình điều trị</div>
  //       <table style={{ width: "100%" }}>
  //         <thead>
  //           <tr>
  //             <th style={{ width: "28px", textAlign: 'center' }}>{i18n.t(languageKeys.field_Stt)}</th>
  //             <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Ten_dich_vu)}</th>
  //             <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.txt_So_luong)}</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {danh_sach_dich_vu.map((item, index) => {
  //             return (
  //               <tr key={rid()}>
  //                 <td style={{ textAlign: 'center' }}>{index + 1 >= 10 ? index + 1 : `0${index + 1}`}</td>
  //                 <td style={{ textAlign: 'center' }}>{item.TEN_DICHVU}</td>
  //                 <td style={{ textAlign: 'center' }}>{item.SO_LUONG}</td>
  //               </tr>
  //             );
  //           })}
  //         </tbody>
  //       </table>
  //       <div className="txt-700 txt-size-m" style={{ margin: '6px 10px 4px' }}>Thuốc điều trị</div>
  //       <table style={{ width: "100%" }}>
  //         <thead>
  //           <tr>
  //             <th style={{ width: "28px", textAlign: 'center' }}>{i18n.t(languageKeys.field_Stt)}</th>
  //             <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Ten_thuoc)}</th>
  //             <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.ham_luong_nong_do)}</th>
  //             <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.txt_So_luong)}</th>
  //             <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.duong_dung)}</th>
  //             <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.huong_dan_su_dung)}</th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {danh_sach_vat_tu.map((item, index) => {
  //             return (
  //               <tr key={rid()}>
  //                 <td style={{ textAlign: 'center' }}>{index + 1 >= 10 ? index + 1 : `0${index + 1}`}</td>
  //                 <td style={{ textAlign: 'center' }}>{item.TEN_VAT_TU}</td>
  //                 <td style={{ textAlign: 'center' }}>{item.HAM_LUONG || item.NONG_DO}</td>
  //                 <td style={{ textAlign: 'center' }}>{item.SO_LUONG}</td>
  //                 <td style={{ textAlign: 'center' }}>{item.DUONG_DUNG}</td>
  //                 <td style={{ textAlign: 'center' }}>{item.HUONG_DAN_SU_DUNG}</td>
  //               </tr>
  //             );
  //           })}
  //         </tbody>
  //       </table>
  //     </div>
  //   )
  // }

  return (
    <div id="phieu-kham-benh">
      <div className="header">
        {!!userProfile.benh_vien.ANH_DAI_DIEN && (
          <div className="img-header">
            <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN} />
          </div>
        )}
        <div className="content-header">
          <h2 style={{ margin: 0 }} className="benh_vien-name upperTxt">
            {userProfile?.benh_vien?.TEN_CSKCB}
          </h2>
          <div>
            <span className="txt-700">{i18n.t(languageKeys.dia_chi)}:</span> {layDiaChi(userProfile.benh_vien)}
          </div>
          <div className="flex">
            <div className="tel">
              <span className="txt-700"> {i18n.t(languageKeys.dien_thoai)}: </span> {convertString(userProfile.benh_vien.SO_DIEN_THOAI)}
            </div>
          </div>
          <div>
            <span className="txt-700">Website:</span> {convertString(userProfile.benh_vien.WEBSITE)}
          </div>
          <div>
            <span className="txt-700">Email:</span> {convertString(userProfile.benh_vien.EMAIL)}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="container-title">
          {isOnlyCSST
            ? i18n.t(languageKeys.kq_do_chi_so_sinh_ton)?.toUpperCase()
            : renderTitlePhieu()
          }
        </div>
        <div className="container__chapter--1">
          <div className="container__title--main">I. {i18n.t(languageKeys.field_Thong_tin_hanh_chinh)?.toUpperCase()}</div>
          <div className="container__ttbn--wrapper">
            <div className="container-wrapper-flex">
              <div className="col-3">
                <div>
                  {i18n.t(languageKeys.field_Ma_benh_nhan)}: <span className="txt-700 txt-size-m">{convertString(benh_nhan.ma_benh_nhan)}</span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Gioi_tinh)}: {getSexFromString(benh_nhan.gioi_tinh)}
                </div>
              </div>
              <div className="col-4">
                <div>
                  {i18n.t(languageKeys.ho_ten)}:{" "}
                  <span className="txt-700 txt-size-m"> {convertString(benh_nhan.ten_benh_nhan)?.toUpperCase()} </span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Doi_tuong)}: {convertString(benh_nhan.doi_tuong)}
                </div>
              </div>
              <div className="col-3">
                <div>
                  {benh_nhan.ngay_sinh.trim().length > 4 ? i18n.t(languageKeys.field_Ngay_sinh) : i18n.t(languageKeys.field_Nam_sinh)}:{" "}
                  {benh_nhan.ngay_sinh}
                </div>
                <div>
                  {i18n.t(languageKeys.sdt)}: <span className="txt-700">{convertString(benh_nhan.sdt)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dia-chi">
            {i18n.t(languageKeys.dia_chi)}: {convertString(benh_nhan.dia_chi)}
          </div>
          {!isOnlyCSST && <div>
            {i18n.t(languageKeys.khoa_phong)}: {convertString(tom_tat_kham.TEN_KHOA_PHONG)}
          </div>}
          <div style={{display: check5s ? "flex" : "none"}}>
            {i18n.t(languageKeys.common_ngay_kham)}: {data.gio}, {moment(data.ngay,"YYYYMMDD").format("DD/MM/YYYY")}
          </div>
        </div>
      {/*================================custom layout================================== */}
        {isCheckOnlyCanDo()}
      {/*=============================================================================== */}
      </div>

      {/*========================================footer================================== */}
      <div className="footer foot-break">
        <div className="footer-confirm">
          {i18n.language === languages.tieng_viet ? (
            // data?.ngay_tao ? <i>{data?.gio ? data.gio + ", " : ""}{data.ngay_tao}</i> :
            <i>
              {moment().format("HH:mm") + ", "}
              Ngày {moment().date()},tháng {moment().month() + 1}, năm {moment().year()}
            </i>
          ) : (
            <i> {moment().format(enDateFormat)}</i>
          )}
          <div className="txt-700">{i18n.t(languageKeys.field_Bac_si)}</div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space" />
          <div className="txt-700">{data.bac_si_ket_luan}</div>
        </div>
      </div>

      {/*=============================================================================== */}
      {!!userProfile.config?.QR_DOWNLOAD_APP && (
        <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: 30 }}>
          <img src={OnCareQR} alt="QR" style={{ height: 85, width: 85 }} />
          <i className="txt-700">
            {i18n.t(languageKeys.phieukhambenh_emp_qr_oncare)}
          </i>
        </div>
      )}

    </div>
  );
};

let convertString = (field) => {
  let text = "";

  if (!!field && typeof field === "string") {
    text = field;
  } else if (!!field && typeof field === "number") {
    text = `${field}`;
  }
  return text;
};

const convertDownlineTag = (string) => {
  try {
    return string.replaceAll("\n","<br/>")
  } catch (error) {
    return string
  }
}


export default PhieuThongTinKhamBenh;
