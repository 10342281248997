import moment from "moment";
import React from "react";
import { formatNumberToPrice, layDiaChi, getSexFromString, convertMillisecondToAgeNotT, convertValueToMilisecond } from "../../../helpers";
import i18n, { languageKeys, languages } from "../../../i18n";
import { useSelector } from "react-redux";
import { convertString } from "../update_1-11/PhieuChiDinhGreenLab";
import { enDateFormat } from "constants/keys";

export const stylePhieuThuK80 = `
#phieu-thu-k80 * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }
#phieu-thu-k80 .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 1em; }
#phieu-thu-k80 .title { text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 0.25rem auto;}
#phieu-thu-k80 .table * { line-height: 1.2em; }
#phieu-thu-k80 .table { page-break-inside:auto; margin: 0.25rem 0; }
#phieu-thu-k80 tr { page-break-inside:avoid; page-break-after:auto; }
#phieu-thu-k80 thead { display:table-header-group }
#phieu-thu-k80 tfoot { display:table-footer-group }
#phieu-thu-k80 .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-thu-k80 .table th, .table td { padding: 3px; }
#phieu-thu-k80 .table th { text-align: start; }
#phieu-thu-k80 .fs12 { font-style: 1em; text-transform: uppercase}
@media print {
   #phieu-thu-k80, #phieu-thu-k80 .foot-break { break-inside: avoid; }
   #phieu-thu-k80{page-break-after: always;}
}
`;

const PhieuThuK80 = ({ data, rid }) => {

  const { nhom_dv } = data

  const userProfile = useSelector((state) => state.auth.user);

  const THANH_TIEN = nhom_dv.reduce((prev, curr) => prev + curr.dich_vu.reduce((tol, obj) => tol + obj.don_gia * obj.so_luong, 0), 0)
  const KHUYEN_MAI = nhom_dv.reduce((prev, curr) => prev + curr.dich_vu.reduce((tol, obj) => tol + obj.mien_giam, 0), 0)
  const THUC_THU = THANH_TIEN - KHUYEN_MAI

  return (
    <div id="phieu-thu-k80">

      <div className="content-header">
        <b className="hospital-name">{userProfile?.benh_vien?.TEN_CSKCB}</b>
        <br />
        <b>{layDiaChi(userProfile.benh_vien)}</b>
        <br />
        <b>{i18n.t(languageKeys.sdt)}: {convertString(userProfile.benh_vien.SO_DIEN_THOAI)}</b>
      </div>

      <div className="title">
        {data.nhom === "HOAN_TIEN"
          ? i18n.t(languageKeys.phieu_hoan).toUpperCase()
          : i18n.t(languageKeys.phieu_thu).toUpperCase()}
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <b>{i18n.t(languageKeys.ma_kh)}: {data.benh_nhan.ma_benh_nhan}</b>
        <b>{i18n.t(languageKeys.field_Tuoi)}: {convertMillisecondToAgeNotT(convertValueToMilisecond(data.benh_nhan?.ngay_sinh))}</b>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <b>{i18n.t(languageKeys.ho_va_ten)}: {data.benh_nhan.ten_benh_nhan}</b>
        <b>{i18n.t(languageKeys.field_Gioi_tinh)}: {getSexFromString(data.benh_nhan.gioi_tinh)}</b>
      </div>

      <table style={{ width: "100%" }} className="table">
        <thead>
          <tr>
            <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.stt)}</th>
            <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Ten_dich_vu)}</th>
            <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_So_luong)}</th>
            <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_don_gia)}</th>
            <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.vien_phi_thanh_tien)}</th>
          </tr>
        </thead>
        <tbody>
          {data.nhom_dv?.map((nhom_dv, index) => {
            return (
              <React.Fragment key={index}>
                <tr>
                  <th colSpan={5}>{nhom_dv.nhom_dv}</th>
                </tr>
                {nhom_dv.dich_vu.map((dich_vu, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td style={{ textAlign: 'center' }}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</td>
                        <td>{dich_vu.ten_dich_vu}</td>
                        <td style={{ textAlign: 'center' }}>
                          {dich_vu.so_luong || dich_vu.SO_LUONG}
                        </td>
                        <td style={{ textAlign: "end" }}>
                          {formatNumberToPrice(dich_vu.don_gia)}
                        </td>
                        <td style={{ textAlign: "end" }}>
                          {formatNumberToPrice(+dich_vu.don_gia * +(dich_vu.so_luong || dich_vu.SO_LUONG))}
                        </td>
                      </tr>
                      {userProfile.config.HIEN_THI_KEM_THEO && dich_vu.dv_kem_theo?.map((dvkt, i) => (
                        <tr key={i}>
                          <td></td>
                          <td style={{ paddingLeft: 30 }}>{dvkt.ten_dich_vu || dvkt.TEN_DICHVU}</td>
                          <td style={{ textAlign: 'center' }}>{dvkt.so_luong || dvkt.SO_LUONG}</td>
                          <td style={{ textAlign: "end" }}>-</td>
                          <td style={{ textAlign: "end" }}>-</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
          <tr>
            <td colSpan={4} style={{ textAlign: "end" }}>
              <b>{i18n.t(languageKeys.bao_cao_tong_cong)}</b>
            </td>
            <td style={{ textAlign: "end" }}>{formatNumberToPrice(THANH_TIEN)}</td>
          </tr>
        </tbody>
      </table>

      <div>
        <b>{i18n.t(languageKeys.khuyen_mai)}: {formatNumberToPrice(KHUYEN_MAI)}</b>
        <br />
        <b>{i18n.t(languageKeys.thuc_thu)}: {formatNumberToPrice(THUC_THU)}</b>
      </div>

      <div style={{ float: "right", textAlign: "center" }}>
        <div style={{ display: "flex", justifyContent: "center", }}>
          {data?.ngay_tao ?
            <i>
              {i18n.language === languages.tieng_viet
                ? (data.gio ? data.gio + ", " + data.ngay_tao : data.ngay_tao)
                : moment(data.gio ? `${data.ngay} ${data.gio}` : data.ngay, data.gio ? 'YYYYMMDD HH:mm' : 'YYYYMMDD').format(enDateFormat)
              }
            </i>
            :
            <i style={{ textAlign: "center", marginTop: 15 }}>
              {i18n.language === languages.tieng_viet
                ? (moment().format("HH:mm") +
                  `,  ${i18n.t(languageKeys.common_ngay)} ` +
                  moment().format("DD") +
                  `  ${i18n.t(languageKeys.common_thang)} ` +
                  moment().format("MM") +
                  `  ${i18n.t(languageKeys.common_nam)} ` +
                  +moment().format("YYYY"))
                : moment().format(enDateFormat)
              }
            </i>}
        </div>
        <b className="fs12">{i18n.t(languageKeys.txt_ke_toan)}</b>
      </div>

    </div>
  )
}

export default PhieuThuK80;