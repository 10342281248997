import React from "react";
import JsBarcode from "jsbarcode";
import moment from "moment";
import { useSelector } from "react-redux";
import { convertDateToValue, layDiaChi } from "../../../helpers";
import i18n, { languageKeys, languages } from "../../../i18n";
import Images from "components/Images/Images";
import { enDateFormat } from "constants/keys";
import { getSharedData } from "ducks/slices/ListData/SharedData.slice";
export const styleDonThuocNew = `
#pdf-don-thuoc * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif;  }
#pdf-don-thuoc .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#pdf-don-thuoc .header { display: flex; gap: 10px; font-size: 10px;}
#pdf-don-thuoc .hospital-name { font-size: 10px; text-transform: uppercase; font-weight: bold; }
#pdf-don-thuoc .title { text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 1rem auto 0.5rem auto;}
#pdf-don-thuoc .label { font-weight: bold; }

#pdf-don-thuoc .table {
    margin-block: 1rem;
    width: 100%;
}

#pdf-don-thuoc .table * {
    font-size: 12px;
}

#pdf-don-thuoc .table,
.table th, 
.table td {
    border: 1px solid #181818;
    border-collapse: collapse;
    text-align: center;
}

#pdf-don-thuoc .table th,
.table td {
    padding: 5px 10px;
}

#pdf-don-thuoc .name-space {
  height: 80px;
}

#pdf-don-thuoc .table th {
    text-align: center;
}
#pdf-don-thuoc .container-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
#pdf-don-thuoc .container__title--main {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
#pdf-don-thuoc .container__ttbn--wrapper>div {
    margin: 4px 0 0 0; 
  }
#pdf-don-thuoc .container-wrapper-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  #pdf-don-thuoc .container-wrapper-flex .col-3{
    width: 30%;
  }
  #pdf-don-thuoc .container-wrapper-flex .col-4{
    width: 40%;
  }
  #pdf-don-thuoc .container-wrapper-flex .col-7{
    width: 70%;
  }
  #pdf-don-thuoc .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }
  #pdf-don-thuoc .footer .signature {
    margin-right: 44px;
    text-align: center;
  }

  #pdf-don-thuoc .txt-size-m {
    font-size: 12px;
  }

  #pdf-don-thuoc .tel {
    margin-right: 50px;
  }

  #pdf-don-thuoc .txt-700{
    font-weight:700;
  }
@media print {
    #pdf-don-thuoc .foot-break { break-inside: avoid; }
    #pdf-don-thuoc{page-break-after: always;}
}
`;

export const styleDonThuocNewGL = `
#pdf-don-thuoc * { font-size: 14pt; font-family: "Times New Roman", Times, serif;  }
#pdf-don-thuoc .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#pdf-don-thuoc .header { display: flex; gap: 10px; font-size: 14pt;}
#pdf-don-thuoc .hospital-name { font-size: 16pt; text-transform: uppercase; font-weight: bold; }
#pdf-don-thuoc .title { text-transform: uppercase; font-size: 16pt; font-weight: bold; text-align: center; width: 90%; margin: 1rem auto 0.5rem auto;}
#pdf-don-thuoc .label { font-weight: bold; }

#pdf-don-thuoc .table {
    margin-block: 1rem;
    width: 100%;
}

#pdf-don-thuoc .table * {
    font-size: 14pt;
}

#pdf-don-thuoc .table,
.table th, 
.table td {
    border: 1px solid #181818;
    border-collapse: collapse;
    text-align: center;
}

#pdf-don-thuoc .table th,
.table td {
    padding: 5px 10px;
}

#pdf-don-thuoc .name-space {
  height: 80px;
}

#pdf-don-thuoc .table th {
    text-align: center;
}
#pdf-don-thuoc .container-title {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
#pdf-don-thuoc .container__title--main {
    font-weight: 700;
    font-size: 14pt;
    line-height: 16pt;
  }
#pdf-don-thuoc .container__ttbn--wrapper>div {
    margin: 4px 0 0 0; 
  }
#pdf-don-thuoc .container-wrapper-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  #pdf-don-thuoc .container-wrapper-flex .col-3{
    width: 30%;
  }
  #pdf-don-thuoc .container-wrapper-flex .col-4{
    width: 40%;
  }
  #pdf-don-thuoc .container-wrapper-flex .col-7{
    width: 70%;
  }
  #pdf-don-thuoc .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }
  #pdf-don-thuoc .footer .signature {
    margin-right: 44px;
    text-align: center;
  }

  #pdf-don-thuoc .txt-size-m {
    font-size: 14pt;
  }

  #pdf-don-thuoc .tel {
    margin-right: 50px;
  }

  #pdf-don-thuoc .txt-700{
    font-weight:700;
  }
@media print {
    #pdf-don-thuoc .foot-break { break-inside: avoid; }
    #pdf-don-thuoc{page-break-after: always;}
}
`;

const DonThuoc = ({ data = { benh_nhan: {}, dich_vu: [], hospital: {}, thuoc: [], IS_DON_TPCN: false }, rid }) => {

  const userProfile = useSelector((state) => state.auth.user);
  const { prescriptionForm } = useSelector(getSharedData);

  let { chuan_doan_benh_chinh, chuan_doan_benh_phu } = data;

  const convertBenh = (arr) => {
    let result = "";

    if (Array.isArray(arr)) {
      result = arr.map((item) => (!!item.MA_ICD ? `${item?.MA_ICD} - ${item.ten_benh}` : `${item.ten_benh}`)).join(", ");
      if (typeof result !== "string") {
        result = "";
      }
    }
    return result;
  };

  let benhChinh = convertBenh(chuan_doan_benh_chinh);
  let benhPhu = convertBenh(chuan_doan_benh_phu);

  React.useEffect(() => {
    if (!!rid && !!data.benh_nhan.ma_dinh_danh) {
      JsBarcode(`#barcode-${rid}`)
        .options({ font: "OCR-B" })
        .CODE128(data.benh_nhan.ma_dinh_danh, {
          fontSize: 16,
          width: 1.2,
          height: 40,
          textMargin: 0,
          margin: 0,
          padding: 0,
        })
        .render();
    }
  }, [data.benh_nhan.ma_benh_nhan, rid]);

  return (
    <div id="pdf-don-thuoc">
      <div className="header">
        {!!userProfile.benh_vien.ANH_DAI_DIEN && (
          <div className="img-header">
            <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN} />
          </div>
        )}
        <div style={{ display: "flex", gap: '80px', justifyContent: "space-between", width: "100%" }}>
          <div>
            <div className="hospital-name txt-size-m">{userProfile.benh_vien.TEN_CSKCB}</div>
            <div>
              {" "}
              {i18n.t(languageKeys.dia_chi)}: {layDiaChi(userProfile.benh_vien)}
            </div>
            <div style={{ display: "flex" }}>
              <div className="tel">
                <span className="txt-700"> {i18n.t(languageKeys.dien_thoai)}: </span> {userProfile.benh_vien.SO_DIEN_THOAI}
              </div>
            </div>
            {/* <div>
              <span className="txt-700">Website:</span> {userProfile?.benh_vien?.WEBSITE || ''}
            </div>
            <div>
              <span className="txt-700">Email:</span> {userProfile.benh_vien.EMAIL}
            </div> */}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', position: 'relative', minWidth: '150px' }}>
            <div className="txt-700">{i18n.t(languageKeys.field_Ma_don_thuoc)}</div>
            <div>
              <svg id={`barcode-${rid}`}></svg>
            </div>
          </div>
        </div>

      </div>
      <div className="body">
        <div id="don-thuoc">
          {/* <div className="title">{i18n.t(!data.IS_DON_TPCN ? languageKeys.don_thuoc_dich_vu : languageKeys.phieu_tu_van)}</div> */}
          <div className="title">{i18n.t(languageKeys.don_thuoc_dich_vu)}</div>

          <div className="container__ttbn--wrapper">
            <div className="container-wrapper-flex">
              <div className="col-3">
                <div>
                  {i18n.t(languageKeys.field_Ma_benh_nhan)}: <span className="txt-700 txt-size-m">{data.benh_nhan?.ma_dinh_danh}</span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Gioi_tinh)}: {data.benh_nhan?.gioi_tinh}
                </div>
                {/* <div>
                  {i18n.t(languageKeys.khoa)}: {data.ten_khoa || ""}
                </div> */}
              </div>
              <div className="col-4">
                <div>
                  {i18n.t(languageKeys.ho_ten)}: <span className="txt-700 txt-size-m"> {data.benh_nhan?.ho_ten}</span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Doi_tuong)}: {data.benh_nhan?.doi_tuong}
                </div>
                {/* <div>
                  {i18n.t(languageKeys.phong)}: {data.ten_phong || ""}
                </div> */}
              </div>
              <div className="col-3">
                <div>
                  {i18n.t(languageKeys.field_Ngay_sinh)}: {(data.benh_nhan?.ngay_sinh?.length > 4) ? moment(data.benh_nhan?.ngay_sinh).format("DD/MM/YYYY") : data.benh_nhan?.ngay_sinh}
                </div>
                <div>
                  {i18n.t(languageKeys.sdt)}: <span className="txt-700">{data.benh_nhan.so_dien_thoai}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dia-chi" >
            {i18n.t(languageKeys.dia_chi)}: {data.benh_nhan.dia_chi}
          </div>
          <div>
            {i18n.t(languageKeys.chan_doan_chinh)}: {benhChinh}
          </div>
          <div>
            {i18n.t(languageKeys.chan_doan_phu)}: {benhPhu}
          </div>

          {prescriptionForm === 1 && (
            <table className="table">
              <thead>
                <tr>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.stt)}</th>
                  <th style={{ textAlign: 'center', width: "35%" }}>{i18n.t(languageKeys.field_Ten_thuoc)}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.dvt)}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.sl)}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.ngay_dung)}</th>
                  <th style={{ textAlign: 'center', width: "30%" }}>{i18n.t(languageKeys.chi_dan_dung_thuoc)}</th>
                  <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.thuoc_co_san)}</th>
                </tr>
              </thead>
              <tbody>
                {data.thuoc.map((item, index) => (
                  <>
                    <tr>
                      <td style={{ textAlign: 'center' }}>{index + 1}</td>
                      <td style={{ textAlign: "start", width: "35%" }}>
                        {item.Thuoc_HoatChat} ({item.Thuoc_Ten}) {item.HAM_LUONG || item.NONG_DO}
                      </td>
                      <td>{item.Thuoc_Dvt}</td>
                      <td style={{ textAlign: 'center' }}>{item.Thuoc_SL}</td>
                      <td style={{ textAlign: 'center' }}>{item.SoNgaySD}</td>
                      <td style={{ textAlign: "start", width: "20%", maxWidth: "25%" }}>
                        {item.Chidan}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {item.KHO_THUOC_ID ? "✔" : ""}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          )}

          {prescriptionForm === 2 && (
            <div style={{ margin: '15px 0' }}>
              {data.thuoc.map((item, index) => (
                <div key={index} style={{ margin: '5px 0' }}>
                  <div className="container-wrapper-flex" style={{ columnGap: 2 }}>
                    <div className="col-7">
                      <b>{index + 1}. {item.Thuoc_HoatChat} ({item.Thuoc_Ten}) {item.HAM_LUONG || item.NONG_DO}</b>
                      {item.Duongdung ? ` - ${item.Duongdung}` : ""}
                    </div>
                    <div className="col-3">
                      <b>{i18n.t(languageKeys.sl)}: {item.Thuoc_SL} {item.Thuoc_Dvt}</b>
                    </div>
                  </div>
                  <div>{item.Chidan}</div>
                </div>
              ))}
            </div>
          )}

          <div>
            {i18n.t(languageKeys.loi_dan_bac_sy)}: <span>{data?.loi_dan}</span>
          </div>
          <div className="txt-700">
            {i18n.t(languageKeys.ngay_tai_kham)}: {convertDateToValue(data.ngay_tai_kham)}
          </div>

          <div className="footer">
            <div style={{ textAlign: "center" }}>
              {/* <div className="txt-700">{i18n.t(languageKeys.field_Ma_don_thuoc)}</div>
              <div>
                <svg id={`barcode-${rid}`}></svg>
              </div> */}
            </div>
            <div className="signature">
              {i18n.language === languages.tieng_viet ? (
                <i>
                  {moment().format("HH:mm") + ", "}
                  Ngày {moment().date()},tháng {moment().month() + 1}, năm {moment().year()}
                </i>
              ) : (
                <i> {moment().format(enDateFormat)}</i>
              )}
              <div className="txt-700">{i18n.t(languageKeys.field_Bac_si_ke_don)}</div>
              <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
              <div className="name-space"></div>
              <div className="txt-700">{data?.bac_si?.chu_ky}</div>
            </div>
          </div>

          <div>
            <i>* {i18n.t(languageKeys.kham_lai_xin_mang_theo_don)}</i>
          </div>

          <i>* {i18n.t(languageKeys.ten_bo_hoac_me_cua_tre)}</i>
        </div>
      </div>
    </div>
  );
};

export default DonThuoc;
