import React from "react";
import style from "./style.module.less";
import i18n, { languageKeys } from "../../../i18n";
import { Row, Col, Button, Tooltip } from "antd";
import Table from "../../Table/Table";
import { calcIndex, convertDateToValue, getFullDiaChi, removeTags, rid } from "../../../helpers";
import { PrinterOutlined } from "@ant-design/icons";
import { TRANG_THAI_KHAM_BENH_CUOC_KHAM, TRANG_THAI_KHAM_KHAC } from "../../../constants/keys";
import { useSelector } from "react-redux";


// ellipsis chữ (độ ... đằng sau nếu quá dài)
const ellipsisProps = {
  ellipsis: true,
  render: (data) => (
    <Tooltip placement="topLeft" title={data} zIndex={1}>
      {data === "null" || data === "undefined" || !data ? "" : data}
    </Tooltip>
  ),
};

const ItemKetQuaCLS = ({ item,onOpenKq }) => {
  const userProfile = useSelector(state => state.auth.user);
  const columns = [
    {
      title: i18n.t(languageKeys.stt),
      key: "STT",
      dataIndex: "STT",
      width: 50,
    },
    // {
    //   title: i18n.t(languageKeys.field_Loai_dich_vu),
    //   key: "TEN_LOAI_DICH_VU",
    //   dataIndex: "TEN_LOAI_DICH_VU",
    //   width: 150,
    // },
    {
      title: i18n.t(languageKeys.field_Ten_dich_vu),
      key: "TEN_DICHVU",
      dataIndex: "TEN_DICHVU",
      width: 200,
    },
    {
      title: i18n.t(languageKeys.mo_ta),
      key: "MO_TA",
      dataIndex: "MO_TA",
      width: 200,
      ...ellipsisProps,
      render: (data) => (
        <Tooltip placement="topLeft" title={removeTags(data)} zIndex={1}>
          {data === "null" || data === "undefined" || !data ? "" :  removeTags(data)}
        </Tooltip>
      ),
    },
    {
      title: i18n.t(languageKeys.field_Ket_qua),
      key: "KET_LUAN",
      dataIndex: "KET_LUAN",
      width: 200,
      // render: (data,record) => {
      //   let kq = "";
      //   try {
      //     if(typeof data === "string") {
      //       if(data.includes("[")) {
      //         kq = JSON.parse(data).join(', ');
      //           return <>{kq}</>
      //       } else {
      //         return data
      //       }
      //     } else {
      //     }
      //   } catch (error) {
      //     return data;
      //   }
      // }

      ...ellipsisProps,
      render: (data) => (
        <Tooltip placement="topLeft" title={removeTags(data)} zIndex={1}>
          {data === "null" || data === "undefined" || !data ? "" :  removeTags(data)}
        </Tooltip>
      ),

    },
    {
      title: i18n.t(languageKeys.thoi_gian_thuc_hien),
      key: "NGAY_Y_LENH",
      dataIndex: "NGAY_Y_LENH",
      render: (ngay, item) => `${item.GIO} - ${convertDateToValue(item.NGAY_Y_LENH)}`,
      width: 150,
    },
    {
      title: i18n.t(languageKeys.trang_thai),
      key: "TRANG_THAI",
      dataIndex: "TRANG_THAI",
      width: 120,
      render: (status, item) => (
        <span style={{ color: getColor(item.PHAN_LOAI === "KHAM_BENH" ? item.TRANG_THAI : item.TRANG_THAI_THUC_HIEN) }}>
          {getTxtTrangThai(item, item.PHAN_LOAI)}
        </span>
      ),
    },
    {
      title: <></>,
      key: "ACTION",
      dataIndex: "",
      width: 150,
      render: (_, record) => {
        if (["CDHA", "XET_NGHIEM"].includes(record.PHAN_LOAI)) {
          return (
            <Button type="primary" onClick={() => onOpenKq(record?.PHAN_LOAI,record)} disabled={!["DA_TRA_KET_QUA","HOAN_THANH"].includes(record?.TRANG_THAI_KET_QUA)}>
              {i18n.t(languageKeys.xem_KQ)}
            </Button>
          );
        } else if (["THU_THUAT"].includes(record.PHAN_LOAI)) {
          return (
            <Button type="primary" onClick={() => onOpenKq(record?.PHAN_LOAI,record)} disabled={!["DA_TRA_KET_QUA","HOAN_THANH"].includes(record?.TRANG_THAI_KET_QUA)}>
              {i18n.t(languageKeys.xem_tuong_trinh)}
            </Button>
          );
        } else {
          return <></>;
        }
      },
    },
  ];
  
  return (
    <div className={style["container"]}>
      <Row className={style["date"]} gutter={10}>
        <Col span={5}>
          <p>
            {i18n.t(languageKeys.field_Ngay_kham)}: {convertDateToValue(item.NGAY_Y_LENH)}
          </p>
        </Col>
        <Col span={7}>
          <p>
            {i18n.t(languageKeys.field_Noi_kham)}: {item?.TEN_CSKCB || userProfile?.benh_vien?.TEN_CSKCB}
          </p>
        </Col>
        <Col span={12}>
          <p>
            {i18n.t(languageKeys.field_Dia_chi)}: {item?.DIA_CHI_CSKCB || userProfile ? getFullDiaChi(userProfile?.benh_vien) : "" }
          </p>
        </Col>
      </Row>
      <Table columns={columns} dataSource={item.DANH_SACH_CLS?.map((item,index) => ({ ...item, STT: calcIndex(1,index,1000), key: rid(8) }))} key={rid(8)} />
    </div>
  );
};
export default React.memo(ItemKetQuaCLS);


const getTxtTrangThai = (record, type) => {
  if (record.PHAN_LOAI === "KHAM_BENH") {
    switch (record.TRANG_THAI) {
      case TRANG_THAI_KHAM_BENH_CUOC_KHAM.CHO_KHAM:
        return i18n.t(languageKeys.Cho_kham);
      case TRANG_THAI_KHAM_BENH_CUOC_KHAM.DANG_KHAM:
        return i18n.t(languageKeys.Cho_kham);
      case TRANG_THAI_KHAM_BENH_CUOC_KHAM.KHAM_XONG:
        return i18n.t(languageKeys.Cho_kham);
      case TRANG_THAI_KHAM_BENH_CUOC_KHAM.HOAN_THANH:
      default:
        return "";
    }
  } else {
    switch (record.TRANG_THAI_THUC_HIEN) {
      case TRANG_THAI_KHAM_KHAC.CHO_THUC_HIEN:
        return i18n.t(languageKeys.cho_thuc_hien);
      case TRANG_THAI_KHAM_KHAC.DANG_THUC_HIEN:
        return i18n.t(languageKeys.Dang_thuc_hien);
      case TRANG_THAI_KHAM_KHAC.DA_THUC_HIEN:
        return i18n.t(languageKeys.da_thuc_hien);
      case TRANG_THAI_KHAM_KHAC.HOAN_THANH:
        return i18n.t(languageKeys.common_Hoan_thanh);

      case TRANG_THAI_KHAM_KHAC.TU_CHOI:
        return i18n.t(languageKeys.txt_tu_choi);
      default:
        return "";
    }
  }
};

const getColor = (status) => {
  switch (status) {
    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.KHAM_XONG:
    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.HOAN_THANH:
    case TRANG_THAI_KHAM_KHAC.HOAN_THANH:
      return "#2CB2A5";
    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.CHO_KHAM:
    case TRANG_THAI_KHAM_KHAC.CHO_THUC_HIEN:
      return "#F6A447";
    case TRANG_THAI_KHAM_BENH_CUOC_KHAM.DANG_KHAM:
    case TRANG_THAI_KHAM_KHAC.DANG_THUC_HIEN:
    case TRANG_THAI_KHAM_KHAC.DA_THUC_HIEN:
      return "#6576FF";
    default:
      return "#F6A447";
  }
};
